import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'common/components';
import { strings } from 'common/constants/app-constants';
import { EDIT_USER_ACTIONS } from 'modules/CommonModules/Users/enums';
import { useAppDispatch } from 'common/redux/core';
import { usersActions } from 'modules/CommonModules/Users/redux';
import { dateUtil } from 'common/utils';
import { UsersDetails } from 'modules/CommonModules/Users/models';
import './ConfirmationModal.scss';

type ConfirmationModalProps = {
    actionName: EDIT_USER_ACTIONS;
    selectedRow: UsersDetails;
    onCancel: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    actionName,
    selectedRow,
    onCancel
}) => {
    const dispatch = useAppDispatch();

    const getHeaderContent = (action: EDIT_USER_ACTIONS) => {
        switch (action) {
            case EDIT_USER_ACTIONS.WipeProgress: {
                return strings.USERS.EDIT_USER_MODAL.WIPE_PROGRESS_CONFIRM_HEADER;
            }
            case EDIT_USER_ACTIONS.ForceEndorsement: {
                return strings.USERS.EDIT_USER_MODAL.FORCE_ENDORSED_CONFIRM_HEADER;
            }
        }
    };

    const getBodyContent = (action: EDIT_USER_ACTIONS) => {
        switch (action) {
            case EDIT_USER_ACTIONS.WipeProgress: {
                return strings.USERS.EDIT_USER_MODAL.WIPE_PROGRESS_CONFIRM_BODY;
            }
            case EDIT_USER_ACTIONS.ForceEndorsement: {
                return strings.USERS.EDIT_USER_MODAL.FORCE_ENDORSED_CONFIRM_BODY;
            }
        }
    };

    const handleProceed = () => {
        switch (actionName) {
            case EDIT_USER_ACTIONS.WipeProgress: {
                dispatch(usersActions.wipeProgressBegin({ userGuid: selectedRow.id }));
                onCancel();
                break;
            }
            case EDIT_USER_ACTIONS.ForceEndorsement: {
                dispatch(
                    usersActions.forceEndorsementBegin({
                        userGuid: selectedRow.id,
                        currentDate: dateUtil.getCurrentDateInYYYYMMDD()
                    })
                );
                onCancel();
                break;
            }
        }
    };

    return (
        <div>
            <Modal className="edit-confirmation-modal" show size="md" onClose={onCancel}>
                <ModalHeader>
                    <div className="edit-confirmation-modal__title">
                        {getHeaderContent(actionName)}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="edit-confirmation-modal__body-content">
                        {getBodyContent(actionName)}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-save" variant="primary" onClick={handleProceed}>
                        {strings.PROCEED}
                    </Button>
                    <Button className="btn-cancel" variant="secondary" onClick={onCancel}>
                        {strings.CANCEL}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ConfirmationModal;
