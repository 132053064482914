import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    initialLogoutResponse,
    initialProfileDetails,
    initialSubmitDeviceInfo,
    LogoutRequest,
    LogoutResponse,
    SubmitDeviceInfoRequest,
    SubmitDeviceInfoResponse,
    UserProfileDetails
} from 'common/models';
import initialUserState from './user-profile.state';
import { ApiStatus } from 'common/enums';

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState: initialUserState,
    reducers: {
        fetchUserProfileBegin: (state) => {
            state.profileDetails.status = ApiStatus.LOADING;
        },
        fetchUserProfileSuccess: (state, { payload }: PayloadAction<UserProfileDetails>) => {
            state.profileDetails.status = ApiStatus.SUCCESS;
            state.profileDetails.data = payload;
        },
        fetchUserProfileError: (state) => {
            state.profileDetails.status = ApiStatus.ERROR;
        },
        resetUserProfileBegin: (state) => {
            state.profileDetails.status = ApiStatus.NONE;
            state.profileDetails.data = initialProfileDetails;
        },

        submitDeviceInfoBegin: (state, { payload }: PayloadAction<SubmitDeviceInfoRequest>) => {
            state.deviceInfo.status = ApiStatus.LOADING;
        },
        submitDeviceInfoSuccess: (state, { payload }: PayloadAction<SubmitDeviceInfoResponse>) => {
            state.deviceInfo.status = ApiStatus.SUCCESS;
            state.deviceInfo.data = payload;
        },
        submitDeviceInfoError: (state) => {
            state.deviceInfo.status = ApiStatus.ERROR;
        },
        resetSubmitDeviceInfo: (state) => {
            state.deviceInfo.status = ApiStatus.NONE;
            state.deviceInfo.data = initialSubmitDeviceInfo;
        },

        logoutBegin: (state, { payload }: PayloadAction<LogoutRequest>) => {
            state.logout.status = ApiStatus.LOADING;
        },
        logoutSuccess: (state, { payload }: PayloadAction<LogoutResponse>) => {
            state.logout.status = ApiStatus.SUCCESS;
            state.logout.data = payload;
        },
        logoutError: (state) => {
            state.logout.status = ApiStatus.ERROR;
        },
        resetLogout: (state) => {
            state.logout.status = ApiStatus.NONE;
            state.logout.data = initialLogoutResponse;
        }
    }
});

export default userProfileSlice.reducer;
export const userProfileActions = userProfileSlice.actions;
