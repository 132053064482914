import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/enums';
import { initialReportsState } from '../states/reports.state';
import {
    AgentProgressReportData,
    AssessmentReportData,
    BrokeragesReportData,
    CompetencyReportData,
    DownloadReportError,
    DownloadReportRequest,
    DownloadReportResponse,
    EndorsementReportData,
    EventLogsReportData,
    GenerateReportRequest,
    GenerateReportResponse,
    LearningTopicReportData,
    PlatformReportData,
    PrimaryAssociationOptions,
    ReportDataRequest,
    ReportGenerationStatusInfoRequest,
    ReportGenerationStatusInfoResponse,
    UserTopicProgressReportData
} from 'modules/AdminDashboard/models';
import { REPORTS_CONFIG } from 'common/configs';

const reportsSlice = createSlice({
    name: 'reports',
    initialState: initialReportsState,
    reducers: {
        fetchCompetencyReportDataBegin: (state, { payload }: PayloadAction<ReportDataRequest>) => {
            state.competency.reportData.status = ApiStatus.LOADING;
        },
        fetchCompetencyReportDataSuccess: (
            state,
            { payload }: PayloadAction<CompetencyReportData>
        ) => {
            state.competency.reportData.status = ApiStatus.SUCCESS;
            state.competency.reportData.data = payload;
        },
        fetchCompetencyReportDataError: (state) => {
            state.competency.reportData.status = ApiStatus.ERROR;
        },
        resetCompetencyReportData: (state) => {
            state.competency = initialReportsState.competency;
        },

        fetchPlatformReportDataBegin: (state) => {
            state.platform.reportData.status = ApiStatus.LOADING;
        },
        fetchPlatformReportDataSuccess: (state, { payload }: PayloadAction<PlatformReportData>) => {
            state.platform.reportData.status = ApiStatus.SUCCESS;
            state.platform.reportData.data = payload;
        },
        fetchPlatformReportDataError: (state) => {
            state.platform.reportData.status = ApiStatus.ERROR;
        },
        resetPlatformReportData: (state) => {
            state.platform = initialReportsState.platform;
        },

        fetchEndorsementReportDataBegin: (state, { payload }: PayloadAction<ReportDataRequest>) => {
            state.endorsement.reportData.status = ApiStatus.LOADING;
        },
        fetchEndorsementReportDataSuccess: (
            state,
            { payload }: PayloadAction<EndorsementReportData>
        ) => {
            state.endorsement.reportData.status = ApiStatus.SUCCESS;
            state.endorsement.reportData.data = payload;
        },
        fetchEndorsementReportDataError: (state) => {
            state.endorsement.reportData.status = ApiStatus.ERROR;
        },
        resetEndorsementReportData: (state) => {
            state.endorsement = initialReportsState.endorsement;
        },

        fetchAssessmentReportDataBegin: (state, { payload }: PayloadAction<ReportDataRequest>) => {
            state.assessment.reportData.status = ApiStatus.LOADING;
        },
        fetchAssessmentReportDataSuccess: (
            state,
            { payload }: PayloadAction<AssessmentReportData>
        ) => {
            state.assessment.reportData.status = ApiStatus.SUCCESS;
            state.assessment.reportData.data = payload;
        },
        fetchAssessmentReportDataError: (state) => {
            state.assessment.reportData.status = ApiStatus.ERROR;
        },
        resetAssessmentReportData: (state) => {
            state.assessment = initialReportsState.assessment;
        },

        fetchLearningTopicReportDataBegin: (
            state,
            { payload }: PayloadAction<ReportDataRequest>
        ) => {
            state.learningTopic.reportData.status = ApiStatus.LOADING;
        },
        fetchLearningTopicReportDataSuccess: (
            state,
            { payload }: PayloadAction<LearningTopicReportData>
        ) => {
            state.learningTopic.reportData.status = ApiStatus.SUCCESS;
            state.learningTopic.reportData.data = payload;
        },
        fetchLearningTopicReportDataError: (state) => {
            state.learningTopic.reportData.status = ApiStatus.ERROR;
        },
        resetLearningTopicReportData: (state) => {
            state.learningTopic = initialReportsState.learningTopic;
        },

        fetchAgentProgressReportDataBegin: (
            state,
            { payload }: PayloadAction<ReportDataRequest>
        ) => {
            state.agentProgress.reportData.status = ApiStatus.LOADING;
        },
        fetchAgentProgressReportDataSuccess: (
            state,
            { payload }: PayloadAction<AgentProgressReportData>
        ) => {
            state.agentProgress.reportData.status = ApiStatus.SUCCESS;
            state.agentProgress.reportData.data = payload;
        },
        fetchAgentProgressReportDataError: (state) => {
            state.agentProgress.reportData.status = ApiStatus.ERROR;
        },
        resetAgentProgressReportData: (state) => {
            state.agentProgress = initialReportsState.agentProgress;
        },

        fetchUserTopicReportDataBegin: (state, { payload }: PayloadAction<ReportDataRequest>) => {
            state.userTopicProgress.reportData.status = ApiStatus.LOADING;
        },
        fetchUserTopicProgressReportDataSuccess: (
            state,
            { payload }: PayloadAction<UserTopicProgressReportData>
        ) => {
            state.userTopicProgress.reportData.status = ApiStatus.SUCCESS;
            state.userTopicProgress.reportData.data = payload;
        },
        fetchUserTopicProgressReportDataError: (state) => {
            state.userTopicProgress.reportData.status = ApiStatus.ERROR;
        },
        resetUserTopicProgressReportData: (state) => {
            state.userTopicProgress = initialReportsState.userTopicProgress;
        },

        fetchEventLogsReportDataBegin: (state, { payload }: PayloadAction<ReportDataRequest>) => {
            state.eventLogs.reportData.status = ApiStatus.LOADING;
        },
        fetchEventLogsReportDataSuccess: (
            state,
            { payload }: PayloadAction<EventLogsReportData>
        ) => {
            state.eventLogs.reportData.status = ApiStatus.SUCCESS;
            state.eventLogs.reportData.data = payload;
        },
        fetchEventLogsReportDataError: (state) => {
            state.eventLogs.reportData.status = ApiStatus.ERROR;
        },
        resetEventLogsReportData: (state) => {
            state.eventLogs = initialReportsState.eventLogs;
        },

        fetchBrokeragesReportDataBegin: (state, { payload }: PayloadAction<ReportDataRequest>) => {
            state.brokerages.reportData.status = ApiStatus.LOADING;
        },
        fetchBrokeragesReportDataSuccess: (
            state,
            { payload }: PayloadAction<BrokeragesReportData>
        ) => {
            state.brokerages.reportData.status = ApiStatus.SUCCESS;
            state.brokerages.reportData.data = payload;
        },
        fetchBrokeragesReportDataError: (state) => {
            state.brokerages.reportData.status = ApiStatus.ERROR;
        },
        resetBrokeragesData: (state) => {
            state.brokerages = initialReportsState.brokerages;
        },

        resetAllReportsData: (state) => {
            Object.values(REPORTS_CONFIG.reportReduxStateKeys).forEach((key) => {
                state[key].reportData = initialReportsState[key].reportData;
                state[key].generationStatus = initialReportsState[key].generationStatus;
            });
        },

        downloadReportBegin: (state, { payload }: PayloadAction<DownloadReportRequest>) => {
            state[payload.reduxStateKey].download.status = ApiStatus.LOADING;
        },
        downloadReportSuccess: (state, { payload }: PayloadAction<DownloadReportResponse>) => {
            state[payload.reduxStateKey].download.status = ApiStatus.SUCCESS;
            state[payload.reduxStateKey].download.data = payload;
        },
        downloadReportError: (state, { payload }: PayloadAction<DownloadReportError>) => {
            state[payload.reduxStateKey].download.status = ApiStatus.ERROR;
            state[payload.reduxStateKey].download.errors = payload.errors;
        },
        resetDownloadReports: (state) => {
            Object.values(REPORTS_CONFIG.reportReduxStateKeys).forEach((key) => {
                state[key].download = initialReportsState[key].download;
            });
        },

        fetchPrimaryAssociationOptionsBegin: (
            state,
            { payload }: PayloadAction<ReportDataRequest>
        ) => {
            state.primaryAssociation.status = ApiStatus.LOADING;
        },
        fetchPrimaryAssociationOptionsSuccess: (
            state,
            { payload }: PayloadAction<PrimaryAssociationOptions>
        ) => {
            state.primaryAssociation.status = ApiStatus.SUCCESS;
            state.primaryAssociation.data = payload;
        },
        fetchPrimaryAssociationOptionsError: (state) => {
            state.primaryAssociation.status = ApiStatus.ERROR;
        },
        resetPrimaryAssociationOptions: (state) => {
            state.primaryAssociation = initialReportsState.primaryAssociation;
        },

        fetchReportGenerationStatusBegin: (
            state,
            { payload }: PayloadAction<ReportGenerationStatusInfoRequest>
        ) => {
            state.generationStatusInfo.status = ApiStatus.LOADING;
        },
        fetchReportGenerationStatusSuccess: (
            state,
            { payload }: PayloadAction<ReportGenerationStatusInfoResponse>
        ) => {
            state.generationStatusInfo.status = ApiStatus.SUCCESS;
            state.generationStatusInfo.data = payload;
        },
        fetchReportGenerationStatusError: (state) => {
            state.generationStatusInfo.status = ApiStatus.ERROR;
        },
        resetReportGenerationStatus: (state) => {
            state.generationStatusInfo = initialReportsState.generationStatusInfo;
        },

        generateReportBegin: (state, { payload }: PayloadAction<GenerateReportRequest>) => {
            state.generateReport.status = ApiStatus.LOADING;
        },
        generateReportSuccess: (state, { payload }: PayloadAction<GenerateReportResponse>) => {
            state.generateReport.status = ApiStatus.SUCCESS;
            state.generateReport.data = payload;
        },
        generateReportError: (state) => {
            state.generateReport.status = ApiStatus.ERROR;
        },
        resetGenerateReport: (state) => {
            state.generateReport = initialReportsState.generateReport;
        }
    }
});

export default reportsSlice.reducer;
export const reportActions = reportsSlice.actions;
