import { PayloadAction } from '@reduxjs/toolkit';
import { ApiResult } from 'common/models';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    BrokeragesListRequest,
    BrokeragesListResponse,
    DownloadBlobRequest,
    DownloadBlobResponse,
    EditBrokerageRequest,
    EditBrokerageResponse,
    FetchFileSasUriRequest,
    FetchFileSasUriResponse,
    UploadFileRequest,
    UploadFileResponse,
    UploadedFilesResponse
} from 'modules/AdminDashboard/models';
import { AdminService } from '../services/admin-actions.service';
import { adminActions } from '../slices/admin-actions.slice';

const adminActionsWatch = function* () {
    yield takeLatest(adminActions.fetchBrokeragesListBegin.type, FetchBrokeragesList);
    yield takeLatest(adminActions.fetchFileSasUriAndDownloadBegin.type, FetchFileSasUriAndDownload);
    yield takeEvery(adminActions.uploadFileBegin.type, UploadFile);
    yield takeLatest(adminActions.downloadBlobDataBegin.type, DownloadBlobData);
    yield takeLatest(adminActions.fetchUploadedFilesDataBegin.type, FetchUploadedFilesData);
    yield takeLatest(adminActions.editBrokerageBegin.type, EditBrokerage);
};

const FetchBrokeragesList = function* ({ payload }: PayloadAction<BrokeragesListRequest>) {
    const result: ApiResult<BrokeragesListResponse> = yield AdminService.fetchBrokeragesList(
        payload
    );
    if (result.hasErrors) {
        yield put(adminActions.fetchBrokeragesListError());
    } else {
        yield put(adminActions.fetchBrokeragesListSuccess(result.value));
    }
};

const FetchFileSasUriAndDownload = function* ({ payload }: PayloadAction<FetchFileSasUriRequest>) {
    const result: ApiResult<FetchFileSasUriResponse> =
        yield AdminService.fetchFileSasUriAndDownload(payload);
    if (result.hasErrors) {
        yield put(
            adminActions.fetchFileSasUriAndDownloadError({
                errors: result.errors,
                type: payload.type
            })
        );
    } else {
        yield put(
            adminActions.fetchFileSasUriAndDownloadSuccess({ ...result.value, type: payload.type })
        );
    }
};

const UploadFile = function* ({ payload }: PayloadAction<UploadFileRequest>) {
    const result: ApiResult<UploadFileResponse> = yield AdminService.uploadFile(payload);
    if (result.hasErrors) {
        yield put(adminActions.uploadFileError({ errors: result.errors, type: payload.type }));
    } else {
        yield put(adminActions.uploadFileSuccess({ ...result.value, type: payload.type }));
    }
};

const DownloadBlobData = function* ({ payload }: PayloadAction<DownloadBlobRequest>) {
    const result: ApiResult<DownloadBlobResponse> = yield AdminService.downloadBlobData(payload);
    if (result.hasErrors) {
        yield put(
            adminActions.downloadBlobDataError({ errors: result.errors, type: payload.type })
        );
    } else {
        yield put(
            adminActions.downloadBlobDataSuccess({
                blob: result.value as any,
                type: payload.type
            })
        );
    }
};

const FetchUploadedFilesData = function* () {
    const result: ApiResult<UploadedFilesResponse> = yield AdminService.fetchUploadedFilesData();
    if (result.hasErrors) {
        yield put(adminActions.fetchUploadedFilesDataError());
    } else {
        yield put(adminActions.fetchUploadedFilesDataSuccess(result.value));
    }
};

const EditBrokerage = function* ({ payload }: PayloadAction<EditBrokerageRequest>) {
    const result: ApiResult<EditBrokerageResponse> = yield AdminService.editBrokerage(payload);
    if (result.hasErrors) {
        yield put(adminActions.editBrokerageError());
    } else {
        yield put(adminActions.editBrokerageSuccess(result.value));
    }
};

export { adminActionsWatch };
