import { DEFAULT_PAGE_SIZE } from 'common/configs';

export type BrokeragesListRequest = {
    brokerageGuid?: string;
    pageNum?: number;
    pageSize?: number;
    searchQuery?: string;
    sortOrder?: string;
    sortColumn?: string;
    isPagination?: boolean;
};

export type BrokeragesDetails = {
    brokergaeGuid: string;
    name: string;
    email: string;
    city: string;
    state: string;
    nrdsId: string;
    isEndorsed: boolean | null;
    userCount: string;
    createdDate: string;
};

export type BrokeragesListResponse = {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    items: BrokeragesDetails[];
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
};

export type EditBrokerageRequest = {
    guidCode: string;
    isEndorsed: boolean | null;
    email: string;
};

export type EditBrokerageResponse = {
    id: string;
    isSuccess: boolean;
    hasNudge: boolean;
    title: string;
    responseDetails: {
        details: string;
        code: string;
        additionalParameters: null;
    };
};

export const initialBrokerageListResponse: BrokeragesListResponse = {
    hasPreviousPage: false,
    hasNextPage: false,
    items: [],
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: DEFAULT_PAGE_SIZE
};

export const initialEditBrokerageResponse: EditBrokerageResponse = {
    id: '',
    isSuccess: false,
    hasNudge: false,
    title: '',
    responseDetails: {
        details: '',
        code: '',
        additionalParameters: null
    }
};

export type FetchBrokeragesParams = {
    pageNum?: number;
    pageSize?: number;
    sortOrder?: string;
    sortColumn?: string;
    searchQuery?: string;
};
