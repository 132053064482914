import React, { useEffect, useState } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Select
} from 'common/components';
import { strings } from 'common/constants/app-constants';
import { useAppDispatch } from 'common/redux/core';
import { adminActions } from 'modules/AdminDashboard/redux';
import { StringUtil } from 'common/utils';
import { BrokeragesDetails } from 'modules/AdminDashboard/models';
import './EditBrokerageModal.scss';

type EditBrokerageModalProps = {
    selectedRow: BrokeragesDetails;
    onCancel: () => void;
};

const EditBrokerageModal: React.FC<EditBrokerageModalProps> = ({ selectedRow, onCancel }) => {
    const dispatch = useAppDispatch();
    const [validationError, setValidationError] = useState<string>('');
    const [emailTouched, setEmailTouched] = useState<boolean>(false);

    const [formData, setFormData] = useState({
        email: '',
        isEndorsed: {
            id: '',
            name: ''
        }
    });

    useEffect(() => {
        const transformedIsEndorsed =
            selectedRow.isEndorsed === null ? '' : selectedRow.isEndorsed ? 'Yes' : 'No';
        setFormData({
            email: selectedRow.email,
            isEndorsed: {
                id: transformedIsEndorsed,
                name: transformedIsEndorsed
            }
        });
    }, []);

    const handleEmailBlur = (e) => {
        setEmailTouched(true);
        triggerEmailValidation(formData.email);
    };

    const handleChange = (fieldName: string, value) => {
        if (fieldName === 'email' && emailTouched) {
            triggerEmailValidation(value);
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value
        }));
    };

    const triggerEmailValidation = (email: string) => {
        if (!StringUtil.isValidMail(email)) {
            setValidationError(strings.BROKERAGES.EDIT_MODAL.EMAIL_VALIDATION_ERROR);
        } else {
            setValidationError('');
        }
    };

    const handleSave = () => {
        if (validationError) return;

        const request = {
            guidCode: selectedRow.brokergaeGuid,
            isEndorsed:
                formData.isEndorsed.name === ''
                    ? null
                    : formData.isEndorsed.name == 'Yes'
                    ? true
                    : false,
            email: formData.email
        };

        dispatch(adminActions.editBrokerageBegin(request));
        onCancel();
    };

    return (
        <div>
            <Modal className="edit-brokerage-modal" show size="md" onClose={onCancel}>
                <ModalHeader>
                    <div className="edit-brokerage-modal__header">
                        <div>{strings.BROKERAGES.EDIT_MODAL.HEADER}</div>
                        <p className="edit-brokerage-modal__brokerage-info">
                            <span className="brokerage-info__label">
                                {strings.BROKERAGES.EDIT_MODAL.NAME}
                            </span>
                            <span className="brokerage-info__name">{selectedRow.name || '-'}</span>
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="edit-brokerage-modal__body-content">
                        <div className="input-wrapper">
                            <Input
                                id="email"
                                placeHolder={strings.BROKERAGES.EDIT_MODAL.EMAIL}
                                value={formData.email}
                                onChange={(e) => handleChange('email', e.target.value)}
                                onBlur={(e) => handleEmailBlur(e)}
                            />
                            {validationError ? (
                                <p className="input-wrapper--error">{validationError}</p>
                            ) : (
                                ''
                            )}
                        </div>

                        <Select
                            options={['Yes', 'No'].map((item) => {
                                return {
                                    id: item,
                                    name: item
                                };
                            })}
                            defaultOption={formData.isEndorsed}
                            placeHolder={strings.BROKERAGES.EDIT_MODAL.MARK_AS_ENDORSED}
                            onChange={(val) => handleChange('isEndorsed', val)}
                        />

                        <p className="edit-brokerage-modal__body-content--note">
                            <strong>{strings.BROKERAGES.EDIT_MODAL.NOTE}</strong>{' '}
                            {strings.BROKERAGES.EDIT_MODAL.NOTE_CONTENT}
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-save" variant="primary" onClick={handleSave}>
                        {strings.SAVE_CHANGES}
                    </Button>
                    <Button className="btn-cancel" variant="secondary" onClick={onCancel}>
                        {strings.CANCEL}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default EditBrokerageModal;
