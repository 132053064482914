import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'common/components';
import { strings } from 'common/constants/app-constants';
import './ValidationModal.scss';

type ValidationModalProps = {
    content: {
        header: string;
        body: string;
        footer: string;
    };
    onCancel: () => void;
};

const ValidationModal: React.FC<ValidationModalProps> = ({ content, onCancel }) => {
    return (
        <div>
            <Modal className="validation-modal" show size="md" onClose={onCancel}>
                <ModalHeader>
                    <div>{content.header}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="validation-modal__body-content">
                        <p
                            className="validation-modal__body-content--para"
                            dangerouslySetInnerHTML={{ __html: content.body }}></p>
                        <p>{content.footer}</p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="primary" variant="primary" onClick={onCancel}>
                        {strings.OK}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ValidationModal;
