import { UNAUTHORIZED_REDIRECT_URL } from 'common/configs/app.config';
import './Unauthorized.scss';

const Unauthorized = () => {
    const handleLinkClick = () => {
        window.open(UNAUTHORIZED_REDIRECT_URL, '_self');
    };

    return (
        <div className="unauthorized-layout">
            <p className="unauthorized-layout-info-msg">
                You&apos;re not authorized to access this site.
            </p>
            <p className="unauthorized-layout-info-msg">
                You&apos;ve reached the administrator&apos;s site for the National Association of
                REALTORS® Commitment to Excellence (C2EX) program.
            </p>
            <p className="unauthorized-layout-info-msg">
                If you wish to participate in the C2EX program, please go{' '}
                <span className="unauthorized-layout-info-msg--link" onClick={handleLinkClick}>
                    here
                </span>{' '}
                to learn more and download the C2EX app.
            </p>
        </div>
    );
};

export default Unauthorized;
