const Mime = {
    doc: 'application/msword',
    pdf: 'application/pdf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    zip: 'application/x-zip-compressed',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    csv: 'text/csv'
};

const stringToFile = (fileToSave: any, name: string, type: string) => {
    const file = new Blob([fileToSave], { type });
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = name;
    element.click();
    element.remove();
};

const fileFromUrl = async (url: string, name: string) => {
    const element = document.createElement('a');
    element.setAttribute('download', name);
    element.href = url;
    element.download = name;
    element.click();
    element.remove();
};

const downloadFileFromBlob = (blob, fileName, extension) => {
    const url = window.URL.createObjectURL(blob);
    const element = document.createElement('a');
    element.style.display = 'none';
    element.href = url;
    element.download = fileName + extension;
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(url);
    element.remove();
};

const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export default {
    stringToFile,
    fileFromUrl,
    downloadFileFromBlob,
    formatBytes
};
