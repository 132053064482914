import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Header, Loader, Unauthorized } from 'common/components';
import { Footer } from 'common/components';
import { AppUtil, SessionUtil } from 'common/utils';
import { AdminLayout, AssociationAdminLayout, BrokerageAdminLayout } from 'layouts';
import { UserRoles } from 'common/models';
import { AUTH_CONFIG, ROUTE_PATH, TITLE_AS_PER_USER_ROLE, USER_NAV_OPTIONS } from 'common/configs';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { userProfileActions } from 'common/redux/userProfile';
import { ApiStatus, UserRoleMapper } from 'common/enums';
import './AuthorizedLayout.scss';

const AuthorizedLayout = () => {
    const dispatch = useAppDispatch();
    const { status: deviceInfoStatus } = useAppSelector((state) => state.userProfile.deviceInfo);
    const { data: profileDetails, status: profileDetailsStatus } = useAppSelector(
        (state) => state.userProfile.profileDetails
    );
    const { data: logoutData, status: logoutStatus } = useAppSelector(
        (state) => state.userProfile.logout
    );

    const { getAccessTokenSilently, logout } = useAuth0();
    const [isTokenFetched, setIsTokenFetched] = useState<boolean>(false);
    const [isUserProfileDataFetched, setUserProfileDataFetched] = useState<boolean>(false);

    const userDetails = {
        firstName: profileDetails?.firstName,
        lastName: profileDetails?.lastName,
        role: profileDetails?.role
    };

    // fetch & store the token in session storage
    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                SessionUtil.set(AUTH_CONFIG.SESSION_KEY.TOKEN, accessToken);
                setIsTokenFetched(true);
            } catch (error) {
                console.error('Error while fetching access token:', error);
            }
        };

        fetchAccessToken();
        const interval = setInterval(fetchAccessToken, AUTH_CONFIG.CONFIG.TOKEN_REFRESH_TIME);

        // clear interval on unmount
        return () => clearInterval(interval);
    }, []);

    //call login/submit device details api once access token is available from auth0
    useEffect(() => {
        if (isTokenFetched) {
            dispatch(
                userProfileActions.submitDeviceInfoBegin({
                    deviceName: AppUtil.getBrowserName()
                })
            );
        }
    }, [isTokenFetched]);

    //fetch user profile on login/device details success
    useEffect(() => {
        if (deviceInfoStatus === ApiStatus.SUCCESS) {
            dispatch(userProfileActions.fetchUserProfileBegin());
        } else if (deviceInfoStatus === ApiStatus.ERROR) {
            setUserProfileDataFetched(true);
        }
    }, [deviceInfoStatus]);

    //call auth0's logout function on logout success from our api
    useEffect(() => {
        const auth0Logout = async () => {
            SessionUtil.removeAll();
            await logout({
                logoutParams: {
                    returnTo: window.location.origin + `/${ROUTE_PATH.LOG_OUT}`
                }
            });
        };

        if (logoutStatus === ApiStatus.SUCCESS && logoutData.isSuccess) {
            auth0Logout();
        }
    }, [logoutStatus]);

    useEffect(() => {
        if (
            profileDetailsStatus === ApiStatus.SUCCESS ||
            profileDetailsStatus === ApiStatus.ERROR
        ) {
            setUserProfileDataFetched(true);
        }
    }, [profileDetailsStatus]);

    const getNavOptions = (userRole: UserRoles) => USER_NAV_OPTIONS[userRole] || [];

    const getLabel = (userRole: UserRoles) => TITLE_AS_PER_USER_ROLE[userRole] || '';

    const getLayout = () => {
        if (isTokenFetched && isUserProfileDataFetched) {
            switch (userDetails.role as UserRoles) {
                case UserRoleMapper.NAR_ADMIN:
                    return <AdminLayout />;

                case UserRoleMapper.BROKER_MANAGER:
                    return <BrokerageAdminLayout />;

                case UserRoleMapper.ASSOCIATION_ADMIN:
                    return <AssociationAdminLayout />;

                default:
                    return <Unauthorized />;
            }
        } else return <Loader show fullscreen />;
    };

    return (
        <>
            <div className="auth-layout" id="auth-layout">
                <Header
                    label={getLabel(userDetails.role)}
                    shouldShowBaseLabel={
                        userDetails.role === UserRoleMapper.BROKER_MANAGER ||
                        userDetails.role === UserRoleMapper.ASSOCIATION_ADMIN
                    }
                    navOptions={getNavOptions(userDetails.role)}
                    userDetails={userDetails}
                    showHeader={isTokenFetched && isUserProfileDataFetched}
                />

                <div className="auth-layout__body">{getLayout()}</div>

                <Footer />
            </div>
        </>
    );
};

export default AuthorizedLayout;
