export enum HTTP_HEADER {
    ContentType = 'Content-Type',
    ActivePodId = 'Active-Pod-Id',
    Authorization = 'Authorization'
}

export const enum HTTP_STATUS_CODES {
    NOT_ACCEPTABLE = 406,
    CONFLICT = 409,
    CONDITIONAL = 428
}

export enum HTTP_METHOD {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE'
}
