export enum ToastVariant {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success'
}

export enum ToastPosition {
    TOP = 'top',
    BOTTOM = 'bottom'
}
