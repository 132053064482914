import React, { FC } from 'react';
import './Button.scss';

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'secondary' | 'warn' | 'default';
    children?: React.ReactNode;
    className?: string;
    startIcon?: string;
    disabled?: boolean;
};
const Button: FC<ButtonProps> = ({
    variant = 'primary',
    children,
    className = '',
    startIcon,
    disabled,
    ...other
}: ButtonProps) => {
    return (
        <button
            {...other}
            className={`button ${className} ${variant} ${disabled ? 'disabled' : ''}`}
            disabled={disabled}>
            {startIcon ? (
                <span className="button__start-icon">
                    <img src={startIcon} alt="start-icon" />
                </span>
            ) : (
                ''
            )}
            {children}
        </button>
    );
};

export default Button;
