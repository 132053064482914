import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'common/components';
import { strings } from 'common/constants/app-constants';
import { useAppDispatch } from 'common/redux/core';
import { ExternalUsersDetails } from 'modules/CommonModules/Users/models';
import { externalUsersActions } from 'modules/CommonModules/Users/redux';
import './ConfirmationModal.scss';

type ConfirmationModalProps = {
    selectedRow: ExternalUsersDetails | undefined;
    onCancel: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ selectedRow, onCancel }) => {
    const dispatch = useAppDispatch();

    const handleProceed = () => {
        if (selectedRow?.userInvitationGuid) {
            dispatch(
                externalUsersActions.deleteExternalUserBegin({
                    userInvitationGuid: selectedRow?.userInvitationGuid
                })
            );
            onCancel();
        }
    };

    return (
        <div>
            <Modal className="delete-modal" show size="md" onClose={onCancel}>
                <ModalHeader>
                    <div className="delete-modal__title">
                        {strings.EXTERNAL_USERS.DELETE_MODAL_TITLE}
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="delete-modal__body-content">
                        {strings.EXTERNAL_USERS.DELETE_MODAL_BODY}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-save" variant="warn" onClick={handleProceed}>
                        {strings.PROCEED}
                    </Button>
                    <Button className="btn-cancel" variant="secondary" onClick={onCancel}>
                        {strings.CANCEL}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ConfirmationModal;
