import TableColumnHeader from './TableColumnHeader';

type TableColumnProps = {
    className?: string;
    headerClassName?: string;
    headerName: string;
    field: string;
    sortable?: boolean;
    onSort?: (value: string) => void;
    sortValue?: string;
    cssStyles?: React.CSSProperties;
    renderer?: (value: any) => React.ReactNode;
};

const TableColumn = ({
    className,
    headerClassName,
    headerName = '',
    field = '',
    sortable = true,
    onSort,
    sortValue,
    cssStyles
}: TableColumnProps) => {
    return (
        <div style={{ ...cssStyles }} className={`custom-table__cell ${headerClassName ?? ''}`}>
            <TableColumnHeader
                className={className}
                headerName={headerName}
                sortable={sortable}
                field={field}
                sortValue={sortValue}
                onSort={onSort}
            />
        </div>
    );
};

export default TableColumn;
