import { REPORTS, REPORT_FORM_FIELDS } from 'common/enums';
import { dateUtil } from 'common/utils';

const reportReduxStateKeys = {
    [REPORTS.CompetencyCompletion]: 'competency',
    [REPORTS.PlatformUsage]: 'platform',
    [REPORTS.Endorsement]: 'endorsement',
    [REPORTS.Assessment]: 'assessment',
    [REPORTS.LearningTopic]: 'learningTopic',
    [REPORTS.AgentProgress]: 'agentProgress',
    [REPORTS.UserTopicProgress]: 'userTopicProgress',
    [REPORTS.EventLogs]: 'eventLogs',
    [REPORTS.Brokerages]: 'brokerages'
};

const downloadReportTypeKeys = {
    [REPORTS.CompetencyCompletion]: 'Competency',
    [REPORTS.PlatformUsage]: 'Platform',
    [REPORTS.Endorsement]: 'Endorsement',
    [REPORTS.Assessment]: 'Assessment',
    [REPORTS.LearningTopic]: 'LearningTopic',
    [REPORTS.AgentProgress]: 'AgentProgress',
    [REPORTS.UserTopicProgress]: 'UserTopicProgress',
    [REPORTS.EventLogs]: 'EventLog',
    [REPORTS.Brokerages]: 'Brokerages'
};

const adminReportOptions = [
    REPORTS.AgentProgress,
    REPORTS.Brokerages,
    REPORTS.Assessment,
    REPORTS.CompetencyCompletion,
    REPORTS.Endorsement,
    REPORTS.EventLogs,
    REPORTS.LearningTopic,
    REPORTS.PlatformUsage,
    REPORTS.UserTopicProgress
];

const brokerManagerReportOptions = [
    REPORTS.AgentProgress,
    REPORTS.CompetencyCompletion,
    REPORTS.Endorsement,
    REPORTS.LearningTopic,
    REPORTS.UserTopicProgress
];

const associateAdminReportOptions = [
    REPORTS.AgentProgress,
    REPORTS.CompetencyCompletion,
    REPORTS.Endorsement,
    REPORTS.LearningTopic,
    REPORTS.UserTopicProgress
];

const adminReportStyles = {
    [REPORTS.CompetencyCompletion]: {
        competency: {
            cssStyles: {
                flex: 0.5
            }
        },
        totalCompleted: {
            cssStyles: {
                flex: 0.5
            }
        },
        totalIncomplete: {
            cssStyles: {
                flex: 1
            }
        }
    },
    [REPORTS.PlatformUsage]: {
        item: {
            cssStyles: {
                flex: 0.75
            }
        }
    },
    [REPORTS.Endorsement]: {
        endorsementDate: {
            renderer: (value) => {
                return dateUtil.convertUTCtoLocalTime(value?.endorsementDate);
            }
        },
        renewalDate: {
            renderer: (value) => {
                return dateUtil.convertUTCtoLocalTime(value?.renewalDate);
            }
        }
    },
    [REPORTS.Assessment]: {
        attemptDate: {
            renderer: (value) => {
                return dateUtil.convertUTCtoLocalTime(value?.attemptDate);
            }
        }
    },
    [REPORTS.AgentProgress]: {
        endorsementDate: {
            renderer: (value) => {
                return dateUtil.convertUTCtoLocalTime(value?.endorsementDate);
            }
        },
        originalEndorsementDate: {
            renderer: (value) => {
                return dateUtil.convertUTCtoLocalTime(value?.originalEndorsementDate);
            }
        }
    },
    [REPORTS.EventLogs]: {
        created: {
            renderer: (value) => {
                return dateUtil.convertUTCtoLocalTime(value?.created);
            }
        }
    },
    [REPORTS.Brokerages]: {
        createdDate: {
            renderer: (value) => {
                return dateUtil.convertUTCtoLocalTime(value?.createdDate);
            }
        }
    },
    [REPORTS.UserTopicProgress]: {
        timeSpent: {
            sortable: false
        }
    }
};

const brokerManagerReportStyles = {
    [REPORTS.CompetencyCompletion]: {
        competency: {
            cssStyles: {
                flex: 0.5
            }
        },
        totalCompleted: {
            cssStyles: {
                flex: 0.5
            }
        },
        totalIncomplete: {
            cssStyles: {
                flex: 1
            }
        }
    },
    [REPORTS.UserTopicProgress]: {
        timeSpent: {
            sortable: false
        }
    }
};

const associateAdminReportStyles = {
    [REPORTS.CompetencyCompletion]: {
        competency: {
            cssStyles: {
                flex: 0.5
            }
        },
        totalCompleted: {
            cssStyles: {
                flex: 0.5
            }
        },
        totalIncomplete: {
            cssStyles: {
                flex: 1
            }
        }
    },
    [REPORTS.UserTopicProgress]: {
        timeSpent: {
            sortable: false
        }
    }
};

const adminReportFormFields = {
    [REPORTS.AgentProgress]: [
        {
            field: REPORT_FORM_FIELDS.Search
        },
        {
            field: REPORT_FORM_FIELDS.PrimaryAssociation
        }
    ],
    [REPORTS.Endorsement]: [
        {
            field: REPORT_FORM_FIELDS.Search
        },
        {
            field: REPORT_FORM_FIELDS.PrimaryAssociation
        }
    ]
};

const brokerManagerReportFormFields = {
    [REPORTS.AgentProgress]: [
        {
            field: REPORT_FORM_FIELDS.Search
        }
    ],
    [REPORTS.Endorsement]: [
        {
            field: REPORT_FORM_FIELDS.Search
        }
    ]
};

const associateAdminReportFormFields = {
    [REPORTS.AgentProgress]: [
        {
            field: REPORT_FORM_FIELDS.Search
        }
    ],
    [REPORTS.Endorsement]: [
        {
            field: REPORT_FORM_FIELDS.Search
        }
    ]
};

const reportsWithGenerateCsvBtn = [
    REPORTS.AgentProgress,
    REPORTS.Brokerages,
    REPORTS.Assessment,
    REPORTS.Endorsement,
    REPORTS.EventLogs,
    REPORTS.UserTopicProgress
];

const reportsWithPaginationAndSorting = [
    REPORTS.AgentProgress,
    REPORTS.Brokerages,
    REPORTS.Assessment,
    REPORTS.CompetencyCompletion,
    REPORTS.Endorsement,
    REPORTS.EventLogs,
    REPORTS.LearningTopic,
    REPORTS.UserTopicProgress
];

export const REPORTS_CONFIG = {
    adminReportOptions,
    adminReportStyles,
    brokerManagerReportOptions,
    brokerManagerReportStyles,
    associateAdminReportOptions,
    associateAdminReportStyles,
    reportReduxStateKeys,
    downloadReportTypeKeys,
    adminReportFormFields,
    brokerManagerReportFormFields,
    associateAdminReportFormFields,
    reportsWithGenerateCsvBtn,
    reportsWithPaginationAndSorting
};
