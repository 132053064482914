import { combineReducers } from 'redux';
import userProfile from '../userProfile/user-profile.slice';
import { usersReducer as users } from 'modules/CommonModules/Users/redux';
import { adminReducer as admin } from 'modules/AdminDashboard/redux';
import { reportReducer as reports } from 'modules/CommonModules/redux';
import { externalUsersReducer as externalUsers } from 'modules/CommonModules/Users/redux';

const rootReducer = combineReducers({
    userProfile,
    users,
    admin,
    reports,
    externalUsers
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
