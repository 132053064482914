import { AUTH_CONFIG, ENV_CONFIG } from 'common/configs';
import { SessionUtil } from 'common/utils';
import { HTTP_HEADER } from 'common/enums';
import { ApiConfig, ApiResult } from 'common/models';
import { BaseHTTPClient } from './base-http-client.service';

export abstract class HTTPClient extends BaseHTTPClient {
    protected static async invokeApi<T>(
        apiConfig: ApiConfig,
        contextHeader: { [key: string]: string } = {}
    ): Promise<ApiResult<T>> {
        let result;
        const accessToken = SessionUtil.get(AUTH_CONFIG.SESSION_KEY.TOKEN);

        const header = {
            ...contextHeader,
            ...(accessToken && { [HTTP_HEADER.Authorization]: `Bearer ${accessToken}` }) // adding accessToken to req
        };

        switch (apiConfig.method) {
            case 'GET': {
                const getUrl = `${ENV_CONFIG.API_URI}${apiConfig.url}${
                    apiConfig.queryString ?? ''
                }`;
                result = await this.get<T>(getUrl, header);
                break;
            }
            case 'POST': {
                const postUrl = `${ENV_CONFIG.API_URI}${apiConfig.url}${
                    apiConfig.queryString ?? ''
                }`;
                result = await this.post<T>(postUrl, apiConfig.data, header);
                break;
            }
            case 'PUT':
                result = await this.put<T>(
                    ENV_CONFIG.API_URI + apiConfig.url,
                    apiConfig.data,
                    header
                );
                break;
            case 'DELETE': {
                const deleteUrl = `${ENV_CONFIG.API_URI}${apiConfig.url}${
                    apiConfig.queryString ?? ''
                }`;
                result = await this.delete<T>(deleteUrl, apiConfig.data, header);
                break;
            }
        }
        return result;
    }

    protected static async invokeFileUpload<T>(
        apiConfig: ApiConfig,
        contextHeader: { [key: string]: string } = {},
        config?: any
    ): Promise<ApiResult<T>> {
        const accessToken = SessionUtil.get(AUTH_CONFIG.SESSION_KEY.TOKEN);
        const header = {
            ...contextHeader,
            ...(accessToken && { [HTTP_HEADER.Authorization]: `Bearer ${accessToken}` }) // adding accessToken to req
        };

        const url =
            ENV_CONFIG.API_URI +
            apiConfig.url +
            (apiConfig.queryString ? `${apiConfig.queryString}` : '');
        return await this.postFile(url, apiConfig.data, header, config);
    }

    protected static async invokeBlobDownload<T>(
        apiConfig: ApiConfig,
        contextHeader: { [key: string]: string } = {},
        config?: any
    ) {
        const accessToken = SessionUtil.get(AUTH_CONFIG.SESSION_KEY.TOKEN);
        const header = {
            ...contextHeader,
            ...(accessToken && { [HTTP_HEADER.Authorization]: `Bearer ${accessToken}` }) // adding accessToken to req
        };

        const url =
            ENV_CONFIG.API_URI +
            apiConfig.url +
            (apiConfig.queryString ? `${apiConfig.queryString}` : '');
        return await this.getBlob(url, apiConfig.method, apiConfig.data, header);
    }
}
