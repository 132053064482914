import { useEffect, useRef, useState } from 'react';
import { strings } from 'common/constants/app-constants';
import { Button, FileUpload, FileUploaderItem } from 'common/components';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { adminActions } from 'modules/AdminDashboard/redux';
import { ApiStatus, UPLOAD_SECTION } from 'common/enums';
import { MEDIA_COLLECTION, UPLOAD_FILE_CONFIG } from 'common/configs';
import ValidationModal from '../ValidationModal/ValidationModal';
import './FileActionsSection.scss';

type FileActionsSectionProps = {
    type: UPLOAD_SECTION;
};

const FileActionsSection = ({ type }: FileActionsSectionProps) => {
    const dispatch = useAppDispatch();

    const { status: uploadStatus } = useAppSelector(
        (state) => state.admin.endorsementMaterials[type].upload
    );

    const uploadedFiles = useAppSelector((state) => state.admin.endorsementMaterials.uploadedFiles);

    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [shouldShowValidationModal, setShowValidationModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState({
        header: '',
        body: '',
        footer: ''
    });

    const initialUploadingFileDetails = {
        loaded: 0,
        total: 0,
        fileName: ''
    };

    const [uploadingFileDetails, setUploadingFileDetails] = useState(initialUploadingFileDetails);

    const controllerRef = useRef<AbortController | null>(null);

    useEffect(() => {
        // once file uploaded reset the state
        if (uploadStatus === ApiStatus.SUCCESS) {
            setShowUpload(false);
            setUploadingFileDetails(initialUploadingFileDetails);
        }
    }, [uploadStatus]);

    const handleFileUpload = (files, type: UPLOAD_SECTION) => {
        const currentFile = files[0];
        if (currentFile) {
            const isInvalidFileType = !UPLOAD_FILE_CONFIG?.[type].validFileTypes.includes(
                currentFile.type
            );

            const isInvalidFileSize = currentFile.size > UPLOAD_FILE_CONFIG?.[type].maxFileSize;

            // check to not allow jpeg
            const isJPEG = currentFile.name.endsWith('.jpeg');

            if (isInvalidFileType || isInvalidFileSize || isJPEG) {
                generateModalContent(type);
                return;
            }

            const formData = new FormData();
            formData.append('file', files[0]);

            if (controllerRef.current) {
                controllerRef.current.abort(); // Abort any ongoing request before making a new one
            }

            // to cancel ongoing upload request when click on X icon
            const controller = new AbortController();
            controllerRef.current = controller; // Store the new AbortController
            const signal = controller.signal;

            const config = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    setUploadingFileDetails({ loaded, total, fileName: currentFile.name });
                },
                signal: signal //passing signal to track request to cancel it later
            };

            dispatch(adminActions.uploadFileBegin({ type, file: formData, config }));
        }
        setShowValidationModal(false);
    };

    const handleUploadCancel = () => {
        if (controllerRef.current) {
            controllerRef.current?.abort();
        }
        setUploadingFileDetails(initialUploadingFileDetails);
    };

    const handleDownloadBtnClick = (type: string) => {
        if (type === UPLOAD_SECTION.EndorsementZip) {
            dispatch(adminActions.fetchFileSasUriAndDownloadBegin({ type })); //fetch sasUri to download zip
        } else if (
            type === UPLOAD_SECTION.EndorsementCertificate ||
            type === UPLOAD_SECTION.AssessmentCertificate
        ) {
            dispatch(adminActions.downloadBlobDataBegin({ type })); //for jpg's, download blob first
        }
    };

    const handleReuploadClick = () => {
        setShowUpload(true);
    };

    const handleBackToListing = () => {
        handleUploadCancel();
        setShowUpload(false);
    };

    const generateModalContent = (type: UPLOAD_SECTION) => {
        let header = '';
        let body = '';
        let footer = '';

        switch (type) {
            case UPLOAD_SECTION.EndorsementZip: {
                header = strings.ENDORSEMENT_MATERIAL.ZIP_UPLOAD_ERROR_HEADER;
                body = strings.ENDORSEMENT_MATERIAL.ZIP_UPLOAD_ERROR_BODY;
                footer = strings.ENDORSEMENT_MATERIAL.ZIP_UPLOAD_ERROR_FOOTER;
                break;
            }
            case UPLOAD_SECTION.EndorsementCertificate:
            case UPLOAD_SECTION.AssessmentCertificate: {
                header = strings.ENDORSEMENT_MATERIAL.CERT_BG_UPLOAD_ERROR_HEADER;
                body = strings.ENDORSEMENT_MATERIAL.CERT_BG_UPLOAD_ERROR_BODY;
                footer = strings.ENDORSEMENT_MATERIAL.CERT_BG_UPLOAD_ERROR_FOOTER;
                break;
            }
        }
        setModalContent({ header, body, footer });
        setShowValidationModal(true);
    };

    const getHeaderLabel = (type: UPLOAD_SECTION) => {
        switch (type) {
            case UPLOAD_SECTION.EndorsementZip: {
                return (
                    <p className="settings-container__wrapper--font-md">
                        {strings.ENDORSEMENT_MATERIAL.UPLOAD_ENDORSEMENT_ZIP}
                    </p>
                );
            }
            case UPLOAD_SECTION.EndorsementCertificate: {
                return (
                    <p className="settings-container__wrapper--font-md">
                        {strings.ENDORSEMENT_MATERIAL.UPLOAD_ENDORSEMENT_CERT}
                    </p>
                );
            }
            case UPLOAD_SECTION.AssessmentCertificate: {
                return (
                    <p className="settings-container__wrapper--font-md">
                        {strings.ENDORSEMENT_MATERIAL.UPLOAD_ASSESSMENT_CERT}
                    </p>
                );
            }
        }
    };

    const getFileValidationsInfo = (type: UPLOAD_SECTION) => {
        switch (type) {
            case UPLOAD_SECTION.EndorsementZip: {
                return (
                    <div className="file-validation-info-wrapper">
                        <p className="file-validation-info-wrapper--font-sm">
                            {strings.ENDORSEMENT_MATERIAL.CONTENT_LIMIT_20_MB}
                        </p>
                        <p
                            className="file-validation-info-wrapper--back-btn"
                            onClick={handleBackToListing}>
                            <img src={MEDIA_COLLECTION.IC_BACK_ARROW} />
                            <span>{strings.ENDORSEMENT_MATERIAL.RETURN_TO_FILE_LISTING}</span>
                        </p>
                    </div>
                );
            }
            case UPLOAD_SECTION.EndorsementCertificate:
            case UPLOAD_SECTION.AssessmentCertificate: {
                return (
                    <div className="file-validation-info-wrapper">
                        <ul className="file-validation-info-wrapper--list">
                            <li className="file-validation-info-wrapper--font-sm">
                                {strings.ENDORSEMENT_MATERIAL.CONTENT_LIMIT_2_MB}
                            </li>
                            <li className="file-validation-info-wrapper--font-sm">
                                {strings.ENDORSEMENT_MATERIAL.IMAGE_TEMPLATE}
                            </li>
                            <li className="file-validation-info-wrapper--font-sm">
                                {strings.ENDORSEMENT_MATERIAL.FILE_IN_JPG}
                            </li>
                        </ul>
                        <p
                            className="file-validation-info-wrapper--back-btn"
                            onClick={handleBackToListing}>
                            <img src={MEDIA_COLLECTION.IC_BACK_ARROW} />
                            <span>{strings.ENDORSEMENT_MATERIAL.RETURN_TO_FILE_LISTING}</span>
                        </p>
                    </div>
                );
            }
        }
    };

    const getUploadedFileName = () =>
        uploadedFiles.data.find((file) => file.type === type)?.filename || '';

    const getFileIcon = () =>
        type === UPLOAD_SECTION.EndorsementZip
            ? MEDIA_COLLECTION.IC_ZIP_ICON
            : MEDIA_COLLECTION.IC_JPG_ICON;

    return (
        <>
            <div className="settings-container__wrapper--endorsement">
                {getUploadedFileName() && !showUpload ? (
                    <div className="download-container">
                        <p className="download-container__file-name">
                            <img src={getFileIcon()} alt="file-icon" />
                            <span>{getUploadedFileName()}</span>
                        </p>

                        <div className="download-container__action-btns">
                            <Button variant="primary" onClick={() => handleDownloadBtnClick(type)}>
                                {strings.DOWNLOAD}
                            </Button>
                            <Button variant="secondary" onClick={handleReuploadClick}>
                                {strings.REUPLOAD}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="upload-container--wrapper">
                        <div className="upload-container">
                            {getHeaderLabel(type)}
                            <FileUpload
                                className="settings-container__wrapper--file-upload"
                                onFileUpload={(files) => handleFileUpload(files, type)}
                                accept={UPLOAD_FILE_CONFIG[type].accept}
                            />
                            {getFileValidationsInfo(type)}
                        </div>

                        {uploadStatus === ApiStatus.LOADING && uploadingFileDetails.total ? (
                            <FileUploaderItem
                                fileName={uploadingFileDetails.fileName}
                                loadedSize={uploadingFileDetails.loaded}
                                totalSize={uploadingFileDetails.total}
                                onCancelUpload={handleUploadCancel}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </div>

            {shouldShowValidationModal && (
                <ValidationModal
                    content={modalContent}
                    onCancel={() => setShowValidationModal(false)}
                />
            )}
        </>
    );
};

export default FileActionsSection;
