const ROUTE_PATH = {
    ADMIN: 'admin',
    BROKER: 'broker',
    ASSOCIATION_ADMIN: 'association-admin',
    USERS: 'users',
    BROKERAGES: 'brokerages',
    MANAGE_LIBRARY: 'manage-library',
    REPORTS: 'reports',
    ENDORSEMENT_MATERIALS: 'endorsement-materials',
    LOG_OUT: 'logout',
    ADD_NEW_CONTENT: 'add-new-content'
};

const ROUTE_INDEX = {
    ADMIN: `/${ROUTE_PATH.ADMIN}`,
    BROKER: `/${ROUTE_PATH.BROKER}`,
    ASSOCIATION_ADMIN: `/${ROUTE_PATH.ASSOCIATION_ADMIN}`
};

const ADMIN_ROUTES = {
    INDEX: ROUTE_INDEX.ADMIN,
    USERS: `${ROUTE_INDEX.ADMIN}/${ROUTE_PATH.USERS}`,
    BROKERAGES: `${ROUTE_INDEX.ADMIN}/${ROUTE_PATH.BROKERAGES}`,
    MANAGE_LIBRARY: `${ROUTE_INDEX.ADMIN}/${ROUTE_PATH.MANAGE_LIBRARY}`,
    MANAGE_LIBRARY_ADD_NEW_CONTENT: `${ROUTE_INDEX.ADMIN}/${ROUTE_PATH.MANAGE_LIBRARY}/${ROUTE_PATH.ADD_NEW_CONTENT}`,
    REPORTS: `${ROUTE_INDEX.ADMIN}/${ROUTE_PATH.REPORTS}`,
    ENDORSEMENT_MATERIALS: `${ROUTE_INDEX.ADMIN}/${ROUTE_PATH.ENDORSEMENT_MATERIALS}`
};

const BROKERAGE_ADMIN_ROUTES = {
    INDEX: ROUTE_INDEX.BROKER,
    USERS: `${ROUTE_INDEX.BROKER}/${ROUTE_PATH.USERS}`,
    REPORTS: `${ROUTE_INDEX.BROKER}/${ROUTE_PATH.REPORTS}`
};

const ASSOCIATION_ADMIN_ROUTES = {
    INDEX: ROUTE_INDEX.ASSOCIATION_ADMIN,
    MANAGE_LIBRARY: `${ROUTE_INDEX.ASSOCIATION_ADMIN}/${ROUTE_PATH.MANAGE_LIBRARY}`,
    MANAGE_LIBRARY_ADD_NEW_CONTENT: `${ROUTE_INDEX.ASSOCIATION_ADMIN}/${ROUTE_PATH.MANAGE_LIBRARY}/${ROUTE_PATH.ADD_NEW_CONTENT}`,
    REPORTS: `${ROUTE_INDEX.ASSOCIATION_ADMIN}/${ROUTE_PATH.REPORTS}`
};

const LOG_OUT_ROUTE = `/${ROUTE_PATH.LOG_OUT}`;

const ROUTES = {
    DEFAULT: '/',
    WILD: '*',
    ADMIN: ADMIN_ROUTES,
    BROKER: BROKERAGE_ADMIN_ROUTES,
    ASSOCIATION_ADMIN: ASSOCIATION_ADMIN_ROUTES,
    LOG_OUT: LOG_OUT_ROUTE
};

export { ROUTES, ROUTE_PATH };
