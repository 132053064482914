import { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    CustomTable,
    DownloadLoader,
    Input,
    Pagination,
    PrimaryAssociationSelect,
    Scenery,
    Select,
    TableColumn
} from 'common/components';
import {
    DEFAULT_PAGE_SIZE,
    MEDIA_COLLECTION,
    PAGINATION_DEBOUNCE_TIME,
    POLLING_CONFIG
} from 'common/configs';
import { strings } from 'common/constants/app-constants';
import { AppUtil, dateUtil } from 'common/utils';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { REPORTS_CONFIG } from 'common/configs';
import { reportActions } from 'modules/CommonModules/redux';
import {
    REPORTS,
    ApiStatus,
    REPORT_FORM_FIELDS,
    UserRoleMapper,
    REPORT_GENERATION_STATUS,
    ToastVariant
} from 'common/enums';
import { SelectOption } from 'common/models';
import { GenerateReportRequestBody, ReportDataRequest } from 'modules/AdminDashboard/models';
import { useDebounce } from 'common/hooks';
import { ToastMessageUtil } from 'common/services';
import './Reports.scss';

let reportStatusTimerId: any = undefined;

const Reports = () => {
    const dispatch = useAppDispatch();
    const [selectedReport, setSelectedReport] = useState<string>('');
    const [staffReport, setStaffReport] = useState<boolean>(false);

    const reports = useAppSelector((state) => state.reports);
    const { data: generateReportData, status: generateReportStatus } = useAppSelector(
        (state) => state.reports.generateReport
    );
    const { data: fetchedReportGenInfo, status: fetchedReportGenStatus } = useAppSelector(
        (state) => state.reports.generationStatusInfo
    );

    const userRole = useAppSelector((state) => state.userProfile.profileDetails.data.role);
    const userIsStaff = useAppSelector((state) => state.userProfile.profileDetails.data.isStaff);

    const currentReportData = {
        headers:
            reports[REPORTS_CONFIG.reportReduxStateKeys[selectedReport]]?.reportData?.data?.headers,
        rowData:
            reports[REPORTS_CONFIG.reportReduxStateKeys[selectedReport]]?.reportData?.data?.report
                ?.items,
        totalRows:
            reports[REPORTS_CONFIG.reportReduxStateKeys[selectedReport]]?.reportData?.data?.report
                ?.totalResults,
        status: reports[REPORTS_CONFIG.reportReduxStateKeys[selectedReport]]?.reportData?.status,
        downloadReportStatus:
            reports[REPORTS_CONFIG.reportReduxStateKeys[selectedReport]]?.download?.status,
        downloadReportErrors:
            reports[REPORTS_CONFIG.reportReduxStateKeys[selectedReport]]?.download?.errors
    };

    const initialFormData = {
        searchQuery: '',
        selectedAssociation: {
            id: 'All',
            name: 'All'
        },
        isStaff: false
    };

    const [formData, setFormData] = useState(initialFormData);

    const initialCurrentPage = {
        debouncedPageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        sortValue: '',
        sortOrder: '',
        sortColumn: ''
    };

    const [currentPage, setCurrentPage] = useState(initialCurrentPage);
    const [isSearchActive, setSearchActive] = useState<boolean>(false);
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const debouncedPageNum = useDebounce(currentPageNum, PAGINATION_DEBOUNCE_TIME) as number;
    const [isGeneratingReport, setGeneratingReport] = useState<boolean>(false);

    // if generate btn is available then that report should be downloaded using SAS url
    // else it should be downloaded using the csv string returned from the api
    const hasGenerateBtn = REPORTS_CONFIG.reportsWithGenerateCsvBtn.includes(
        selectedReport as REPORTS
    );

    const shouldDisableDownloadBtn =
        hasGenerateBtn &&
        (fetchedReportGenInfo.status !== REPORT_GENERATION_STATUS.Completed || isGeneratingReport);

    const shouldDisableGenerateBtn =
        hasGenerateBtn &&
        (fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.InProgress || isGeneratingReport);

    useEffect(() => {
        return () => {
            setGeneratingReport(false);
            stopPolling();
            dispatch(reportActions.resetAllReportsData());
            dispatch(reportActions.resetGenerateReport());
        };
    }, []);

    useEffect(() => {
        if (debouncedPageNum != currentPage.debouncedPageNum) {
            setCurrentPage((prevState) => ({
                ...prevState,
                debouncedPageNum: debouncedPageNum
            }));
        }
    }, [debouncedPageNum]);

    useEffect(() => {
        callFetchReportService(selectedReport);
    }, [currentPage]);

    useEffect(() => {
        // clear report data
        setGeneratingReport(false);
        stopPolling();
        dispatch(reportActions.resetAllReportsData());
        dispatch(reportActions.resetReportGenerationStatus());
        setCurrentPage(initialCurrentPage);
        setCurrentPageNum(1);
        setFormData(initialFormData);
        setSearchActive(false);
    }, [selectedReport,staffReport]);

    useEffect(() => {
        if (fetchedReportGenStatus === ApiStatus.SUCCESS) {
            if (fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.InProgress) {
                setGeneratingReport(true);
                startPolling();
            } else if (fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.Completed) {
                setGeneratingReport(false);
                stopPolling();
            } else if (
                fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.Failed &&
                isGeneratingReport
            ) {
                setGeneratingReport(false);
                stopPolling();
                ToastMessageUtil.show(
                    strings.REPORTS.GENERATION_FAILED,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
        }
    }, [fetchedReportGenStatus]);

    // once generate report api is success, then start polling to fetch the report status
    useEffect(() => {
        if (generateReportStatus === ApiStatus.SUCCESS && generateReportData.isSuccess) {
            setGeneratingReport(true);
            startPolling();
        } else if (generateReportStatus === ApiStatus.ERROR) {
            stopPolling();
            setGeneratingReport(false);
            ToastMessageUtil.show(
                strings.SOMETHING_WENT_WRONG,
                () => dispatch(reportActions.resetGenerateReport()),
                '',
                ToastVariant.ERROR
            );
        }
    }, [generateReportStatus]);

    useEffect(() => {
        if (currentReportData.downloadReportStatus === ApiStatus.ERROR) {
            if (
                currentReportData.downloadReportErrors &&
                currentReportData.downloadReportErrors?.length
            ) {
                const errMsg = currentReportData.downloadReportErrors || '';
                ToastMessageUtil.show(
                    errMsg as string,
                    () => dispatch(reportActions.resetDownloadReports()),
                    '',
                    ToastVariant.ERROR
                );
            } else {
                ToastMessageUtil.show(
                    strings.SOMETHING_WENT_WRONG,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
        }
    }, [currentReportData.downloadReportStatus]);

    const startPolling = () => {
        stopPolling();

        reportStatusTimerId = setInterval(
            () =>
                dispatch(
                    reportActions.fetchReportGenerationStatusBegin({
                        reportType: REPORTS_CONFIG.downloadReportTypeKeys[selectedReport]
                    })
                ),
            POLLING_CONFIG.FETCH_REPORT_GEN_STATUS
        );
    };

    const stopPolling = () => {
        if (reportStatusTimerId) {
            clearInterval(reportStatusTimerId);
            reportStatusTimerId = undefined;
        }
    };

    const callFetchReportService = (selectedReport: string) => {
        if (selectedReport) {
            const requestParams: ReportDataRequest = {
                ...currentPage,
                pageNum: currentPage.debouncedPageNum,
                searchQuery: formData.searchQuery,
                primaryAssociationId:
                    formData.selectedAssociation.id === 'All'
                        ? ''
                        : formData.selectedAssociation.id, // if All selected then send it as blank, so api will return data for all
                isPagination: true,
                isStaff: staffReport
            };

            // check if the report status api is already called or not, if no then call it
            if (fetchedReportGenStatus === ApiStatus.NONE) {
                dispatch(
                    reportActions.fetchReportGenerationStatusBegin({
                        reportType: REPORTS_CONFIG.downloadReportTypeKeys[selectedReport]
                    })
                );
            }

            switch (selectedReport) {
                case REPORTS.CompetencyCompletion: {
                    dispatch(reportActions.fetchCompetencyReportDataBegin({ ...requestParams }));
                    break;
                }
                case REPORTS.PlatformUsage: {
                    dispatch(reportActions.fetchPlatformReportDataBegin());
                    break;
                }
                case REPORTS.Endorsement: {
                    dispatch(reportActions.fetchEndorsementReportDataBegin({ ...requestParams }));
                    break;
                }
                case REPORTS.Assessment: {
                    dispatch(reportActions.fetchAssessmentReportDataBegin({ ...requestParams }));
                    break;
                }
                case REPORTS.LearningTopic: {
                    dispatch(reportActions.fetchLearningTopicReportDataBegin({ ...requestParams }));
                    break;
                }
                case REPORTS.AgentProgress: {
                    dispatch(reportActions.fetchAgentProgressReportDataBegin({ ...requestParams }));
                    break;
                }
                case REPORTS.UserTopicProgress: {
                    dispatch(reportActions.fetchUserTopicReportDataBegin({ ...requestParams }));
                    break;
                }
                case REPORTS.EventLogs: {
                    dispatch(reportActions.fetchEventLogsReportDataBegin({ ...requestParams }));
                    break;
                }
                case REPORTS.Brokerages: {
                    dispatch(reportActions.fetchBrokeragesReportDataBegin({ ...requestParams }));
                    break;
                }
            }
        }
    };

    const handleEnterPressed = () => {
        handleSearch();
    };

    const handleBtnClick = (btnType) => {
        switch (btnType) {
            case 'downloadCSV': {
                dispatch(
                    reportActions.downloadReportBegin({
                        reportType: REPORTS_CONFIG.downloadReportTypeKeys[selectedReport],
                        reduxStateKey: REPORTS_CONFIG.reportReduxStateKeys[selectedReport],
                        shouldDownloadUsingSasUrl: hasGenerateBtn,
                        isStaff: staffReport
                    })
                );
                break;
            }
            case 'generateCSV': {
                setGeneratingReport(true);

                let requestBody: GenerateReportRequestBody = {
                    searchFilters: null,
                    fromDate: null,
                    toDate: null,
                    primaryAssociationId: null,
                    isStaff: staffReport
                };

                if (isSearchActive) {
                    requestBody = {
                        searchFilters: formData.searchQuery || null,
                        fromDate: null,
                        toDate: null,
                        primaryAssociationId:
                            formData.selectedAssociation.id === 'All'
                                ? null
                                : formData.selectedAssociation.id,
                        isStaff: staffReport
                    };
                }

                dispatch(
                    reportActions.generateReportBegin({
                        reportType: REPORTS_CONFIG.downloadReportTypeKeys[selectedReport],
                        body: {
                            ...requestBody
                        }
                    })
                );
                break;
            }
            default:
                break;
        }
    };

    const handleSort = (value: string) => {
        const res = AppUtil.getSortOrderAndColumn(value);

        if (res) {
            setCurrentPage((prevState) => ({
                ...prevState,
                sortValue: value,
                sortOrder: res.sortOrder,
                sortColumn: res.columnName
            }));
        }
    };

    const handlePageChange = (pageNum: number) => {
        setCurrentPageNum(pageNum);
    };

    const onPageSizeChange = (pageSize: number) => {
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1,
            pageSize: pageSize
        }));
    };

    const handleChange = (fieldName: string, value) => {
        if (fieldName === 'isStaff') {
            setStaffReport(value);
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value
        }));
    };

    const getRoleBasedReportOptions = () => {
        switch (userRole) {
            case UserRoleMapper.NAR_ADMIN: {
                return REPORTS_CONFIG.adminReportOptions;
            }
            case UserRoleMapper.BROKER_MANAGER: {
                return REPORTS_CONFIG.brokerManagerReportOptions;
            }
            case UserRoleMapper.ASSOCIATION_ADMIN: {
                return REPORTS_CONFIG.associateAdminReportOptions;
            }
            default:
                return [];
        }
    };

    const getReportBasedFormFields = () => {
        switch (userRole) {
            case UserRoleMapper.NAR_ADMIN: {
                return REPORTS_CONFIG.adminReportFormFields[selectedReport];
            }
            case UserRoleMapper.BROKER_MANAGER: {
                return REPORTS_CONFIG.brokerManagerReportFormFields[selectedReport];
            }
            case UserRoleMapper.ASSOCIATION_ADMIN: {
                return REPORTS_CONFIG.associateAdminReportFormFields[selectedReport];
            }
            default:
                return [];
        }
    };

    const getReportBasedStyles = () => {
        switch (userRole) {
            case UserRoleMapper.NAR_ADMIN: {
                return REPORTS_CONFIG.adminReportStyles[selectedReport];
            }
            case UserRoleMapper.BROKER_MANAGER: {
                return REPORTS_CONFIG.brokerManagerReportStyles[selectedReport];
            }
            case UserRoleMapper.ASSOCIATION_ADMIN: {
                return REPORTS_CONFIG.associateAdminReportStyles[selectedReport];
            }
            default:
                return [];
        }
    };

    const getField = (item) => {
        switch (item.field) {
            case REPORT_FORM_FIELDS.Search: {
                return (
                    <Input
                        id="report-search"
                        key={`report-search ${selectedReport}`}
                        className="report-search-field"
                        placeHolder={strings.USERS.SEARCH}
                        value={formData.searchQuery}
                        startIcon={MEDIA_COLLECTION.IC_SEARCH}
                        onChange={(e) => handleChange('searchQuery', e.target.value)}
                        onClear={() => handleChange('searchQuery', '')}
                        onEnterPressed={handleEnterPressed}
                        showClearBtn
                    />
                );
            }
            case REPORT_FORM_FIELDS.PrimaryAssociation: {
                return (
                    <PrimaryAssociationSelect
                        key={`reports-primary-association ${selectedReport}`}
                        className="report-primary-association-field"
                        placeHolder={strings.USERS.PRIMARY_ASSOCIATION}
                        onChange={(selectedOption: SelectOption) =>
                            handleChange('selectedAssociation', selectedOption)
                        }
                        value={formData.selectedAssociation}
                    />
                );
            }
        }
    };

    const handleSearch = () => {
        setSearchActive(true);
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1
        }));
    };

    const handleClearSearch = () => {
        setSearchActive(false);
        setFormData(initialFormData);
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1
        }));
    };

    return (
        <div className="reports-container">
            <div className="reports-container__header">
                <h2>{strings.REPORTS.TITLE}</h2>

                {currentReportData.rowData?.length &&
                (fetchedReportGenStatus === ApiStatus.SUCCESS || isGeneratingReport) ? (
                    <div className="reports-container__header--action-btns">
                        {((isGeneratingReport && hasGenerateBtn) ||
                            (!hasGenerateBtn && // if generate btn is not available then show loader only if download is in progress
                                currentReportData.downloadReportStatus === ApiStatus.LOADING)) && (
                            <div className="download-csv-status">
                                <DownloadLoader />
                                <span className="label">{strings.REPORTS.CREATING_CSV}</span>{' '}
                            </div>
                        )}
                        {hasGenerateBtn && (
                            <div className="generate-csv-wrapper">
                                <Button
                                    variant="secondary"
                                    disabled={shouldDisableGenerateBtn}
                                    startIcon={
                                        shouldDisableGenerateBtn
                                            ? MEDIA_COLLECTION.IC_GENERATE_CSV_DISABLED
                                            : MEDIA_COLLECTION.IC_GENERATE_CSV
                                    }
                                    onClick={() => handleBtnClick('generateCSV')}>
                                    {strings.GENERATE_CSV}
                                </Button>

                                {fetchedReportGenInfo.generatedDate ? (
                                    <p className="generated-info">
                                        <span className="generated-info__label">
                                            {strings.REPORTS.LAST_GENERATED}
                                        </span>{' '}
                                        <span className="generated-info__date">
                                            {dateUtil.convertUTCtoLocalTime(
                                                fetchedReportGenInfo.generatedDate
                                            )}
                                        </span>
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>
                        )}
                        <Button
                            variant="secondary"
                            disabled={shouldDisableDownloadBtn}
                            startIcon={
                                shouldDisableDownloadBtn
                                    ? MEDIA_COLLECTION.IC_DOWNLOAD_DISABLED
                                    : MEDIA_COLLECTION.IC_DOWNLOAD
                            }
                            onClick={() => handleBtnClick('downloadCSV')}>
                            {strings.DOWNLOAD_CSV}
                        </Button>
                    </div>
                ) : (
                    ''
                )}
            </div>

            <div className="reports-container__body">
                <div className="reports-container__body--select-container">
                    <Select
                        className="report-select-field"
                        options={getRoleBasedReportOptions().map((item) => {
                            return {
                                id: item,
                                name: item
                            };
                        })}
                        placeHolder={strings.REPORTS.SELECT_REPORT}
                        onChange={(val: SelectOption) => setSelectedReport(val.id as string)}
                    />
                    {userRole === UserRoleMapper.BROKER_MANAGER && userIsStaff && (
                        <Checkbox
                            className="report-staff-checkbox-field"
                            key="report-staff-checkbox"
                            label={strings.REPORTS.STAFF_REPORT}
                            checked={staffReport}
                            onChange={(isChecked) => handleChange('isStaff', isChecked)}
                        />
                    )}
                </div>

                {/* show search & primary association dropdown for specific reports */}
                {getReportBasedFormFields()?.length && currentReportData.headers?.length ? (
                    <>
                        <div className="reports-container__body--formfields-container">
                            {getReportBasedFormFields().map((item) => {
                                return getField(item);
                            })}

                            <Button className="search-btn" variant="primary" onClick={handleSearch}>
                                {strings.USERS.SEARCH}
                            </Button>
                        </div>

                        {isSearchActive && (
                            <div className="reports-container__body--search-info">
                                <p>{`${strings.SEARCH_RESULTS} (${
                                    currentReportData.status === ApiStatus.LOADING
                                        ? '-'
                                        : currentReportData.totalRows
                                })`}</p>{' '}
                                <Button
                                    variant="primary"
                                    className="clear-btn"
                                    startIcon={MEDIA_COLLECTION.IC_CLOSE}
                                    onClick={handleClearSearch}>
                                    {strings.CLEAR_SEARCH}
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    ''
                )}

                {!selectedReport ? (
                    <Scenery />
                ) : currentReportData.rowData?.length == 0 &&
                  currentReportData.status === ApiStatus.LOADING ? (
                    <div className="search-loader">
                        <p className="search-content">{strings.LOADING}</p>
                        <Scenery />
                    </div>
                ) : currentReportData.status === ApiStatus.SUCCESS &&
                  currentReportData.rowData?.length == 0 ? (
                    <p className="users-container__body--no-data">{strings.NO_DATA_FOUND}</p>
                ) : (
                    <CustomTable
                        className="reports-table"
                        tableHeaderClass="table-head"
                        rowData={currentReportData.rowData}
                        enableSort={REPORTS_CONFIG.reportsWithPaginationAndSorting.includes(
                            selectedReport as REPORTS
                        )}
                        handleSort={handleSort}
                        sortValue={currentPage.sortValue}
                        showLoader={currentReportData.status === ApiStatus.LOADING}>
                        {currentReportData.headers?.map((field, i) => {
                            const styles = getReportBasedStyles()?.[field.field];

                            return (
                                <TableColumn
                                    key={i}
                                    headerName={field.headerName as string}
                                    field={field.field as string}
                                    cssStyles={styles?.cssStyles}
                                    sortable={styles?.sortable === false ? false : true}
                                    renderer={
                                        styles?.renderer
                                            ? (rowData) => styles.renderer(rowData)
                                            : undefined
                                    }
                                />
                            );
                        })}
                    </CustomTable>
                )}
            </div>

            {currentReportData.totalRows ? (
                <Pagination
                    totalRows={currentReportData.totalRows}
                    currentPageNum={currentPageNum}
                    onPageChange={handlePageChange}
                    pageSize={currentPage.pageSize}
                    onPageSizeChange={onPageSizeChange}
                    disabled={
                        !REPORTS_CONFIG.reportsWithPaginationAndSorting.includes(
                            selectedReport as REPORTS
                        )
                    }
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default Reports;
