import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MEDIA_COLLECTION } from 'common/configs';
import Select from '../Select/Select';
import { SelectOption } from 'common/models';
import './AppDatePicker.scss';

type AppDatePickerProps = {
    id: string;
    className?: string;
    value: number | string | Date;
    onChange: (selectedDate: string) => void;
    placeHolder: string;
};

const AppDatePicker = ({ id, className, value, onChange, placeHolder }: AppDatePickerProps) => {
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const years = Array.from({ length: 51 }, (v, i) => 1990 + i); // Generate an array of years from 1990 to + 2040
    const months = [
        { id: 0, name: 'Jan' },
        { id: 1, name: 'Feb' },
        { id: 2, name: 'Mar' },
        { id: 3, name: 'Apr' },
        { id: 4, name: 'May' },
        { id: 5, name: 'Jun' },
        { id: 6, name: 'Jul' },
        { id: 7, name: 'Aug' },
        { id: 8, name: 'Sep' },
        { id: 9, name: 'Oct' },
        { id: 10, name: 'Nov' },
        { id: 11, name: 'Dec' }
    ];

    const handleCalendarToggle = (e) => {
        setIsCalendarOpen(!isCalendarOpen);
    };

    const handleDateChange = (date) => {
        onChange(date);
    };

    const handleKeyDown = (e) => {
        // Allow only numeric keys, backspace, and arrow keys
        if (
            !/^\d$/.test(e.key) &&
            e.key !== 'Backspace' &&
            e.key !== 'ArrowLeft' &&
            e.key !== 'ArrowRight'
        ) {
            e.preventDefault();
        }
    };

    return (
        <div id={id} className={`date-picker ${className}`}>
            <div
                className={`date-picker__wrapper ${value ? 'calendar__has-value' : ''} ${
                    isCalendarOpen ? 'calendar__opened' : ''
                }`}>
                <DatePicker
                    renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        increaseYear,
                        decreaseYear
                    }) => (
                        // overriding default styles
                        <div className="date-picker__header-controls">
                            <div className="date-picker__header-controls--year">
                                <img
                                    className="prev-btn"
                                    onClick={decreaseYear}
                                    src={MEDIA_COLLECTION.IC_PREVIOUS}
                                />

                                <Select
                                    className="year-select"
                                    options={years.map((item) => {
                                        return {
                                            id: item,
                                            name: item
                                        };
                                    })}
                                    defaultOption={
                                        [date.getFullYear()].map((year) => {
                                            return {
                                                id: year,
                                                name: year
                                            };
                                        })[0]
                                    }
                                    placeHolder=""
                                    onChange={(val: SelectOption) => {
                                        changeYear(val.id);
                                    }}
                                />

                                <img
                                    className="next-btn"
                                    onClick={increaseYear}
                                    src={MEDIA_COLLECTION.IC_NEXT}
                                />
                            </div>

                            <div className="date-picker__header-controls--month">
                                <img
                                    className="prev-btn"
                                    onClick={decreaseMonth}
                                    src={MEDIA_COLLECTION.IC_PREVIOUS}
                                />
                                <Select
                                    className="month-select"
                                    options={months.map((month) => {
                                        return {
                                            id: month.id,
                                            name: month.name
                                        };
                                    })}
                                    defaultOption={
                                        [months[date.getMonth()].name].map((month) => {
                                            return {
                                                id: month,
                                                name: month
                                            };
                                        })[0]
                                    }
                                    placeHolder=""
                                    onChange={(val: SelectOption) => {
                                        changeMonth(val.id);
                                    }}
                                />

                                <img
                                    className="next-btn"
                                    onClick={increaseMonth}
                                    src={MEDIA_COLLECTION.IC_NEXT}
                                />
                            </div>
                        </div>
                    )}
                    selected={value}
                    onChange={handleDateChange}
                    className={`custom-date-picker ${isCalendarOpen ? 'calendar__opened' : ''}`}
                    onCalendarOpen={handleCalendarToggle}
                    onCalendarClose={handleCalendarToggle}
                    onKeyDown={handleKeyDown}
                    dateFormat="MM/dd/yyyy"
                />
                {<div className={`input-field__label`}>{placeHolder}</div>}
            </div>
            <div>
                <img
                    className="calendar__icon"
                    src={MEDIA_COLLECTION.IC_CALENDAR}
                    onClick={(e) => e.preventDefault()}
                    alt="calendar-icon"
                />
            </div>
        </div>
    );
};

export default AppDatePicker;
