import moment from 'moment';

const formatDateToYYYYMMDD = (date) => {
    if (!(date instanceof Date)) {
        return '';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
};

const getCurrentDateInYYYYMMDD = () => {
    const currentDate = new Date();

    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // months are zero-based (0 = January)
    const year = currentDate.getFullYear();

    // Pad single-digit day and month values with leading zeros
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Create the formatted date string in "dd/mm/yyyy" format
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    return formattedDate;
};

const convertTimeToDDMMMYYYY = (date) => {
    if (date) {
        const localTime = moment.utc(date).format('DD MMM YYYY');
        return localTime;
    }
    return date;
};

const convertUTCtoLocalTime = (date: string) => {
    if (date) {
        const localTime = moment.utc(date).local().format('MM/DD/YYYY, hh:mm A');
        return localTime;
    }
    return date;
};

const convertUTCtoLocalDate = (date: string) => {
    if (date) {
        const localDate = moment.utc(date).local().format('MM/DD/YYYY');
        return localDate;
    }
    return date;
};

export default {
    formatDateToYYYYMMDD,
    getCurrentDateInYYYYMMDD,
    convertTimeToDDMMMYYYY,
    convertUTCtoLocalTime,
    convertUTCtoLocalDate
};
