import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { strings } from 'common/constants/app-constants';
import { ApiStatus, UPLOAD_SECTION } from 'common/enums';
import FileActionsSection from './Components/FileActionsSection/FileActionsSection';
import { adminActions } from 'modules/AdminDashboard/redux';
import { Loader, EndorsementMaterialsScenery } from 'common/components';
import './EndorsementMaterials.scss';

const EndorsementMaterials = () => {
    const dispatch = useAppDispatch();

    const { status: uploadedFilesStatus } = useAppSelector(
        (state) => state.admin.endorsementMaterials.uploadedFiles
    );

    useEffect(() => {
        dispatch(adminActions.fetchUploadedFilesDataBegin());
        return () => {
            dispatch(adminActions.resetSiteSettings());
        };
    }, []);

    return (
        <div className="endorsement-container">
            <div className="endorsement-container__header">
                <h2>{strings.ENDORSEMENT_MATERIAL.TITLE}</h2>
            </div>

            {uploadedFilesStatus === ApiStatus.SUCCESS ? (
                <div className="endorsement-container__wrapper">
                    <p className="endorsement-container__wrapper--sub-heading">
                        {strings.ENDORSEMENT_MATERIAL.EDIT_ENDORSEMENT}
                    </p>

                    <>
                        <FileActionsSection type={UPLOAD_SECTION.EndorsementZip} />
                        <FileActionsSection type={UPLOAD_SECTION.EndorsementCertificate} />
                        <FileActionsSection type={UPLOAD_SECTION.AssessmentCertificate} />
                        <EndorsementMaterialsScenery />
                    </>
                </div>
            ) : (
                ''
            )}

            <Loader show={uploadedFilesStatus === ApiStatus.LOADING} fullscreen />
        </div>
    );
};

export default EndorsementMaterials;
