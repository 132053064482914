import { HTTP_METHOD } from 'common/enums';
import { ApiConfig } from 'common/models';
import {
    BrokeragesListRequest,
    DownloadBlobRequest,
    EditBrokerageRequest,
    FetchFileSasUriRequest,
    UploadFileRequest
} from 'modules/AdminDashboard/models';
import { CONTROLLER, METHOD, QUERY_STRINGS } from './endpoint.config';
import { RequestUtil } from 'common/utils';

export const adminActionsApiConfig = {
    fetchBrokeragesList: (request: BrokeragesListRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.BROKERAGES}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchCompetencyReportData: (): ApiConfig => {
        return new ApiConfig(`${CONTROLLER.REPORTS}/${METHOD.COMPETENCY}`, HTTP_METHOD.GET);
    },

    fetchPlatformReportData: (): ApiConfig => {
        return new ApiConfig(`${CONTROLLER.REPORTS}/${METHOD.PLATFORM}`, HTTP_METHOD.GET);
    },

    fetchFileSasUriAndDownload: (request: FetchFileSasUriRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.FILE_DETAILS}`,
            HTTP_METHOD.GET,
            `?${QUERY_STRINGS.DOC_TYPE}=${request.type}`
        );
    },

    uploadFile: (request: UploadFileRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.FILE_UPLOAD}`,
            HTTP_METHOD.POST,
            `?${QUERY_STRINGS.DOC_TYPE}=${request.type}`,
            request.file
        );
    },

    fetchUploadedFilesData: (): ApiConfig => {
        return new ApiConfig(`${CONTROLLER.ADMIN}/${METHOD.FILE_SETTINGS}`, HTTP_METHOD.GET);
    },

    downloadBlobData: (request: DownloadBlobRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.FILE_DOWNLOAD}`,
            HTTP_METHOD.POST,
            `?${QUERY_STRINGS.DOC_TYPE}=${request.type}`
        );
    },

    editBrokerage: (request: EditBrokerageRequest): ApiConfig => {
        return new ApiConfig(`${CONTROLLER.BROKERAGES}`, HTTP_METHOD.POST, '', request);
    }
};
