import { DEFAULT_PAGE_SIZE, MEDIA_COLLECTION, PAGE_SIZE_OPTIONS } from 'common/configs';
import { Select, Button } from 'common/components';
import { SelectOption } from 'common/models';
import './Pagination.scss';

type PaginationProps = {
    totalRows: number;
    currentPageNum: number;
    pageSize: number;
    disabled?: boolean;
    onPageChange: (pageNum: number) => void;
    onPageSizeChange: (pageSize: number) => void;
};

const Pagination = ({
    totalRows,
    currentPageNum,
    pageSize,
    disabled,
    onPageChange,
    onPageSizeChange
}: PaginationProps) => {
    const totalPages = Math.ceil(totalRows / pageSize);

    const handlePageChange = (page) => {
        onPageChange(page);
    };

    const renderPageNumbers = () => (
        <p className="pagination__count">
            <span className="pagination__count--current-page">{currentPageNum}</span>
            <span className="pagination__count--devider">{'/'}</span>
            <span className="pagination__count--total-pages">{totalPages}</span>
        </p>
    );

    const showOption = (size) => {
        return `Show ${size}`;
    };

    return (
        <div className={`pagination ${disabled ? 'pagination--disabled' : ''}`}>
            <Select
                className="pagination-select"
                options={PAGE_SIZE_OPTIONS.map((size) => {
                    return {
                        id: size,
                        name: showOption(size)
                    };
                })}
                placeHolder={''}
                disabled={disabled}
                defaultOption={{ id: pageSize, name: showOption(pageSize) }}
                onChange={(val: SelectOption) => onPageSizeChange(+val.id)}
            />

            <Button
                variant="default"
                disabled={currentPageNum === 1 || disabled}
                onClick={() => handlePageChange(1)}>
                <img src={MEDIA_COLLECTION.IC_FIRST_PAGE} aria-hidden="true" />
            </Button>

            <Button
                variant="default"
                disabled={currentPageNum === 1 || disabled}
                onClick={() => handlePageChange(currentPageNum - 1)}>
                <img src={MEDIA_COLLECTION.IC_PREVIOUS_PAGE} aria-hidden="true" />
            </Button>

            {renderPageNumbers()}

            <Button
                variant="default"
                disabled={currentPageNum === totalPages || disabled}
                onClick={() => handlePageChange(currentPageNum + 1)}>
                <img src={MEDIA_COLLECTION.IC_NEXT_PAGE} aria-hidden="true" />
            </Button>

            <Button
                variant="default"
                disabled={currentPageNum === totalPages || disabled}
                onClick={() => handlePageChange(totalPages)}>
                <img src={MEDIA_COLLECTION.IC_LAST_PAGE} aria-hidden="true" />
            </Button>
        </div>
    );
};

export default Pagination;
