import { REPORT_GENERATION_STATUS } from 'common/enums';
import { ApiResponse, IErrors } from 'common/models';
import {
    GenericSuccessResponse,
    initialGenericSuccessResponse
} from 'modules/CommonModules/Users/models';

export type Reports = {
    competency: Competency;
    platform: Platform;
    endorsement: Endorsement;
    assessment: Assessment;
    learningTopic: LearningTopic;
    agentProgress: AgentProgress;
    userTopicProgress: UserTopicProgress;
    eventLogs: EventLogs;
    brokerages: Brokerages;
    primaryAssociation: ApiResponse<PrimaryAssociationOptions>;
    generationStatusInfo: ApiResponse<ReportGenerationStatusInfoResponse>;
    generateReport: ApiResponse<GenerateReportResponse>;
};

export const initialReportData = {
    headers: [],
    report: {
        currentPage: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        items: [],
        pageSize: 0,
        totalPages: 0,
        totalResults: 0
    }
};

export type ReportDataRequest = {
    isPagination?: boolean;
    pageNum?: number;
    pageSize?: number;
    searchQuery?: string;
    sortOrder?: string;
    sortColumn?: string;
    reportType?: string;
    primaryAssociationId?: string;
    isStaff?: boolean;
};

export type ReportDataGenericResponse = {
    currentPage: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    pageSize: number;
    totalPages: number;
    totalResults: number;
};

export type ColumnHeader = {
    headerName: string;
    field: string;
};

// Competency
export type Competency = {
    reportData: ApiResponse<CompetencyReportData>;
    download: ApiResponse<unknown>;
};

export type CompetencyItemData = {
    competency: string;
    totalCompleted: number;
    totalIncomplete: number;
};

export type CompetencyData = ReportDataGenericResponse & {
    items: CompetencyItemData[];
};

export type CompetencyReportData = {
    headers: ColumnHeader[];
    report: CompetencyData;
};

// Platform
export type Platform = {
    reportData: ApiResponse<PlatformReportData>;
    download: ApiResponse<unknown>;
};

export type PlatformItemData = {
    item: string;
    total: number;
};

export type PlatformData = ReportDataGenericResponse & {
    items: PlatformItemData[];
};

export type PlatformReportData = {
    headers: ColumnHeader[];
    report: PlatformData;
};

// Endorsement
export type Endorsement = {
    reportData: ApiResponse<EndorsementReportData>;
    download: ApiResponse<unknown>;
};

export type EndorsementItemData = {
    brokerage: string;
    email: string;
    endorsementDate: string;
    firstName: string;
    lastName: string;
    m1: string;
    renewalDate: string;
};

export type EndorsementData = ReportDataGenericResponse & {
    items: EndorsementItemData[];
};

export type EndorsementReportData = {
    headers: ColumnHeader[];
    report: EndorsementData;
};

// Assessment
export type Assessment = {
    reportData: ApiResponse<AssessmentReportData>;
    download: ApiResponse<DownloadReportResponse>;
};

export type AssessmentItemData = {
    firstName: string;
    lastName: string;
    passed: string;
    attempts: number;
    attemptDate: string;
    percentage: string;
};

export type AssessmentData = ReportDataGenericResponse & {
    items: AssessmentItemData[];
};

export type AssessmentReportData = {
    headers: ColumnHeader[];
    report: AssessmentData;
};

// Learning Topic
export type LearningTopic = {
    reportData: ApiResponse<LearningTopicReportData>;
    download: ApiResponse<unknown>;
};

export type LearningTopicItemData = {
    competency: string;
    learningTopic: string;
    totalCompleted: string;
    totalIncomplete: string;
};

export type LearningTopicData = ReportDataGenericResponse & {
    items: LearningTopicItemData[];
};

export type LearningTopicReportData = {
    headers: ColumnHeader[];
    report: LearningTopicData;
};

// agent progress
export type AgentProgress = {
    reportData: ApiResponse<AgentProgressReportData>;
    download: ApiResponse<DownloadReportResponse>;
};

export type AgentProgressItemData = {
    assessmentCompleted: string;
    completedLearningTopics: number;
    email: string;
    endorsementDate: string;
    endorsementProgress: number;
    firstName: string;
    lastName: string;
    logins: number;
    m1: number;
    originalEndorsementDate: string;
};

export type AgentProgressData = ReportDataGenericResponse & {
    items: AgentProgressItemData[];
};

export type AgentProgressReportData = {
    headers: ColumnHeader[];
    report: AgentProgressData;
};

// user topic progress
export type UserTopicProgress = {
    reportData: ApiResponse<UserTopicProgressReportData>;
    download: ApiResponse<DownloadReportResponse>;
};

export type UserTopicProgressItemData = {
    firstName: string;
    lastName: string;
    email: string;
    m1: string;
    learningTopic: string;
    status: string;
    required: string;
    timeSpent: number;
};

export type UserTopicProgressData = ReportDataGenericResponse & {
    items: UserTopicProgressItemData[];
};

export type UserTopicProgressReportData = {
    headers: ColumnHeader[];
    report: UserTopicProgressData;
};

// event logs
export type EventLogs = {
    reportData: ApiResponse<EventLogsReportData>;
    download: ApiResponse<DownloadReportResponse>;
};

export type EventLogsItemData = {
    id: string;
    scope: string;
    user: string;
    clientIP: string;
    message: string;
    created: string;
    platform: string;
};

export type EventLogsData = ReportDataGenericResponse & {
    items: UserTopicProgressItemData[];
};

export type EventLogsReportData = {
    headers: ColumnHeader[];
    report: EventLogsData;
};

//Brokerages
export type Brokerages = {
    reportData: ApiResponse<BrokeragesReportData>;
    download: ApiResponse<DownloadReportResponse>;
};

export type BrokeragesItemData = {
    city: string;
    createdDate: string;
    m1: string;
    name: string;
    state: string;
    userCount: string;
};

export type BrokeragesData = ReportDataGenericResponse & {
    items: BrokeragesItemData[];
};

export type BrokeragesReportData = {
    headers: ColumnHeader[];
    report: BrokeragesData;
};

//Primary associations
export type PrimaryAssociationItemsData = {
    name: string;
    guidCode: string;
};

export type PrimaryAssociationOptions = ReportDataGenericResponse & {
    items: PrimaryAssociationItemsData[];
};

// Download Report
export type DownloadReportRequest = {
    reportType: string;
    reduxStateKey: string;
    shouldDownloadUsingSasUrl: boolean;
    isStaff?: boolean;
};

export type DownloadReportResponse = {
    sasUri: string;
    fileName: string;
    data?: unknown;
    reduxStateKey: string;
};

export type DownloadReportError = {
    errors: IErrors[] | string;
    reduxStateKey: string;
};

export const initialDownloadReportResponse: DownloadReportResponse = {
    sasUri: '',
    fileName: '',
    data: '',
    reduxStateKey: ''
};

// Generation status
export type ReportGenerationStatusInfoRequest = {
    reportType: string;
};

export type ReportGenerationStatusInfoResponse = {
    generatedDate: string;
    status: REPORT_GENERATION_STATUS;
};

export const initialGenerationInfoStatus = {
    generatedDate: '',
    status: REPORT_GENERATION_STATUS.NotAvailable
};

// Generate report
export type GenerateReportRequest = {
    reportType: string;
    body: GenerateReportRequestBody;
};

export type GenerateReportRequestBody = {
    searchFilters?: string | null;
    primaryAssociationId?: string | null;
    fromDate?: string | null;
    toDate?: string | null;
    isStaff?: boolean ;
};

export type GenerateReportResponse = GenericSuccessResponse;

export const initialGenerateReportData = { ...initialGenericSuccessResponse };
