import { HTTP_METHOD } from 'common/enums';
import { ApiConfig } from 'common/models';
import { CONTROLLER, METHOD } from './endpoint.config';
import { RequestUtil } from 'common/utils';
import {
    DownloadReportRequest,
    GenerateReportRequest,
    ReportDataRequest,
    ReportGenerationStatusInfoRequest
} from 'modules/AdminDashboard/models';

export const reportActionsApiConfig = {
    fetchCompetencyReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.COMPETENCY}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchPlatformReportData: (): ApiConfig => {
        return new ApiConfig(`${CONTROLLER.REPORTS}/${METHOD.PLATFORM}`, HTTP_METHOD.GET);
    },

    fetchEndorsementReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.ENDORSEMENT}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchAssessmentReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.ASSESSMENT}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchLearningTopicReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.LEARNING_TOPIC}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchAgentProgressReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.AGENT_PROGRESS}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchUserTopicReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.USER_TOPIC_PROGRESS}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchEventLogsReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.EVENT_LOG}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchBrokeragesReportData: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.BROKERAGES}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    downloadReportUsingSasUrl: (request: DownloadReportRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.DOWNLOAD}/${request.reportType}`,
            HTTP_METHOD.GET
        );
    },

    downloadReportUsingCSVString: (request: DownloadReportRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.DOWNLOAD_REPORT}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchPrimaryAssociationOptions: (request: ReportDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.PRIMARY_ASSOCIATION}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchReportGenerationStatus: (request: ReportGenerationStatusInfoRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${request.reportType}/${METHOD.STATUS_SMALL}`,
            HTTP_METHOD.GET
        );
    },

    generateReport: (request: GenerateReportRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.GENERATE}/${request.reportType}`,
            HTTP_METHOD.POST,
            '',
            { ...request.body }
        );
    }
};
