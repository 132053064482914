import { MEDIA_COLLECTION } from 'common/configs';
import { UserAvatarDropdownDetails } from 'common/models';
import { strings } from 'common/constants/app-constants';
import { useDispatch } from 'react-redux';
import { userProfileActions } from 'common/redux/userProfile';
import { AppUtil } from 'common/utils';
import './AvatarDropdown.scss';

type AvatarDropdownProps = {
    userDetails: UserAvatarDropdownDetails;
};

const AvatarDropdown = ({ userDetails }: AvatarDropdownProps) => {
    const dispatch = useDispatch();

    const handleLogout = async () => {
        dispatch(
            userProfileActions.logoutBegin({
                deviceInfo: {
                    deviceName: AppUtil.getBrowserName(),
                    browser: AppUtil.getBrowserName(),
                    isMobile: false
                }
            })
        );
    };

    return (
        <div className="dropdown-options-container">
            <div
                className="dropdown-options-container__option-row"
                onClick={(e) => e.stopPropagation()}>
                <div className="dropdown-options-container__option-row--profile-name">
                    <p className="font-700">{`${userDetails.firstName} ${userDetails.lastName}`}</p>
                    <p className="font-thin role-label">{userDetails.role}</p>
                </div>
            </div>

            <div className="dropdown-options-container__option-row" onClick={handleLogout}>
                <img
                    className="dropdown-options-container__option-row--option-icon"
                    src={MEDIA_COLLECTION.IC_LOGOUT}
                    aria-hidden="true"
                />
                <span className="font-thin">{strings.HEADER.LOGOUT}</span>
            </div>
        </div>
    );
};

export default AvatarDropdown;
