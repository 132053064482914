import {
    initialExternalUsersListState,
    initialUsersState
} from 'modules/CommonModules/Users/redux';
import { initialUserProfileState } from '../userProfile';
import { RootState } from './root.reducer';
import { initialAdminActionsState } from 'modules/AdminDashboard/redux';
import { initialReportsState } from 'modules/CommonModules/redux';

const initialState: RootState = {
    userProfile: initialUserProfileState,
    users: initialUsersState,
    admin: initialAdminActionsState,
    reports: initialReportsState,
    externalUsers: initialExternalUsersListState
};

export default initialState;
