const CONTROLLER = {
    USER: 'User',
    BROKERAGES: 'Brokerages',
    REPORTS: 'Reports',
    ADMIN: 'Admin'
};

const METHOD = {
    STATUS: 'Status',
    STATUS_SMALL: 'status',
    USER: 'user',
    USERS: 'users',
    RESET_PROGRESS: 'ResetProgress',
    PROFILE: 'profile',
    LOGIN: 'login',
    LOGOUT: 'Logout',
    COMPETENCY: 'competency',
    PLATFORM: 'platform',
    ENDORSEMENT: 'endorsement',
    ASSESSMENT: 'assessment',
    LEARNING_TOPIC: 'learningtopic',
    AGENT_PROGRESS: 'agentprogress',
    USER_TOPIC_PROGRESS: 'usertopicprogress',
    EVENT_LOG: 'eventlog',
    BROKERAGES: 'brokerages',
    PRIMARY_ASSOCIATION: 'primaryassociation',
    FILE_UPLOAD: 'file-upload',
    FILE_DETAILS: 'file-details',
    EDIT: 'edit',
    ROLES: 'roles',
    DOWNLOAD_REPORT: 'downloadreport',
    RESETPROGRESS: 'reset-progress',
    FORCE_ENDORSEMENT: 'force-endorsement',
    FILE_SETTINGS: 'file-settings',
    FILE_DOWNLOAD: 'file-download',
    USER_INVITATIONS: 'userinvitations',
    USER_INVITATIONS_ADD: 'userinvitation-add',
    USER_INVITATIONS_UPDATE: 'userinvitation-update',
    USER_INVITATIONS_DELETE: 'userinvitation-delete',
    SYNC_USER_DATA: 'SyncUserData',
    GENERATE: 'generate',
    DOWNLOAD: 'download'
};

const QUERY_STRINGS = {
    PAGE_NUM: 'PageNumber',
    PAGE_SIZE: 'PageSize',
    FILTERS: 'Filters',
    SORT_ORDER: 'SortOrder',
    SORT_COLUMN: 'SortColumn',
    SEARCH_QUERY: 'SearchQuery',
    ENDORSED_DATE_FROM: 'EndorsedDateFrom',
    ENDORSED_DATE_TO: 'EndorsedDateTo',
    TYPE: 'Type',
    DOC_TYPE: 'type',
    ISPAGINATION: 'isPagination',
    REPORT_TYPE: 'ReportType',
    PRIMARY_ASSOCIATION_ID: 'PrimaryAssociationId',
    IS_STAFF: 'IsStaff',

};

export { CONTROLLER, METHOD, QUERY_STRINGS };
