import { NavOptionsBuilder } from 'common/components';
import { NavOptions } from 'common/models';
import './MenuDrawer.scss';

type MenuDrawerProps = {
    isOpen: boolean;
    closeMenuDrawer: () => void;
    navOptions: NavOptions | undefined;
};

const MenuDrawer = ({ isOpen, navOptions, closeMenuDrawer }: MenuDrawerProps) => {
    return (
        <div className={`menu-drawer ${isOpen ? 'open' : 'close'}`}>
            <NavOptionsBuilder navOptions={navOptions} onClick={closeMenuDrawer} />
        </div>
    );
};

export default MenuDrawer;
