import { put, takeLatest } from 'redux-saga/effects';
import { userProfileActions } from './user-profile.slice';
import {
    ApiResult,
    LogoutRequest,
    SubmitDeviceInfoRequest,
    SubmitDeviceInfoResponse,
    UserProfileDetails
} from 'common/models';
import { UserProfileService } from './user-profile.service';
import { PayloadAction } from '@reduxjs/toolkit';

const userProfileWatch = function* () {
    yield takeLatest(userProfileActions.fetchUserProfileBegin.type, FetchUserProfile);
    yield takeLatest(userProfileActions.submitDeviceInfoBegin.type, SubmitDeviceInfo);
    yield takeLatest(userProfileActions.logoutBegin.type, Logout);
};

const FetchUserProfile = function* () {
    const result: ApiResult<UserProfileDetails> = yield UserProfileService.fetchUserProfile();
    if (result.hasErrors) {
        yield put(userProfileActions.fetchUserProfileError());
    } else {
        yield put(userProfileActions.fetchUserProfileSuccess(result.value));
    }
};

const SubmitDeviceInfo = function* ({ payload }: PayloadAction<SubmitDeviceInfoRequest>) {
    const result: ApiResult<SubmitDeviceInfoResponse> = yield UserProfileService.submitDeviceInfo(
        payload
    );
    if (result.hasErrors) {
        yield put(userProfileActions.submitDeviceInfoError());
    } else {
        yield put(userProfileActions.submitDeviceInfoSuccess(result.value));
    }
};

const Logout = function* ({ payload }: PayloadAction<LogoutRequest>) {
    const result: ApiResult<SubmitDeviceInfoResponse> =
        yield UserProfileService.logout(payload);
    if (result.hasErrors) {
        yield put(userProfileActions.logoutError());
    } else {
        yield put(userProfileActions.logoutSuccess(result.value));
    }
};

export { userProfileWatch };
