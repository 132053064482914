import React from 'react';
import { Toast } from 'common/components';
import { ToastPosition, ToastVariant } from 'common/enums';
import { createRoot } from 'react-dom/client';

export class ToastMessageUtil {
    private static _modalRoot?: HTMLDivElement = undefined;

    public static show(
        message: string,
        onClose?: () => void,
        className = '',
        variant = ToastVariant.SUCCESS,
        position = ToastPosition.TOP,
        duration = 3000
    ) {
        if (!ToastMessageUtil._modalRoot) {
            ToastMessageUtil.getRootRef();
        }

        const element: HTMLDivElement = document.createElement('div');
        const root = createRoot(element!);

        const handleClose = () => {
            try {
                if (ToastMessageUtil?._modalRoot?.hasChildNodes()) {
                    onClose?.();
                    ToastMessageUtil?._modalRoot?.removeChild(element);
                }
            } catch (ex) {
                console.log(ex);
            }
            root.unmount();
            element.remove();
        };

        const component = React.createElement(
            Toast,
            {
                className: `toast-msg ${className} ${variant}`,
                show: true,
                variant: variant,
                position: position,
                duration: duration,
                onClose: handleClose,
                message: message
            },
            null
        );

        root.render(component);
        ToastMessageUtil?._modalRoot?.appendChild(element);
    }

    private static getRootRef() {
        ToastMessageUtil._modalRoot = document.getElementById('auth-layout') as HTMLDivElement;
    }
}
