import { ApiStatus } from 'common/enums';
import {
    Reports,
    initialReportData,
    initialGenerationInfoStatus,
    initialGenerateReportData,
    initialDownloadReportResponse
} from 'modules/AdminDashboard/models';

export const initialReportsState: Reports = {
    competency: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: '',
            status: ApiStatus.NONE
        }
    },
    platform: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: '',
            status: ApiStatus.NONE
        }
    },
    endorsement: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadReportResponse,
            status: ApiStatus.NONE
        }
    },
    assessment: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadReportResponse,
            status: ApiStatus.NONE
        }
    },
    learningTopic: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: '',
            status: ApiStatus.NONE
        }
    },
    agentProgress: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadReportResponse,
            status: ApiStatus.NONE
        }
    },
    userTopicProgress: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadReportResponse,
            status: ApiStatus.NONE
        }
    },
    eventLogs: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadReportResponse,
            status: ApiStatus.NONE
        }
    },
    brokerages: {
        reportData: {
            data: initialReportData,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadReportResponse,
            status: ApiStatus.NONE
        }
    },
    primaryAssociation: {
        data: initialReportData.report,
        status: ApiStatus.NONE
    },
    generationStatusInfo: {
        data: initialGenerationInfoStatus,
        status: ApiStatus.NONE
    },
    generateReport: {
        data: initialGenerateReportData,
        status: ApiStatus.NONE
    }
};
