import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/enums';
import { initialUsersState } from '../states/users.state';
import {
    DownloadUsersCSVError,
    DownloadUsersCSVRequest,
    DownloadUsersCSVResponse,
    EditUserRequest,
    EditUserResponse,
    ForceEndorsementRequest,
    ForceEndorsementResponse,
    initialEditUserResponse,
    initialForceEndorsementResponse,
    initialSyncUserDataResponse,
    initialUsersListResponse,
    initialViewProgressResponse,
    initialWipeProgressResponse,
    SyncUserDataErrorResponse,
    SyncUserDataRequest,
    SyncUserDataResponse,
    UserRolesRequest,
    UserRolesResponse,
    UsersListRequest,
    UsersListResponse,
    ViewProgressRequest,
    ViewProgressResponse,
    WipeProgressRequest,
    WipeProgressResponse
} from 'modules/CommonModules/Users/models';
import {
    GenerateReportRequest,
    GenerateReportResponse,
    initialDownloadReportResponse,
    ReportGenerationStatusInfoRequest,
    ReportGenerationStatusInfoResponse
} from 'modules/AdminDashboard/models';
import { initialReportsState } from 'modules/CommonModules/redux';

const usersSlice = createSlice({
    name: 'users',
    initialState: initialUsersState,
    reducers: {
        fetchUsersListBegin: (state, { payload }: PayloadAction<UsersListRequest>) => {
            state.list.status = ApiStatus.LOADING;
        },
        fetchUsersListSuccess: (state, { payload }: PayloadAction<UsersListResponse>) => {
            state.list.status = ApiStatus.SUCCESS;
            state.list.data = payload;
        },
        fetchUsersListError: (state) => {
            state.list.status = ApiStatus.ERROR;
        },
        resetUsersList: (state) => {
            state.list.status = ApiStatus.NONE;
            state.list.data = initialUsersListResponse;
        },

        fetchUserRoles: (state, { payload }: PayloadAction<UserRolesRequest>) => {
            state.roles.status = ApiStatus.LOADING;
        },
        fetchUserRolesSuccess: (state, { payload }: PayloadAction<UserRolesResponse>) => {
            state.roles.status = ApiStatus.SUCCESS;
            state.roles.data = payload;
        },
        fetchUserRolesError: (state) => {
            state.roles.status = ApiStatus.ERROR;
        },
        resetUserRoles: (state) => {
            state.roles.status = ApiStatus.NONE;
            state.roles.data = [];
        },

        editUserBegin: (state, { payload }: PayloadAction<EditUserRequest>) => {
            state.edit.status = ApiStatus.LOADING;
        },
        editUserSuccess: (state, { payload }: PayloadAction<EditUserResponse>) => {
            state.edit.status = ApiStatus.SUCCESS;
            state.edit.data = payload;
        },
        editUserError: (state) => {
            state.edit.status = ApiStatus.ERROR;
        },
        resetEditUser: (state) => {
            state.edit.status = ApiStatus.NONE;
            state.edit.data = initialEditUserResponse;
        },

        wipeProgressBegin: (state, { payload }: PayloadAction<WipeProgressRequest>) => {
            state.wipeProgress.status = ApiStatus.LOADING;
        },
        wipeProgressSuccess: (state, { payload }: PayloadAction<WipeProgressResponse>) => {
            state.wipeProgress.status = ApiStatus.SUCCESS;
            state.wipeProgress.data = payload;
        },
        wipeProgressError: (state) => {
            state.wipeProgress.status = ApiStatus.ERROR;
        },
        resetWipeProgress: (state) => {
            state.wipeProgress.status = ApiStatus.NONE;
            state.wipeProgress.data = initialWipeProgressResponse;
        },

        forceEndorsementBegin: (state, { payload }: PayloadAction<ForceEndorsementRequest>) => {
            state.forceEndorsement.status = ApiStatus.LOADING;
        },
        forceEndorsementSuccess: (state, { payload }: PayloadAction<ForceEndorsementResponse>) => {
            state.forceEndorsement.status = ApiStatus.SUCCESS;
            state.forceEndorsement.data = payload;
        },
        forceEndorsementError: (state) => {
            state.forceEndorsement.status = ApiStatus.ERROR;
        },
        resetForceEndorsement: (state) => {
            state.forceEndorsement.status = ApiStatus.NONE;
            state.forceEndorsement.data = initialForceEndorsementResponse;
        },

        viewProgressBegin: (state, { payload }: PayloadAction<ViewProgressRequest>) => {
            state.viewProgress.status = ApiStatus.LOADING;
        },
        viewProgressSuccess: (state, { payload }: PayloadAction<ViewProgressResponse>) => {
            state.viewProgress.status = ApiStatus.SUCCESS;
            state.viewProgress.data = payload;
        },
        viewProgressError: (state) => {
            state.viewProgress.status = ApiStatus.ERROR;
        },
        resetViewProgress: (state) => {
            state.viewProgress.status = ApiStatus.NONE;
            state.viewProgress.data = initialViewProgressResponse;
        },

        syncUserDataBegin: (state, { payload }: PayloadAction<SyncUserDataRequest>) => {
            state.syncUserData.status = ApiStatus.LOADING;
        },
        syncUserDataSuccess: (state, { payload }: PayloadAction<SyncUserDataResponse>) => {
            state.syncUserData.status = ApiStatus.SUCCESS;
            state.syncUserData.data = payload;
        },
        syncUserDataError: (state, { payload }: PayloadAction<SyncUserDataErrorResponse>) => {
            state.syncUserData.status = ApiStatus.ERROR;
            state.syncUserData.errors = payload.errors;
        },
        resetSyncUserData: (state) => {
            state.syncUserData.status = ApiStatus.NONE;
            state.syncUserData.data = initialSyncUserDataResponse;
        },

        downloadCSVBegin: (state, { payload }: PayloadAction<DownloadUsersCSVRequest>) => {
            state.download.status = ApiStatus.LOADING;
        },
        downloadCSVSuccess: (state, { payload }: PayloadAction<DownloadUsersCSVResponse>) => {
            state.download.status = ApiStatus.SUCCESS;
            state.download.data = payload;
        },
        downloadCSVError: (state, { payload }: PayloadAction<DownloadUsersCSVError>) => {
            state.download.status = ApiStatus.ERROR;
        },
        resetdownloadCSV: (state) => {
            state.download = {
                data: initialDownloadReportResponse,
                status: ApiStatus.NONE
            };
        },

        fetchReportGenerationStatusBegin: (
            state,
            { payload }: PayloadAction<ReportGenerationStatusInfoRequest>
        ) => {
            state.generationStatusInfo.status = ApiStatus.LOADING;
        },
        fetchReportGenerationStatusSuccess: (
            state,
            { payload }: PayloadAction<ReportGenerationStatusInfoResponse>
        ) => {
            state.generationStatusInfo.status = ApiStatus.SUCCESS;
            state.generationStatusInfo.data = payload;
        },
        fetchReportGenerationStatusError: (state) => {
            state.generationStatusInfo.status = ApiStatus.ERROR;
        },
        resetReportGenerationStatus: (state) => {
            state.generationStatusInfo = initialReportsState.generationStatusInfo;
        },

        generateReportBegin: (state, { payload }: PayloadAction<GenerateReportRequest>) => {
            state.generateReport.status = ApiStatus.LOADING;
        },
        generateReportSuccess: (state, { payload }: PayloadAction<GenerateReportResponse>) => {
            state.generateReport.status = ApiStatus.SUCCESS;
            state.generateReport.data = payload;
        },
        generateReportError: (state) => {
            state.generateReport.status = ApiStatus.ERROR;
        },
        resetGenerateReport: (state) => {
            state.generateReport = initialReportsState.generateReport;
        }
    }
});

export default usersSlice.reducer;
export const usersActions = usersSlice.actions;
