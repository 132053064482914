import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root.reducer';
import rootSaga from './root.saga';
import initialState from './root.state';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleWares) =>
        getDefaultMiddleWares({ thunk: false }).concat(sagaMiddleware),
    devTools: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
