import { usersApiConfig } from 'common/configs/api';
import { HTTP_HEADER } from 'common/enums';
import { ApiResult } from 'common/models';
import { HTTPClient } from 'common/services';
import { FileUtil } from 'common/utils';
import {
    GenerateReportRequest,
    ReportGenerationStatusInfoRequest,
    ReportGenerationStatusInfoResponse
} from 'modules/AdminDashboard/models';
import {
    EditUserRequest,
    EditUserResponse,
    ForceEndorsementRequest,
    ForceEndorsementResponse,
    UserRolesResponse,
    UsersListRequest,
    UsersListResponse,
    ViewProgressRequest,
    ViewProgressResponse,
    WipeProgressRequest,
    WipeProgressResponse,
    SyncUserDataRequest,
    SyncUserDataResponse,
    DownloadUsersCSVRequest,
    DownloadUsersCSVResponse,
    UserRolesRequest
} from 'modules/CommonModules/Users/models';

export class UsersService extends HTTPClient {
    static async fetchUsersList(request: UsersListRequest): Promise<ApiResult<UsersListResponse>> {
        return await this.invokeApi(usersApiConfig.fetchUsersList(request));
    }

    static async fetchUserRoles(request: UserRolesRequest): Promise<ApiResult<UserRolesResponse>> {
        return await this.invokeApi(usersApiConfig.fetchUserRoles(request));
    }

    static async editUser(request: EditUserRequest): Promise<ApiResult<EditUserResponse>> {
        return await this.invokeApi(usersApiConfig.editUser(request));
    }

    static async wipeProgress(
        request: WipeProgressRequest
    ): Promise<ApiResult<WipeProgressResponse>> {
        return await this.invokeApi(usersApiConfig.wipeProgress(request));
    }

    static async forceEndorsement(
        request: ForceEndorsementRequest
    ): Promise<ApiResult<ForceEndorsementResponse>> {
        return await this.invokeApi(usersApiConfig.forceEndorsement(request));
    }

    static async viewProgress(
        request: ViewProgressRequest
    ): Promise<ApiResult<ViewProgressResponse>> {
        return await this.invokeApi(usersApiConfig.viewProgress(request));
    }

    static async syncUserData(
        request: SyncUserDataRequest
    ): Promise<ApiResult<SyncUserDataResponse>> {
        return await this.invokeApi(usersApiConfig.syncUserData(request));
    }

    static async downloadCSV(
        request: DownloadUsersCSVRequest
    ): Promise<ApiResult<DownloadUsersCSVResponse>> {
        const data: ApiResult<DownloadUsersCSVResponse> = await this.invokeApi(
            usersApiConfig.downloadCSV(request)
        );
        if (data.value) {
            FileUtil.fileFromUrl(data.value.sasUri, data.value.fileName);
        }
        return data;
    }

    static async fetchReportGenerationStatus(
        request: ReportGenerationStatusInfoRequest
    ): Promise<ApiResult<ReportGenerationStatusInfoResponse>> {
        return await this.invokeApi(usersApiConfig.fetchReportGenerationStatus(request));
    }

    static async generateReport(
        request: GenerateReportRequest
    ): Promise<ApiResult<GenerateReportRequest>> {
        return await this.invokeApi(usersApiConfig.generateReport(request));
    }
}
