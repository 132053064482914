import { HTTP_METHOD } from 'common/enums';
import { ApiConfig, LogoutRequest, SubmitDeviceInfoRequest } from 'common/models';
import { CONTROLLER, METHOD } from './endpoint.config';

export const userProfileApiConfig = {
    fetchUserProfile: (): ApiConfig => {
        return new ApiConfig(`${CONTROLLER.USER}/${METHOD.PROFILE}`, HTTP_METHOD.GET);
    },

    submitDeviceInfo: (request: SubmitDeviceInfoRequest): ApiConfig => {
        return new ApiConfig(`${CONTROLLER.USER}/${METHOD.LOGIN}`, HTTP_METHOD.POST, '', request);
    },

    logout: (request: LogoutRequest): ApiConfig => {
        return new ApiConfig(`${METHOD.LOGOUT}`, HTTP_METHOD.POST, '', request);
    }
};
