import React, { FC } from 'react';
import './ModalHeader.scss';

type ModalHeader = {
    className?: string;
    children: React.ReactNode;
};

const ModalHeader: FC<ModalHeader> = ({ className, children }) => {
    return <div className={`modal-header ${className ?? ''}`}>{children}</div>;
};

export default ModalHeader;
