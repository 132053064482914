export const ENV_CONFIG = {
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || '',
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || '',
    AUTH0_CALLBACK_URL: process.env.REACT_APP_AUTH0_CALLBACK_URL || '',
    API_URI: process.env.REACT_APP_API_URI || '',

    //TODO: NEED TO UPDATE LATER
    API_SCOPE: process.env.REACT_APP_API_SCOPE || '#{REACT_APP_API_SCOPE}#',
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '#{REACT_APP_CLIENT_ID}#',
    AUTHORITY: process.env.REACT_APP_AUTHORITY || '#{REACT_APP_AUTHORITY}#'
};
