import { UserRoleMapper } from 'common/enums';
import { ROUTES } from './route.config';

export const USER_NAV_OPTIONS = {
    [UserRoleMapper.NAR_ADMIN]: [
        { name: 'Users', path: ROUTES.ADMIN.USERS },
        { name: 'Brokerages', path: ROUTES.ADMIN.BROKERAGES },
        { name: 'Reports', path: ROUTES.ADMIN.REPORTS },
        { name: 'Endorsement Materials', path: ROUTES.ADMIN.ENDORSEMENT_MATERIALS }
    ],

    [UserRoleMapper.BROKER_MANAGER]: [
        { name: 'Users', path: ROUTES.BROKER.USERS },
        { name: 'Reports', path: ROUTES.BROKER.REPORTS }
    ],

    [UserRoleMapper.ASSOCIATION_ADMIN]: [
        { name: 'Reports', path: ROUTES.ASSOCIATION_ADMIN.REPORTS }
    ]
};

export const TITLE_AS_PER_USER_ROLE = {
    [UserRoleMapper.NAR_ADMIN]: 'Admin Dashboard',
    [UserRoleMapper.BROKER_MANAGER]: 'Brokerage',
    [UserRoleMapper.ASSOCIATION_ADMIN]: 'Association Administrator'
};
