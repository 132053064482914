import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'common/configs';
import { Reports, Users } from 'modules/CommonModules';

const BrokerageAdminLayout = () => {
    return (
        <Routes>
            <Route path={ROUTES.BROKER.USERS} element={<Users />} />
            <Route path={ROUTES.BROKER.REPORTS} element={<Reports />} />

            <Route
                path={`${ROUTES.DEFAULT}${ROUTES.WILD}`}
                element={<Navigate replace to={ROUTES.BROKER.USERS} />}
            />
        </Routes>
    );
};

export default BrokerageAdminLayout;
