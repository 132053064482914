import React, { FC } from 'react';
import './ModalFooter.scss';

type ModalFooter = {
    className?: string;
    children: React.ReactNode;
};

const ModalFooter: FC<ModalFooter> = ({ className, children }) => {
    return <div className={`modal-footer ${className ?? ''}`}>{children}</div>;
};

export default ModalFooter;
