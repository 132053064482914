import { useEffect, useState } from 'react';
import {
    Button,
    CustomTable,
    Input,
    LegendDot,
    Pagination,
    Scenery,
    TableColumn
} from 'common/components';
import { DEFAULT_PAGE_SIZE, MEDIA_COLLECTION, PAGINATION_DEBOUNCE_TIME } from 'common/configs';
import { strings } from 'common/constants/app-constants';
import { AppUtil } from 'common/utils';
import { LEGEND_BASED_ON } from 'modules/CommonModules/Users/enums';
import EditBrokerageModal from './Components/EditBrokerageModal/EditBrokerageModal';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { adminActions } from 'modules/AdminDashboard/redux';
import { ApiStatus, ToastVariant } from 'common/enums';
import { ToastMessageUtil } from 'common/services';
import { BrokeragesDetails } from 'modules/AdminDashboard/models';
import { useDebounce } from 'common/hooks';
import './Brokerages.scss';

const Brokerages = () => {
    const dispatch = useAppDispatch();
    const { data: brokeragesListData, status: brokeragesListStatus } = useAppSelector(
        (state) => state.admin.brokeragesList
    );
    const { data: editBrokerageData, status: editBrokerageStatus } = useAppSelector(
        (state) => state.admin.editBrokerage
    );

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const debouncedPageNum = useDebounce(currentPageNum, PAGINATION_DEBOUNCE_TIME) as number;

    const [currentPage, setCurrentPage] = useState({
        debouncedPageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        sortValue: '',
        sortOrder: '',
        sortColumn: ''
    });
    const [selectedRowData, setSelectedRowData] = useState({});
    const [isSearchActive, setSearchActive] = useState<boolean>(false);

    useEffect(() => {
        if (debouncedPageNum != currentPage.debouncedPageNum) {
            setCurrentPage((prevState) => ({
                ...prevState,
                debouncedPageNum: debouncedPageNum
            }));
        }
    }, [debouncedPageNum]);

    useEffect(() => {
        fetchBrokerages();
    }, [currentPage]);

    useEffect(() => {
        if (editBrokerageStatus === ApiStatus.SUCCESS) {
            if (editBrokerageData.isSuccess) {
                ToastMessageUtil.show(strings.BROKERAGES.EDIT_MODAL.CHANGES_SAVED, () =>
                    dispatch(adminActions.resetEditBrokerage())
                );
            } else {
                ToastMessageUtil.show(
                    strings.SOMETHING_WENT_WRONG,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
            // refetch brokerage list
            fetchBrokerages();
        }
    }, [editBrokerageStatus]);

    const fetchBrokerages = () => {
        dispatch(
            adminActions.fetchBrokeragesListBegin({
                ...currentPage,
                pageNum: currentPage.debouncedPageNum,
                searchQuery: searchTerm,
                isPagination: true
            })
        );
    };

    const handleSearch = () => {
        setSearchActive(true);
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1
        }));
    };

    const handleEnterPressed = () => {
        handleSearch();
    };

    const handleClearSearch = () => {
        setSearchActive(false);
        setSearchTerm('');
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1
        }));
    };

    const handleSort = (value: string) => {
        const res = AppUtil.getSortOrderAndColumn(value);

        if (res) {
            setCurrentPage((prevState) => ({
                ...prevState,
                sortValue: value,
                sortOrder: res.sortOrder,
                sortColumn: res.columnName
            }));
        }
    };

    const handleEditAction = (data: BrokeragesDetails) => {
        setSelectedRowData(data);
    };

    const handlePageChange = (pageNum: number) => {
        setCurrentPageNum(pageNum);
    };

    const onPageSizeChange = (pageSize: number) => {
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1,
            pageSize: pageSize
        }));
    };

    return (
        <div className="brokerages-container">
            <div className="brokerages-container__header">
                <h2>{strings.BROKERAGES.TITLE}</h2>
            </div>
            <div className="brokerages-container__wrapper">
                <div className="search-container">
                    <Input
                        id="searchTerm"
                        className="search-input"
                        placeHolder={strings.USERS.SEARCH}
                        value={searchTerm}
                        startIcon={MEDIA_COLLECTION.IC_SEARCH}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onClear={() => setSearchTerm('')}
                        onEnterPressed={handleEnterPressed}
                        showClearBtn
                    />
                    <Button variant="primary" onClick={handleSearch}>
                        {strings.USERS.SEARCH}
                    </Button>
                </div>

                {isSearchActive && (
                    <div className="search-text__container">
                        {' '}
                        <p>{`${strings.SEARCH_RESULTS} (${
                            brokeragesListStatus === ApiStatus.LOADING
                                ? '-'
                                : brokeragesListData.totalResults
                        })`}</p>{' '}
                        <Button
                            variant="primary"
                            className="search-text__container--clear"
                            startIcon={MEDIA_COLLECTION.IC_CLOSE}
                            onClick={handleClearSearch}>
                            {strings.CLEAR_SEARCH}
                        </Button>
                    </div>
                )}

                {brokeragesListData.items.length == 0 &&
                brokeragesListStatus === ApiStatus.LOADING ? (
                    <div className="search-loader">
                        <p className="search-content">{strings.LOADING}</p>
                        <Scenery />
                    </div>
                ) : brokeragesListStatus === ApiStatus.SUCCESS &&
                  brokeragesListData.items.length == 0 ? (
                    <p className="users-container__body--no-data">{strings.USERS.NO_DATA_FOUND}</p>
                ) : (
                    <CustomTable
                        className="brokerage-table"
                        tableHeaderClass="table-head"
                        rowData={brokeragesListData.items}
                        enableSort={true}
                        handleSort={handleSort}
                        sortValue={currentPage.sortValue}
                        showLoader={brokeragesListStatus === ApiStatus.LOADING}>
                        <TableColumn headerName={strings.BROKERAGES.NAME} field="name" />
                        <TableColumn headerName={strings.BROKERAGES.CITY} field="city" />
                        <TableColumn headerName={strings.BROKERAGES.STATE} field="state" />
                        <TableColumn headerName={strings.BROKERAGES.M1} field="nrdsId" />
                        <TableColumn
                            headerName={strings.BROKERAGES.ENDORSEMENT}
                            field="isEndorsed"
                            renderer={(value: BrokeragesDetails) => {
                                return value.isEndorsed === null ? (
                                    value.isEndorsed
                                ) : (
                                    <LegendDot
                                        cellData={value.isEndorsed ? 'YES' : 'NO'}
                                        legendBasedOn={LEGEND_BASED_ON.YesOrNo}
                                    />
                                );
                            }}
                        />
                        <TableColumn
                            headerName={strings.BROKERAGES.USERS_COUNT}
                            field="userCount"
                        />
                        <TableColumn
                            headerClassName="edit-action"
                            className="edit-action"
                            headerName={''}
                            field=""
                            sortable={false}
                            cssStyles={{ flex: 0 }}
                            renderer={(value: BrokeragesDetails) => {
                                return (
                                    <div className="action-container">
                                        <img
                                            className="action-container__icon-edit"
                                            src={MEDIA_COLLECTION.IC_EDIT}
                                            aria-hidden="true"
                                            onClick={() => handleEditAction?.(value)}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </CustomTable>
                )}
            </div>

            {brokeragesListData.totalResults ? (
                <Pagination
                    totalRows={brokeragesListData.totalResults}
                    currentPageNum={currentPageNum}
                    onPageChange={handlePageChange}
                    pageSize={currentPage.pageSize}
                    onPageSizeChange={onPageSizeChange}
                />
            ) : null}

            {/* show modal to edit records */}
            {Object.keys(selectedRowData).length ? (
                <EditBrokerageModal
                    selectedRow={selectedRowData as BrokeragesDetails}
                    onCancel={() => setSelectedRowData({})}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default Brokerages;
