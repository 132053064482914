import './Loader.scss';

enum Position {
    Fixed = 'fixed',
    Absolute = 'absolute'
}

type LoaderProps = {
    show: boolean;
    className?: string;
    fullscreen?: boolean;
};

const Loader: React.FC<LoaderProps> = ({ show, className, fullscreen }: LoaderProps) => {
    const loaderStyle: React.CSSProperties = {
        position: fullscreen ? Position.Fixed : Position.Absolute,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        display: show ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 997
    };

    return (
        <div style={loaderStyle} className={className ?? ''}>
            <div className="loader"></div>
        </div>
    );
};

export default Loader;
