export type UserProfileDetails = {
    userGuid: string;
    firstName: string;
    lastName: string;
    emailId: string;
    role: UserRoles;
    endorsementGuid: string;
    totalPoints: number;
    totalProgress: number;
    dayInterval: number;
    minutesperDay: number;
    noOfEstimatedWeeks: number;
    totalCourseMinutes: number;
    isReadyForEndorsement: boolean;
    isStaff: boolean;
};

export const initialProfileDetails: UserProfileDetails = {
    userGuid: '',
    firstName: '',
    lastName: '',
    emailId: '',
    role: 'Standard User',
    endorsementGuid: '',
    totalPoints: 0,
    totalProgress: 0,
    dayInterval: 0,
    minutesperDay: 0,
    noOfEstimatedWeeks: 0,
    totalCourseMinutes: 0,
    isReadyForEndorsement: false,
    isStaff: false
};

export const initialSubmitDeviceInfo: SubmitDeviceInfoResponse = {
    isSuccess: false,
    hasNudge: false,
    title: '',
    responseDetails: {
        details: '',
        code: '',
        additionalParameters: null
    }
};

export type UserAvatarDropdownDetails = {
    firstName: string;
    lastName: string;
    role: string;
};

export type NavOptions = { name: string; path: string }[];

export type UserRoles =
    | 'NAR Administrator'
    | 'Broker Manager'
    | 'Association Admin'
    | 'Standard User';

export type SubmitDeviceInfoRequest = {
    deviceName: string;
};

export type SubmitDeviceInfoResponse = {
    isSuccess: boolean;
    hasNudge: boolean;
    title: string;
    responseDetails: {
        details: string;
        code: string;
        additionalParameters: null;
    };
};

export type LogoutRequest = {
    deviceInfo?: {
        deviceId?: string;
        deviceName?: string;
        deviceModel?: string;
        operatingSystem?: string;
        operatingSystemVersion?: string;
        browser?: string;
        browserVersion?: string;
        isMobile?: boolean;
    };
};

export type LogoutResponse = SubmitDeviceInfoResponse;

export const initialLogoutResponse: LogoutResponse = {
    isSuccess: false,
    hasNudge: false,
    title: '',
    responseDetails: {
        details: '',
        code: '',
        additionalParameters: null
    }
};
