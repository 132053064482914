import { adminActionsApiConfig } from 'common/configs/api';
import { ApiResult } from 'common/models';
import { HTTPClient } from 'common/services';
import { FileUtil } from 'common/utils';
import {
    BrokeragesListRequest,
    BrokeragesListResponse,
    CompetencyReportData,
    DownloadBlobRequest,
    EditBrokerageRequest,
    EditBrokerageResponse,
    FetchFileSasUriRequest,
    FetchFileSasUriResponse,
    PlatformReportData,
    UploadFileRequest,
    UploadFileResponse
} from 'modules/AdminDashboard/models';

export class AdminService extends HTTPClient {
    static async fetchBrokeragesList(
        request: BrokeragesListRequest
    ): Promise<ApiResult<BrokeragesListResponse>> {
        return await this.invokeApi(adminActionsApiConfig.fetchBrokeragesList(request));
    }

    static async fetchCompetencyReportData(): Promise<ApiResult<CompetencyReportData>> {
        return await this.invokeApi(adminActionsApiConfig.fetchCompetencyReportData());
    }

    static async fetchPlatformReportData(): Promise<ApiResult<PlatformReportData>> {
        return await this.invokeApi(adminActionsApiConfig.fetchPlatformReportData());
    }

    static async fetchFileSasUriAndDownload(
        request: FetchFileSasUriRequest
    ): Promise<ApiResult<FetchFileSasUriResponse>> {
        const data: ApiResult<FetchFileSasUriResponse> = await this.invokeApi(
            adminActionsApiConfig.fetchFileSasUriAndDownload(request)
        );
        // start downloading a file using sasUri
        FileUtil.fileFromUrl(data.value.sasUri, data.value.fileName);
        return data;
    }

    static async uploadFile(request: UploadFileRequest): Promise<ApiResult<UploadFileResponse>> {
        return await this.invokeFileUpload(
            adminActionsApiConfig.uploadFile(request),
            {},
            request.config
        );
    }

    static async downloadBlobData(request: DownloadBlobRequest) {
        const blobData = await this.invokeBlobDownload(
            adminActionsApiConfig.downloadBlobData(request)
        );
        //start downloading a file using blob data
        FileUtil.downloadFileFromBlob(blobData.value, request.type, '.jpg');
        return blobData;
    }

    static async fetchUploadedFilesData() {
        return await this.invokeApi(adminActionsApiConfig.fetchUploadedFilesData());
    }

    static async editBrokerage(
        request: EditBrokerageRequest
    ): Promise<ApiResult<EditBrokerageResponse>> {
        return await this.invokeApi(adminActionsApiConfig.editBrokerage(request));
    }
}
