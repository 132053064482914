export enum LEGEND_BASED_ON {
    Percentage = 'percentage',
    YesOrNo = 'yesOrNo',
    ActiveInactive = 'activeInactive'
}

export enum EDIT_USER_ACTIONS {
    ViewProgress = 'viewProgress',
    WipeProgress = 'wipeProgress',
    ForceEndorsement = 'forceEndorsement',
    SyncWithRAMCO = 'syncWithRAMCO'
}
