import React, { useCallback } from 'react';
import { useDropzone, FileRejection, Accept } from 'react-dropzone';
import { MEDIA_COLLECTION } from 'common/configs';
import { strings } from 'common/constants/app-constants';
import './FileUpload.scss';

type FileUploadProps = {
    className?: string;
    onFileUpload: (files: File[]) => void;
    accept?: Accept;
    multiple?: boolean;
    maxSize?: number;
};

const FileUpload: React.FC<FileUploadProps> = ({
    className,
    onFileUpload,
    accept,
    multiple,
    maxSize
}) => {
    const onDrop = useCallback(
        (acceptedFiles: File[], fileRejections: FileRejection[]) => {
            if (onFileUpload) {
                onFileUpload(acceptedFiles);
            }
        },
        [onFileUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept,
        multiple,
        maxSize
    });

    const uploadLabel = () => (
        <p className="file-upload__label-wrapper">
            <span>
                <img src={MEDIA_COLLECTION.IC_UPLOAD} />
            </span>
            <span className="file-upload__label-wrapper--label">
                {strings.FILE_UPLOAD.DRAG_AND_DROP}{' '}
                <a className="file-upload__anchor-btn">{strings.FILE_UPLOAD.BROWSE}</a>
            </span>
        </p>
    );

    return (
        <div {...getRootProps()} className={`file-upload ${className ?? ''}`}>
            <input {...getInputProps()} />
            {uploadLabel()}
        </div>
    );
};

export default FileUpload;
