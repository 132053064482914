const ExternalUserStatusActiveOption = { id: 'active', name: 'Active' };
const ExternalUserStatusInActiveOption = { id: 'inactive', name: 'Inactive' };

const ExternalUserStatusOptions = [
    { ...ExternalUserStatusActiveOption },
    { ...ExternalUserStatusInActiveOption }
];

export {
    ExternalUserStatusActiveOption,
    ExternalUserStatusInActiveOption,
    ExternalUserStatusOptions
};
