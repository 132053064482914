import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/enums';
import { initialAdminActionsState } from '../states/admin-actions.state';
import {
    BrokeragesListRequest,
    BrokeragesListResponse,
    UploadFileRequest,
    UploadFileResponse,
    FetchFileSasUriRequest,
    FetchFileSasUriResponse,
    ErrorResponse,
    DownloadBlobRequest,
    DownloadBlobResponse,
    ResetSiteSettingsRequest,
    UploadedFilesResponse,
    EditBrokerageRequest,
    EditBrokerageResponse,
    initialEditBrokerageResponse
} from 'modules/AdminDashboard/models';

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialAdminActionsState,
    reducers: {
        fetchBrokeragesListBegin: (state, { payload }: PayloadAction<BrokeragesListRequest>) => {
            state.brokeragesList.status = ApiStatus.LOADING;
        },
        fetchBrokeragesListSuccess: (state, { payload }: PayloadAction<BrokeragesListResponse>) => {
            state.brokeragesList.status = ApiStatus.SUCCESS;
            state.brokeragesList.data = payload;
        },
        fetchBrokeragesListError: (state) => {
            state.brokeragesList.status = ApiStatus.ERROR;
        },
        resetBrokeragesList: (state) => {
            state.brokeragesList = initialAdminActionsState.brokeragesList;
        },

        editBrokerageBegin: (state, { payload }: PayloadAction<EditBrokerageRequest>) => {
            state.editBrokerage.status = ApiStatus.LOADING;
        },
        editBrokerageSuccess: (state, { payload }: PayloadAction<EditBrokerageResponse>) => {
            state.editBrokerage.status = ApiStatus.SUCCESS;
            state.editBrokerage.data = payload;
        },
        editBrokerageError: (state) => {
            state.editBrokerage.status = ApiStatus.ERROR;
        },
        resetEditBrokerage: (state) => {
            state.editBrokerage.status = ApiStatus.NONE;
            state.editBrokerage.data = initialEditBrokerageResponse;
        },

        // Site-settings
        fetchFileSasUriAndDownloadBegin: (
            state,
            { payload }: PayloadAction<FetchFileSasUriRequest>
        ) => {
            state.endorsementMaterials[payload.type].fileDetails.status = ApiStatus.LOADING;
        },
        fetchFileSasUriAndDownloadSuccess: (
            state,
            { payload }: PayloadAction<FetchFileSasUriResponse>
        ) => {
            state.endorsementMaterials[payload.type].fileDetails.status = ApiStatus.SUCCESS;
            state.endorsementMaterials[payload.type].fileDetails.data = payload;
        },
        fetchFileSasUriAndDownloadError: (state, { payload }: PayloadAction<ErrorResponse>) => {
            state.endorsementMaterials[payload.type].fileDetails.status = ApiStatus.ERROR;
        },
        resetFetchFileSasUriAndDownload: (
            state,
            { payload }: PayloadAction<ResetSiteSettingsRequest>
        ) => {
            state.endorsementMaterials[payload.type].fileDetails =
                initialAdminActionsState.endorsementMaterials[payload.type].fileDetails;
        },

        uploadFileBegin: (state, { payload }: PayloadAction<UploadFileRequest>) => {
            state.endorsementMaterials[payload.type].upload.status = ApiStatus.LOADING;
        },
        uploadFileSuccess: (state, { payload }: PayloadAction<UploadFileResponse>) => {
            state.endorsementMaterials[payload.type].upload.status = ApiStatus.SUCCESS;
            state.endorsementMaterials[payload.type].upload.data = payload;
        },
        uploadFileError: (state, { payload }: PayloadAction<ErrorResponse>) => {
            state.endorsementMaterials[payload.type].upload.status = ApiStatus.ERROR;
        },
        resetUploadFile: (state, { payload }: PayloadAction<ResetSiteSettingsRequest>) => {
            state.endorsementMaterials[payload.type].upload =
                initialAdminActionsState.endorsementMaterials[payload.type].upload;
        },

        downloadBlobDataBegin: (state, { payload }: PayloadAction<DownloadBlobRequest>) => {
            state.endorsementMaterials[payload.type].download.status = ApiStatus.LOADING;
        },
        downloadBlobDataSuccess: (state, { payload }: PayloadAction<DownloadBlobResponse>) => {
            state.endorsementMaterials[payload.type].download.status = ApiStatus.SUCCESS;
            state.endorsementMaterials[payload.type].download.data = payload;
        },
        downloadBlobDataError: (state, { payload }: PayloadAction<ErrorResponse>) => {
            state.endorsementMaterials[payload.type].download.status = ApiStatus.ERROR;
        },
        resetBlobData: (state, { payload }: PayloadAction<ResetSiteSettingsRequest>) => {
            state.endorsementMaterials[payload.type].download =
                initialAdminActionsState.endorsementMaterials[payload.type].download;
        },

        fetchUploadedFilesDataBegin: (state) => {
            state.endorsementMaterials.uploadedFiles.status = ApiStatus.LOADING;
        },
        fetchUploadedFilesDataSuccess: (
            state,
            { payload }: PayloadAction<UploadedFilesResponse>
        ) => {
            state.endorsementMaterials.uploadedFiles.status = ApiStatus.SUCCESS;
            state.endorsementMaterials.uploadedFiles.data = payload;
        },
        fetchUploadedFilesDataError: (state) => {
            state.endorsementMaterials.uploadedFiles.status = ApiStatus.ERROR;
        },
        resetUploadedFilesData: (state) => {
            state.endorsementMaterials.uploadedFiles =
                initialAdminActionsState.endorsementMaterials.uploadedFiles;
        },

        resetSiteSettings: (state) => {
            state.endorsementMaterials = initialAdminActionsState.endorsementMaterials;
        }
    }
});

export default adminSlice.reducer;
export const adminActions = adminSlice.actions;
