import { ApiStatus, UPLOAD_SECTION } from 'common/enums';
import { ApiResponse, IErrors } from 'common/models';

export type EndorsementMaterials = {
    [UPLOAD_SECTION.EndorsementZip]: EndorsementMaterialsInfo;
    [UPLOAD_SECTION.EndorsementCertificate]: EndorsementMaterialsInfo;
    [UPLOAD_SECTION.AssessmentCertificate]: EndorsementMaterialsInfo;
    uploadedFiles: ApiResponse<UploadedFilesResponse>;
};

export type EndorsementMaterialsInfo = {
    upload: ApiResponse<UploadFileResponse>;
    fileDetails: ApiResponse<FetchFileSasUriResponse>;
    download: ApiResponse<DownloadBlobResponse>;
};

export type ErrorResponse = {
    errors: IErrors[] | string;
    type: string;
};

export type FetchFileSasUriRequest = {
    type: string;
};

export type FetchFileSasUriResponse = {
    fileName: string;
    sasUri: string;
    type: string;
};

export type UploadFileRequest = FetchFileSasUriRequest & {
    file: FormData | File;
    config?: any;
};

export type UploadFileResponse = {
    isSuccess: boolean;
    hasNudge: boolean;
    title: string;
    responseDetails: {
        details: string;
        code: string;
        additionalParameters: unknown;
    };
    type: string;
};

export type DownloadBlobRequest = {
    type: string;
};

export type DownloadBlobResponse = {
    blob: Blob | null;
    type: string;
};

export type ResetSiteSettingsRequest = {
    type: string;
};

export type UploadedFilesResponse = {
    id: string;
    type: string;
    filename: string;
    size: number;
}[];

export const initialUploadResponse = {
    isSuccess: false,
    hasNudge: false,
    title: '',
    responseDetails: {
        details: '',
        code: '',
        additionalParameters: null
    },
    type: ''
};

export const initialFileDetails = {
    fileName: '',
    sasUri: '',
    type: ''
};

export const initialDownloadDetails = {
    blob: null,
    type: ''
};

export const initialUploadedFilesData = {
    id: '',
    type: '',
    filename: '',
    size: 0
};

export const initialEndorsementMaterialsState: EndorsementMaterials = {
    [UPLOAD_SECTION.EndorsementZip]: {
        upload: {
            data: initialUploadResponse,
            status: ApiStatus.NONE
        },
        fileDetails: {
            data: initialFileDetails,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadDetails,
            status: ApiStatus.NONE
        }
    },
    [UPLOAD_SECTION.EndorsementCertificate]: {
        upload: {
            data: initialUploadResponse,
            status: ApiStatus.NONE
        },
        fileDetails: {
            data: initialFileDetails,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadDetails,
            status: ApiStatus.NONE
        }
    },
    [UPLOAD_SECTION.AssessmentCertificate]: {
        upload: {
            data: initialUploadResponse,
            status: ApiStatus.NONE
        },
        fileDetails: {
            data: initialFileDetails,
            status: ApiStatus.NONE
        },
        download: {
            data: initialDownloadDetails,
            status: ApiStatus.NONE
        }
    },
    uploadedFiles: {
        data: [],
        status: ApiStatus.NONE
    }
};
