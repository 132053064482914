import { all } from 'redux-saga/effects';
import { userProfileWatch } from '../userProfile';
import { usersWatch, externalUsersWatch } from 'modules/CommonModules/Users/redux';
import { adminActionsWatch } from 'modules/AdminDashboard/redux';
import { reportActionsWatch } from 'modules/CommonModules/redux';

export default function* rootSaga() {
    yield all([
        userProfileWatch(),
        usersWatch(),
        adminActionsWatch(),
        reportActionsWatch(),
        externalUsersWatch()
    ]);
}
