import { jwtDecode, JwtPayload } from 'jwt-decode';

const get = (key: string) => sessionStorage.getItem(key);

const set = (key: string, value: string) => sessionStorage.setItem(key, value);

const remove = (key: string) => sessionStorage.removeItem(key);

const removeAll = () => sessionStorage.clear();

const checkTokenValidity = (accessToken: string) => {
    if (accessToken) {
        try {
            const decodedToken = jwtDecode<JwtPayload>(accessToken);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds since the epoch.

            if (decodedToken.exp && decodedToken.exp > currentTime) {
                return true; // The token is valid.
            } else {
                return false; // The token is expired.
            }
        } catch (error) {
            console.error('Token verification failed:', error);
            return false;
        }
    }
    return false;
};
export default { get, set, remove, removeAll, checkTokenValidity };
