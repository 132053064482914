import { HTTP_METHOD } from 'common/enums';
import { ApiConfig } from 'common/models';
import { CONTROLLER, METHOD } from './endpoint.config';
import { RequestUtil } from 'common/utils';
import {
    AddExternalUserRequest,
    DeleteExternalUserRequest,
    ExternalUsersListRequest,
    UpdateExternalUserRequest
} from 'modules/CommonModules/Users/models';

export const externalUsersListApiConfig = {
    fetchExternalUsersList: (request: ExternalUsersListRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.USER_INVITATIONS}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    addExternalUser: (request: AddExternalUserRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.USER_INVITATIONS_ADD}`,
            HTTP_METHOD.POST,
            '',
            request
        );
    },

    updateExternalUser: (request: UpdateExternalUserRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.USER_INVITATIONS_UPDATE}`,
            HTTP_METHOD.PUT,
            '',
            request
        );
    },

    deleteExternalUser: (request: DeleteExternalUserRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.USER_INVITATIONS_DELETE}`,
            HTTP_METHOD.DELETE,
            '',
            request
        );
    }
};
