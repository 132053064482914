import { MEDIA_COLLECTION } from 'common/configs';
import './EndorsementMaterialsScenery.scss';

type SiteSettingsSceneryProps = {
    className?: string;
};

const SiteSettingsScenery: React.FC<SiteSettingsSceneryProps> = ({
    className
}: SiteSettingsSceneryProps) => {
    return (
        <div className={`site-settings-scenery-container ${className}`}>
            <img className="site-scenery" src={MEDIA_COLLECTION.IC_SITE_SETTINGS_SCENERY} />
        </div>
    );
};

export default SiteSettingsScenery;
