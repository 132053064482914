import { Suspense, useState } from 'react';
import { Route, Routes } from 'react-router';
import { ErrorBoundary, Loader } from 'common/components';
import { ROUTES } from 'common/configs';
import { Layout } from 'layouts';
import 'assets/scss/app.scss';

const App = () => {
    const [showFallback, setShowFallback] = useState(false);

    const handleError = () => {
        setShowFallback(true);
    };

    return (
        <ErrorBoundary showFallback={showFallback} onError={handleError}>
            <Suspense fallback={<Loader show fullscreen />}>
                <Routes>
                    <Route path={`${ROUTES.DEFAULT}${ROUTES.WILD}`} element={<Layout />} />
                </Routes>
            </Suspense>
        </ErrorBoundary>
    );
};

export default App;
