import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ToastMessageUtil } from '../toastMessage.service';
import { strings } from 'common/constants/app-constants';
import { ToastVariant } from 'common/enums';

const NETWORK_ERROR = 'Network Error';
Axios.interceptors.response.use(
    (response: AxiosResponse) => {
        /**
         * Response interceptor code goes here
         *  */
        return response;
    },
    (err) => {
        if (
            err.message == NETWORK_ERROR ||
            err.response.status === 500 ||
            err.response.status === 404 ||
            err.response.status === 403 ||
            err.response.status === 401
        )
            ToastMessageUtil.show(strings.SOMETHING_WENT_WRONG, undefined, '', ToastVariant.ERROR);

        return Promise.reject(err);
    }
);

Axios.interceptors.request.use((request: AxiosRequestConfig) => {
    // const xsrfToken = CookieUtil.get('XSRF-REQUEST-TOKEN');
    // if (xsrfToken) {
    //     request.headers['X-XSRF-TOKEN'] = xsrfToken;
    // }
    // request.headers['X-Requested-With'] = 'XMLHttpRequest';
    return request;
});

export default Axios;
