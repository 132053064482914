import { strings } from 'common/constants/app-constants';
import { ReactComponent as FacebookIcon } from 'assets/media/icons/facebook.svg';
import { ReactComponent as InstaIcon } from 'assets/media/icons/instagram.svg';
import { ReactComponent as LinkedInIcon } from 'assets/media/icons/linkedIn.svg';
import { ReactComponent as TwitterIcon } from 'assets/media/icons/twitter.svg';
import { ReactComponent as YouTubeIcon } from 'assets/media/icons/youtube.svg';
import { ReactComponent as EmailIcon } from 'assets/media/icons/email.svg';
import { ReactComponent as PhoneIcon } from 'assets/media/icons/phone.svg';
import { ReactComponent as ChatIcon } from 'assets/media/icons/chat.svg';

export const FollowUsInfo = {
    facebook: { iconComponent: FacebookIcon, url: 'http://www.facebook.com/NARdotRealtor/' },
    instagram: { iconComponent: InstaIcon, url: 'https://instagram.com/NARdotRealtor' },
    linkedIn: {
        iconComponent: LinkedInIcon,
        url: 'http://www.linkedin.com/company/national-association-of-realtors'
    },
    twitter: {
        iconComponent: TwitterIcon,
        url: 'http://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=nardotrealtor'
    },
    youTube: {
        iconComponent: YouTubeIcon,
        url: 'https://www.youtube.com/user/NAREALTORS'
    }
};

export const ContactUsInfo = {
    contactUs: {
        iconComponent: EmailIcon,
        desc: strings.FOOTER.CONTACT_US,
        url: 'https://www.nar.realtor/contact-us'
    },
    phone: {
        iconComponent: PhoneIcon,
        desc: strings.FOOTER.PHONE_NUM,
        url: ''
    },
    liveChat: {
        iconComponent: ChatIcon,
        desc: strings.FOOTER.LIVE_CHAT,
        url: 'https://www.nar.realtor/help'
    }
};

export const NAR_HOME_PAGE = 'https://www.nar.realtor/';

export const UNAUTHORIZED_REDIRECT_URL =
    'https://www.nar.realtor/realtors-commitment-to-excellence#:~:text=Commitment%20to%20Excellence%20(C2EX)%20from%20the%20National%20Association,can%20promote%20when%20serving%20clients%20and%20other%20REALTORS%C2%AE.';

export const POLLING_CONFIG = {
    FETCH_REPORT_GEN_STATUS: 5000
};
