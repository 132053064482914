import { ApiStatus } from 'common/enums';
import { ApiResponse } from 'common/models';
import {
    GenerateReportResponse,
    ReportGenerationStatusInfoResponse,
    initialDownloadReportResponse,
    initialGenerateReportData,
    initialGenerationInfoStatus
} from 'modules/AdminDashboard/models';
import {
    DownloadUsersCSVResponse,
    EditUserResponse,
    ForceEndorsementResponse,
    SyncUserDataResponse,
    UserRolesResponse,
    UsersListResponse,
    ViewProgressResponse,
    WipeProgressResponse,
    initialEditUserResponse,
    initialForceEndorsementResponse,
    initialSyncUserDataResponse,
    initialUsersListResponse,
    initialViewProgressResponse,
    initialWipeProgressResponse
} from 'modules/CommonModules/Users/models';

export type UsersListState = {
    list: ApiResponse<UsersListResponse>;
    edit: ApiResponse<EditUserResponse>;
    roles: ApiResponse<UserRolesResponse>;
    wipeProgress: ApiResponse<WipeProgressResponse>;
    forceEndorsement: ApiResponse<ForceEndorsementResponse>;
    viewProgress: ApiResponse<ViewProgressResponse>;
    syncUserData: ApiResponse<SyncUserDataResponse>;
    download: ApiResponse<DownloadUsersCSVResponse>;
    generationStatusInfo: ApiResponse<ReportGenerationStatusInfoResponse>;
    generateReport: ApiResponse<GenerateReportResponse>;
};

export const initialUsersState: UsersListState = {
    list: {
        data: initialUsersListResponse,
        status: ApiStatus.NONE
    },
    edit: {
        data: initialEditUserResponse,
        status: ApiStatus.NONE
    },
    roles: {
        data: [],
        status: ApiStatus.NONE
    },
    wipeProgress: {
        data: initialWipeProgressResponse,
        status: ApiStatus.NONE
    },
    forceEndorsement: {
        data: initialForceEndorsementResponse,
        status: ApiStatus.NONE
    },
    viewProgress: {
        data: initialViewProgressResponse,
        status: ApiStatus.NONE
    },
    syncUserData: {
        data: initialSyncUserDataResponse,
        status: ApiStatus.NONE
    },
    download: {
        data: initialDownloadReportResponse,
        status: ApiStatus.NONE
    },
    generationStatusInfo: {
        data: initialGenerationInfoStatus,
        status: ApiStatus.NONE
    },
    generateReport: {
        data: initialGenerateReportData,
        status: ApiStatus.NONE
    }
};
