import { PayloadAction } from '@reduxjs/toolkit';
import { ApiResult, IErrors } from 'common/models';
import { put, takeLatest } from 'redux-saga/effects';
import { UsersService } from '../services/users.service';
import { usersActions } from '../slices/users.slice';
import {
    EditUserRequest,
    EditUserResponse,
    ForceEndorsementRequest,
    ForceEndorsementResponse,
    UserRolesResponse,
    UsersListRequest,
    UsersListResponse,
    ViewProgressRequest,
    ViewProgressResponse,
    WipeProgressRequest,
    WipeProgressResponse,
    SyncUserDataRequest,
    SyncUserDataResponse,
    DownloadUsersCSVRequest,
    DownloadUsersCSVResponse,
    UserRolesRequest
} from 'modules/CommonModules/Users/models';
import {
    GenerateReportRequest,
    GenerateReportResponse,
    ReportGenerationStatusInfoRequest,
    ReportGenerationStatusInfoResponse
} from 'modules/AdminDashboard/models';

const usersWatch = function* () {
    yield takeLatest(usersActions.fetchUsersListBegin.type, FetchUsersList);
    yield takeLatest(usersActions.editUserBegin.type, EditUser);
    yield takeLatest(usersActions.fetchUserRoles.type, FetchUserRoles);
    yield takeLatest(usersActions.wipeProgressBegin.type, WipeProgress);
    yield takeLatest(usersActions.forceEndorsementBegin.type, ForceEndorsement);
    yield takeLatest(usersActions.viewProgressBegin.type, ViewProgress);
    yield takeLatest(usersActions.syncUserDataBegin.type, SyncUserData);
    yield takeLatest(usersActions.downloadCSVBegin.type, DownloadCSV);
    yield takeLatest(
        usersActions.fetchReportGenerationStatusBegin.type,
        FetchReportGenerationStatus
    );
    yield takeLatest(usersActions.generateReportBegin.type, GenerateReport);
};

const FetchUsersList = function* ({ payload }: PayloadAction<UsersListRequest>) {
    const result: ApiResult<UsersListResponse> = yield UsersService.fetchUsersList(payload);
    if (result.hasErrors) {
        yield put(usersActions.fetchUsersListError());
    } else {
        yield put(usersActions.fetchUsersListSuccess(result.value));
    }
};

const FetchUserRoles = function* ({ payload }: PayloadAction<UserRolesRequest>) {
    const result: ApiResult<UserRolesResponse> = yield UsersService.fetchUserRoles(payload);
    if (result.hasErrors) {
        yield put(usersActions.fetchUserRolesError());
    } else {
        yield put(usersActions.fetchUserRolesSuccess(result.value));
    }
};

const EditUser = function* ({ payload }: PayloadAction<EditUserRequest>) {
    const result: ApiResult<EditUserResponse> = yield UsersService.editUser(payload);
    if (result.hasErrors) {
        yield put(usersActions.editUserError());
    } else {
        yield put(usersActions.editUserSuccess(result.value));
    }
};

const WipeProgress = function* ({ payload }: PayloadAction<WipeProgressRequest>) {
    const result: ApiResult<WipeProgressResponse> = yield UsersService.wipeProgress(payload);
    if (result.hasErrors) {
        yield put(usersActions.wipeProgressError());
    } else {
        yield put(usersActions.wipeProgressSuccess(result.value));
    }
};

const ForceEndorsement = function* ({ payload }: PayloadAction<ForceEndorsementRequest>) {
    const result: ApiResult<ForceEndorsementResponse> = yield UsersService.forceEndorsement(
        payload
    );
    if (result.hasErrors) {
        yield put(usersActions.forceEndorsementError());
    } else {
        yield put(usersActions.forceEndorsementSuccess(result.value));
    }
};

const ViewProgress = function* ({ payload }: PayloadAction<ViewProgressRequest>) {
    const result: ApiResult<ViewProgressResponse> = yield UsersService.viewProgress(payload);
    if (result.hasErrors) {
        yield put(usersActions.viewProgressError());
    } else {
        yield put(usersActions.viewProgressSuccess(result.value));
    }
};

const SyncUserData = function* ({ payload }: PayloadAction<SyncUserDataRequest>) {
    const result: ApiResult<SyncUserDataResponse> = yield UsersService.syncUserData(payload);
    if (result.hasErrors) {
        const { errors } = result;
        const status = errors[0]?.response?.status;
        let errorResponse: IErrors[] | string = [];

        if (status && status == 400)
            errorResponse = errors[0].response?.data?.causes[0].details ?? [];

        yield put(
            usersActions.syncUserDataError({
                errors: errorResponse
            })
        );
    } else {
        yield put(usersActions.syncUserDataSuccess(result.value));
    }
};

const DownloadCSV = function* ({ payload }: PayloadAction<DownloadUsersCSVRequest>) {
    const result: ApiResult<DownloadUsersCSVResponse> = yield UsersService.downloadCSV(payload);
    if (result.hasErrors) {
        const { errors } = result;
        const status = errors[0]?.response?.status;
        let errorResponse: IErrors[] | string = [];

        if (status && status == 400)
            errorResponse = errors[0].response?.data?.causes[0].details ?? [];

        yield put(
            usersActions.downloadCSVError({
                errors: errorResponse
            })
        );
    } else {
        yield put(usersActions.downloadCSVSuccess(result.value));
    }
};

const FetchReportGenerationStatus = function* ({
    payload
}: PayloadAction<ReportGenerationStatusInfoRequest>) {
    const result: ApiResult<ReportGenerationStatusInfoResponse> =
        yield UsersService.fetchReportGenerationStatus(payload);
    if (result.hasErrors) {
        yield put(usersActions.fetchReportGenerationStatusError());
    } else {
        yield put(usersActions.fetchReportGenerationStatusSuccess(result.value));
    }
};

const GenerateReport = function* ({ payload }: PayloadAction<GenerateReportRequest>) {
    const result: ApiResult<GenerateReportResponse> = yield UsersService.generateReport(payload);
    if (result.hasErrors) {
        yield put(usersActions.generateReportError());
    } else {
        yield put(usersActions.generateReportSuccess(result.value));
    }
};

export { usersWatch };
