import { reportActionsApiConfig } from 'common/configs/api';
import { ApiResult } from 'common/models';
import { HTTPClient } from 'common/services';
import { FileUtil } from 'common/utils';
import {
    AgentProgressReportData,
    AssessmentReportData,
    CompetencyReportData,
    EndorsementReportData,
    LearningTopicReportData,
    PlatformReportData,
    PrimaryAssociationOptions,
    ReportDataRequest,
    UserTopicProgressReportData,
    EventLogsReportData,
    BrokeragesReportData,
    DownloadReportRequest,
    ReportGenerationStatusInfoRequest,
    ReportGenerationStatusInfoResponse,
    GenerateReportRequest,
    DownloadReportResponse
} from 'modules/AdminDashboard/models';

export class ReportService extends HTTPClient {
    static async fetchCompetencyReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<CompetencyReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchCompetencyReportData(request));
    }

    static async fetchPlatformReportData(): Promise<ApiResult<PlatformReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchPlatformReportData());
    }

    static async fetchEndorsementReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<EndorsementReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchEndorsementReportData(request));
    }

    static async fetchAssessmentReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<AssessmentReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchAssessmentReportData(request));
    }

    static async fetchLearningTopicReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<LearningTopicReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchLearningTopicReportData(request));
    }

    static async fetchAgentProgressReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<AgentProgressReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchAgentProgressReportData(request));
    }

    static async fetchUserTopicReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<UserTopicProgressReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchUserTopicReportData(request));
    }

    static async fetchEventLogsReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<EventLogsReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchEventLogsReportData(request));
    }

    static async fetchBrokeragesReportData(
        request: ReportDataRequest
    ): Promise<ApiResult<BrokeragesReportData>> {
        return await this.invokeApi(reportActionsApiConfig.fetchBrokeragesReportData(request));
    }

    static async downloadReport(
        request: DownloadReportRequest
    ): Promise<ApiResult<DownloadReportResponse>> {
        if (request.shouldDownloadUsingSasUrl) {
            const data: ApiResult<DownloadReportResponse> = await this.invokeApi(
                reportActionsApiConfig.downloadReportUsingSasUrl(request)
            );
            if (data.value) {
                FileUtil.fileFromUrl(data.value.sasUri, data.value.fileName);
            }
            return data;
        } else {
            const data: ApiResult<DownloadReportResponse> = await this.invokeApi(
                reportActionsApiConfig.downloadReportUsingCSVString(request)
            );
            if (data.value) {
                FileUtil.stringToFile(data.value, request.reportType ?? '', 'text/csv');
            }
            return data;
        }
    }

    static async fetchPrimaryAssociationOptions(
        request: ReportDataRequest
    ): Promise<ApiResult<PrimaryAssociationOptions>> {
        return await this.invokeApi(reportActionsApiConfig.fetchPrimaryAssociationOptions(request));
    }

    static async fetchReportGenerationStatus(
        request: ReportGenerationStatusInfoRequest
    ): Promise<ApiResult<ReportGenerationStatusInfoResponse>> {
        return await this.invokeApi(reportActionsApiConfig.fetchReportGenerationStatus(request));
    }

    static async generateReport(
        request: GenerateReportRequest
    ): Promise<ApiResult<GenerateReportRequest>> {
        return await this.invokeApi(reportActionsApiConfig.generateReport(request));
    }
}
