import React from 'react';
import RadioButton from './RadioButton/RadioButton';
import { RadioOption } from 'common/models';

type RadioButtonGroupProps = {
    className?: string;
    options: RadioOption[];
    value: RadioOption;
    onChange: (option: RadioOption) => void;
};

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
    className,
    options,
    value,
    onChange
}: RadioButtonGroupProps) => {
    return (
        <div className={`${className ?? ''}`}>
            {options.map((option) => (
                <RadioButton
                    key={option.name}
                    id={option.id}
                    name={option.name}
                    checked={value.id === option.id}
                    onChange={() => onChange(option)}
                />
            ))}
        </div>
    );
};

export default RadioButtonGroup;
