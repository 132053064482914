import { FC, ReactNode, useState } from 'react';
import { MEDIA_COLLECTION } from 'common/configs';
import './Accordion.scss';

type AccordionProps = {
    title: ReactNode;
    content: ReactNode;
    isActive?: boolean;
    className?: string;
    titleClassName?: string;
    contentClassName?: string;
    isGrouped?: boolean;
    triggerIcon?: string;
    onClick?: () => void;
};

const Accordion: FC<AccordionProps> = ({
    title,
    content,
    isActive,
    className,
    titleClassName,
    contentClassName,
    triggerIcon,
    onClick
}: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(isActive || false);

    return (
        <div
            className={`accordion-item ${className ? className : ''} ${
                isOpen ? 'accordion-item__open' : ''
            }`}>
            <div
                className={`accordion-item__title ${titleClassName ? titleClassName : ''}`}
                onClick={() => setIsOpen(!isOpen)}>
                {title}
                <img
                    className={'accordion-item__arrow'}
                    src={triggerIcon ? triggerIcon : MEDIA_COLLECTION.IC_UP_ARROW_BLACK}
                    alt="up-arrow"
                />
            </div>

            {isOpen && (
                <div className={`accordion-item__content ${contentClassName}`}>{content}</div>
            )}
        </div>
    );
};

export default Accordion;
