import { QUERY_STRINGS } from 'common/configs/api/endpoint.config';
import { ReportDataRequest } from 'modules/AdminDashboard/models';
import { UsersListRequest } from 'modules/CommonModules/Users/models';

const generateQueryString = (request: ReportDataRequest & UsersListRequest) => {
    const queryString = [
        request.reportType ? `${QUERY_STRINGS.REPORT_TYPE}=${request.reportType}` : '',
        request.isPagination ? `${QUERY_STRINGS.ISPAGINATION}=${request.isPagination}` : '',
        request.pageNum ? `${QUERY_STRINGS.PAGE_NUM}=${request.pageNum}` : '',
        request.pageSize ? `${QUERY_STRINGS.PAGE_SIZE}=${request.pageSize}` : '',
        request.searchQuery ? `${QUERY_STRINGS.SEARCH_QUERY}=${request.searchQuery}` : '',
        request.sortOrder ? `${QUERY_STRINGS.SORT_ORDER}=${request.sortOrder}` : '',
        request.sortColumn ? `${QUERY_STRINGS.SORT_COLUMN}=${request.sortColumn}` : '',
        request.primaryAssociationId
            ? `${QUERY_STRINGS.PRIMARY_ASSOCIATION_ID}=${request.primaryAssociationId}`
            : '',
        request.filters ? `${QUERY_STRINGS.FILTERS}=${request.filters}` : '',
        request.endorsedDateFrom
            ? `${QUERY_STRINGS.ENDORSED_DATE_FROM}=${request.endorsedDateFrom}`
            : '',
        request.isStaff ? `${QUERY_STRINGS.IS_STAFF}=${request.isStaff}` : '',

        request.endorsedDateTo ? `${QUERY_STRINGS.ENDORSED_DATE_TO}=${request.endorsedDateTo}` : ''
    ]
        .filter((param) => param !== '')
        .join('&');

    return queryString ? `?${queryString}` : '';
};

export default {
    generateQueryString
};
