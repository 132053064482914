import { externalUsersListApiConfig } from 'common/configs/api';
import { ApiResult } from 'common/models';
import { HTTPClient } from 'common/services';
import {
    AddExternalUserRequest,
    AddExternalUserResponse,
    DeleteExternalUserRequest,
    DeleteExternalUserResponse,
    ExternalUsersListRequest,
    ExternalUsersListResponse,
    UpdateExternalUserRequest,
    UpdateExternalUserResponse
} from '../../models';

export class ExternalUsersService extends HTTPClient {
    static async fetchExternalUsersList(
        request: ExternalUsersListRequest
    ): Promise<ApiResult<ExternalUsersListResponse>> {
        return await this.invokeApi(externalUsersListApiConfig.fetchExternalUsersList(request));
    }

    static async addExternalUser(
        request: AddExternalUserRequest
    ): Promise<ApiResult<AddExternalUserResponse>> {
        return await this.invokeApi(externalUsersListApiConfig.addExternalUser(request));
    }

    static async updateExternalUser(
        request: UpdateExternalUserRequest
    ): Promise<ApiResult<UpdateExternalUserResponse>> {
        return await this.invokeApi(externalUsersListApiConfig.updateExternalUser(request));
    }

    static async deleteExternalUser(
        request: DeleteExternalUserRequest
    ): Promise<ApiResult<DeleteExternalUserResponse>> {
        return await this.invokeApi(externalUsersListApiConfig.deleteExternalUser(request));
    }
}
