import { ApiResult, IErrors } from 'common/models';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    AgentProgressReportData,
    AssessmentReportData,
    BrokeragesReportData,
    CompetencyReportData,
    DownloadReportRequest,
    DownloadReportResponse,
    EndorsementReportData,
    EventLogsReportData,
    GenerateReportRequest,
    GenerateReportResponse,
    LearningTopicReportData,
    PlatformReportData,
    PrimaryAssociationOptions,
    ReportDataRequest,
    ReportGenerationStatusInfoRequest,
    ReportGenerationStatusInfoResponse,
    UserTopicProgressReportData
} from 'modules/AdminDashboard/models';
import { ReportService } from '../services/reports.service';
import { reportActions } from '../slices/reports.slice';
import { PayloadAction } from '@reduxjs/toolkit';

const reportActionsWatch = function* () {
    yield takeLatest(reportActions.fetchCompetencyReportDataBegin.type, FetchCompetencyReportData);
    yield takeLatest(reportActions.fetchPlatformReportDataBegin.type, FetchPlatformReportData);
    yield takeLatest(
        reportActions.fetchEndorsementReportDataBegin.type,
        FetchEndorsementReportData
    );
    yield takeLatest(reportActions.fetchAssessmentReportDataBegin.type, FetchAssessmentReportData);
    yield takeLatest(
        reportActions.fetchLearningTopicReportDataBegin.type,
        FetchLearningTopicReportData
    );
    yield takeLatest(
        reportActions.fetchAgentProgressReportDataBegin.type,
        FetchAgentProgressReportData
    );
    yield takeLatest(reportActions.fetchUserTopicReportDataBegin.type, FetchUserTopicReportData);
    yield takeEvery(reportActions.downloadReportBegin.type, DownloadReport);
    yield takeLatest(
        reportActions.fetchPrimaryAssociationOptionsBegin.type,
        FetchPrimaryAssociationOptions
    );
    yield takeLatest(reportActions.fetchEventLogsReportDataBegin.type, FetchEventLogsReportData);
    yield takeLatest(reportActions.fetchBrokeragesReportDataBegin.type, FetchBrokeragesReportData);
    yield takeLatest(
        reportActions.fetchReportGenerationStatusBegin.type,
        FetchReportGenerationStatus
    );
    yield takeLatest(reportActions.generateReportBegin.type, GenerateReport);
};

const FetchCompetencyReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<CompetencyReportData> = yield ReportService.fetchCompetencyReportData(
        payload
    );
    if (result.hasErrors) {
        yield put(reportActions.fetchCompetencyReportDataError());
    } else {
        yield put(reportActions.fetchCompetencyReportDataSuccess(result.value));
    }
};

const FetchPlatformReportData = function* () {
    const result: ApiResult<PlatformReportData> = yield ReportService.fetchPlatformReportData();
    if (result.hasErrors) {
        yield put(reportActions.fetchPlatformReportDataError());
    } else {
        yield put(reportActions.fetchPlatformReportDataSuccess(result.value));
    }
};

const FetchEndorsementReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<EndorsementReportData> = yield ReportService.fetchEndorsementReportData(
        payload
    );
    if (result.hasErrors) {
        yield put(reportActions.fetchEndorsementReportDataError());
    } else {
        yield put(reportActions.fetchEndorsementReportDataSuccess(result.value));
    }
};

const FetchAssessmentReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<AssessmentReportData> = yield ReportService.fetchAssessmentReportData(
        payload
    );
    if (result.hasErrors) {
        yield put(reportActions.fetchAssessmentReportDataError());
    } else {
        yield put(reportActions.fetchAssessmentReportDataSuccess(result.value));
    }
};

const FetchLearningTopicReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<LearningTopicReportData> =
        yield ReportService.fetchLearningTopicReportData(payload);
    if (result.hasErrors) {
        yield put(reportActions.fetchLearningTopicReportDataError());
    } else {
        yield put(reportActions.fetchLearningTopicReportDataSuccess(result.value));
    }
};

const FetchAgentProgressReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<AgentProgressReportData> =
        yield ReportService.fetchAgentProgressReportData(payload);
    if (result.hasErrors) {
        yield put(reportActions.fetchAgentProgressReportDataError());
    } else {
        yield put(reportActions.fetchAgentProgressReportDataSuccess(result.value));
    }
};

const FetchUserTopicReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<UserTopicProgressReportData> =
        yield ReportService.fetchUserTopicReportData(payload);
    if (result.hasErrors) {
        yield put(reportActions.fetchUserTopicProgressReportDataError());
    } else {
        yield put(reportActions.fetchUserTopicProgressReportDataSuccess(result.value));
    }
};

const FetchEventLogsReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<EventLogsReportData> = yield ReportService.fetchEventLogsReportData(
        payload
    );
    if (result.hasErrors) {
        yield put(reportActions.fetchEventLogsReportDataError());
    } else {
        yield put(reportActions.fetchEventLogsReportDataSuccess(result.value));
    }
};

const FetchBrokeragesReportData = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<BrokeragesReportData> = yield ReportService.fetchBrokeragesReportData(
        payload
    );
    if (result.hasErrors) {
        yield put(reportActions.fetchBrokeragesReportDataError());
    } else {
        yield put(reportActions.fetchBrokeragesReportDataSuccess(result.value));
    }
};

const DownloadReport = function* ({ payload }: PayloadAction<DownloadReportRequest>) {
    const result: ApiResult<DownloadReportResponse> = yield ReportService.downloadReport(payload);
    if (result.hasErrors) {
        const { errors } = result;
        const status = errors[0]?.response?.status;
        let errorResponse: IErrors[] | string = [];

        if (status && status == 400)
            errorResponse = errors[0].response?.data?.causes[0].details ?? [];

        yield put(
            reportActions.downloadReportError({
                errors: errorResponse,
                reduxStateKey: payload.reduxStateKey
            })
        );
    } else {
        yield put(
            reportActions.downloadReportSuccess({
                ...result.value,
                reduxStateKey: payload.reduxStateKey
            })
        );
    }
};

const FetchPrimaryAssociationOptions = function* ({ payload }: PayloadAction<ReportDataRequest>) {
    const result: ApiResult<PrimaryAssociationOptions> =
        yield ReportService.fetchPrimaryAssociationOptions(payload);
    if (result.hasErrors) {
        yield put(reportActions.fetchPrimaryAssociationOptionsError());
    } else {
        yield put(reportActions.fetchPrimaryAssociationOptionsSuccess(result.value));
    }
};

const FetchReportGenerationStatus = function* ({
    payload
}: PayloadAction<ReportGenerationStatusInfoRequest>) {
    const result: ApiResult<ReportGenerationStatusInfoResponse> =
        yield ReportService.fetchReportGenerationStatus(payload);
    if (result.hasErrors) {
        yield put(reportActions.fetchReportGenerationStatusError());
    } else {
        yield put(reportActions.fetchReportGenerationStatusSuccess(result.value));
    }
};

const GenerateReport = function* ({ payload }: PayloadAction<GenerateReportRequest>) {
    const result: ApiResult<GenerateReportResponse> = yield ReportService.generateReport(payload);
    if (result.hasErrors) {
        yield put(reportActions.generateReportError());
    } else {
        yield put(reportActions.generateReportSuccess(result.value));
    }
};

export { reportActionsWatch };
