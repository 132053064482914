import React, { FC } from 'react';
import './ModalBody.scss';

type ModalBody = {
    className?: string;
    children: React.ReactNode;
};

const ModalBody: FC<ModalBody> = ({ className, children }) => {
    return <div className={`modal-body ${className ?? ''}`}>{children}</div>;
};

export default ModalBody;
