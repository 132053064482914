import React from 'react';
import { MEDIA_COLLECTION } from 'common/configs';
import fileUtil from 'common/utils/file.util';
import './FileUploaderItem.scss';

type FileUploaderItemProps = {
    className?: string;
    fileName: string;
    loadedSize: number;
    totalSize: number;
    onCancelUpload: () => void;
};

const FileUploaderItem: React.FC<FileUploaderItemProps> = ({
    className,
    fileName,
    loadedSize,
    totalSize,
    onCancelUpload
}) => {
    const loadedPercent = Math.floor((loadedSize * 100) / totalSize) || 0;

    return (
        <div className={`file-uploader-item ${className ?? ''}`}>
            <div className="file-details">
                <p className="file-details__name">
                    <img src={MEDIA_COLLECTION.IC_DOC} alt="doc-icon" />
                    <span>{fileName}</span>
                </p>

                <p className="file-details__actions">
                    <span>{`${fileUtil.formatBytes(loadedSize)} of ${fileUtil.formatBytes(
                        totalSize
                    )}`}</span>
                    <img
                        className="cancel-btn"
                        src={MEDIA_COLLECTION.IC_CLOSE_BLACK}
                        alt="close-icon"
                        onClick={onCancelUpload}
                    />
                </p>
            </div>
            <div className="upload-progress__row--outerline">
                <div
                    className="upload-progress__row--innerline"
                    style={{ width: `${loadedPercent}%` }}></div>
            </div>
        </div>
    );
};

export default FileUploaderItem;
