export enum REPORTS {
    CompetencyCompletion = 'Competency completion',
    PlatformUsage = 'Platform usage',
    Endorsement = 'Endorsement',
    Assessment = 'CE Assessment Results',
    LearningTopic = 'Learning Topic Completion',
    AgentProgress = 'Agent Progress',
    UserTopicProgress = 'User Topic Progress',
    EventLogs = 'Event Logs',
    Brokerages = 'Brokerages'
}

export enum REPORT_FORM_FIELDS {
    Search = 'search',
    PrimaryAssociation = 'primaryAssociation'
}

export enum REPORT_GENERATION_STATUS {
    NotAvailable = 'NotAvailable',
    InProgress = 'InProgress',
    Completed = 'Completed',
    Failed = 'Failed'
}
