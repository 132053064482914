import './DownloadLoader.scss';

type DownloadLoaderProps = {
    className?: string;
};

const DownloadLoader = ({ className }: DownloadLoaderProps) => {
    return <div className={`download-loader ${className ?? ''}`}></div>;
};

export default DownloadLoader;
