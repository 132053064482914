import { userProfileApiConfig } from 'common/configs/api';
import {
    ApiResult,
    LogoutRequest,
    LogoutResponse,
    SubmitDeviceInfoRequest,
    SubmitDeviceInfoResponse,
    UserProfileDetails
} from 'common/models';
import { HTTPClient } from 'common/services';

export class UserProfileService extends HTTPClient {
    public static async fetchUserProfile(): Promise<ApiResult<UserProfileDetails>> {
        return await this.invokeApi(userProfileApiConfig.fetchUserProfile());
    }

    public static async submitDeviceInfo(
        request: SubmitDeviceInfoRequest
    ): Promise<ApiResult<SubmitDeviceInfoResponse>> {
        return await this.invokeApi(userProfileApiConfig.submitDeviceInfo(request));
    }

    public static async logout(request: LogoutRequest): Promise<ApiResult<LogoutResponse>> {
        return await this.invokeApi(userProfileApiConfig.logout(request));
    }
}
