import React, { ReactNode } from 'react';
import { Loader, TableColumn } from 'common/components';
import { LEGEND_BASED_ON_TYPE } from 'modules/CommonModules/Users/models';
import './CustomTable.scss';

type CustomTableProps = {
    className?: string;
    tableHeaderClass?: string;
    rowData: { [key: string]: string | null | any }[];
    enableSort?: boolean;
    handleSort?: (value: string) => void;
    sortValue?: string;
    showLoader?: boolean;
    children: ReactNode;
};

type TableColumnProps = {
    className?: string;
    headerName: string;
    field: string;
    onSort?: (value: string) => void;
    sortValue?: string;
    cssStyles?: React.CSSProperties;
    renderer?: (value: any) => React.ReactNode;
    legendBasedOn?: LEGEND_BASED_ON_TYPE;
};

const CustomTable = ({
    className,
    tableHeaderClass,
    rowData,
    enableSort,
    handleSort,
    sortValue,
    showLoader = false,
    children
}: CustomTableProps) => {
    return (
        <div className={`${className} custom-table__container`}>
            <Loader show={showLoader} />
            <div className="custom-table__scrollable">
                <div className="custom-table__header">
                    {React.Children.map(children, (child, index) => {
                        if (React.isValidElement<TableColumnProps>(child)) {
                            const { field, headerName } = child.props;
                            return (
                                <TableColumn
                                    {...child.props}
                                    key={`${index}${field}`}
                                    className={tableHeaderClass}
                                    headerName={headerName}
                                    field={field}
                                    onSort={enableSort ? handleSort : undefined}
                                    sortValue={enableSort ? sortValue : ''}
                                />
                            );
                        }
                        return null;
                    })}
                </div>
                <div className="custom-table__body">
                    {rowData?.map((row, rowIndex) => (
                        <div key={rowIndex}>
                            <div className="custom-table__row" key={rowIndex}>
                                {React.Children.map(children, (child, colIndex) => {
                                    if (React.isValidElement<TableColumnProps>(child)) {
                                        const { field, renderer, cssStyles, className } =
                                            child.props;
                                        const rendererContent = renderer ? renderer?.(row) : '';

                                        return (
                                            <div
                                                className={`custom-table__cell ${className ?? ''}`}
                                                key={`${rowIndex}${colIndex}${field}`}
                                                title={
                                                    renderer
                                                        ? typeof rendererContent == 'string'
                                                            ? rendererContent
                                                            : ''
                                                        : row[field] || (row[field] === 0 ? 0 : '')
                                                }
                                                style={cssStyles}>
                                                {renderer
                                                    ? rendererContent
                                                    : row[field] || (row[field] === 0 ? 0 : '')}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomTable;
