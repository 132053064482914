import { Method } from 'axios';

export class ApiConfig {
    url: string;
    method: Method;
    queryString?: string;
    data?: any;
    constructor(_url = '', _method: Method = 'GET', _queryString = '', _data?: any) {
        this.url = _url;
        this.method = _method;
        this.data = _data;
        this.queryString = _queryString;
    }
}
