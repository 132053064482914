import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from 'common/hooks';
import { SelectOption } from 'common/models';
import { strings } from 'common/constants/app-constants';
import './Select.scss';

type SelectProps = {
    id?: string;
    className?: string;
    value?: string;
    placeHolder: string;
    options: SelectOption[];
    defaultOption?: SelectOption;
    disabled?: boolean;
    onChange: (selectedOption: SelectOption) => void;
};

const Select = ({
    id,
    className,
    placeHolder,
    options,
    defaultOption,
    disabled,
    onChange
}: SelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const intialSelectedOption = { id: '', name: '' };
    const [selectedOption, setSelectedOption] = useState<SelectOption>(intialSelectedOption);

    const optionsRef: any = useRef(null);

    useOnClickOutside(optionsRef, (e) => {
        setIsOpen(false);
    });

    useEffect(() => {
        if (defaultOption?.id) {
            if (defaultOption?.name) {
                setSelectedOption(defaultOption);
            } else {
                // if id is present & name is not then search & assign it
                const optionName =
                    options.find((option) => option.id === defaultOption.id)?.name || '';
                setSelectedOption({ id: defaultOption.id, name: optionName });
            }
        }
    }, [defaultOption]);

    const handleOptionClick = (option: SelectOption) => {
        setSelectedOption(option);
        onChange(option);
        setIsOpen(false);
    };

    return (
        <div
            id={id}
            className={`custom-select ${className} ${disabled ? 'custom-select--disabled' : ''}`}
            ref={optionsRef}>
            <div
                className={`custom-select__header ${isOpen ? 'custom-select__header--open' : ''} ${
                    disabled ? 'custom-select__header--disabled' : ''
                }`}
                onClick={() => (disabled ? undefined : setIsOpen(!isOpen))}>
                <div className={`${selectedOption.name ? 'custom-select__header--has-value' : ''}`}>
                    <p
                        className={`custom-select__placeholder-text ${
                            isOpen ? 'custom-select__placeholder-text--open' : ''
                        }`}>
                        {placeHolder ?? ''}
                    </p>
                    <p className="custom-select__selected-option">{selectedOption.name ?? ''}</p>
                </div>
                <div
                    className={`custom-select__arrow ${
                        isOpen ? 'custom-select__arrow--open' : ''
                    }`}></div>
            </div>
            {isOpen && (
                <ul className="custom-select__options">
                    {options?.length ? (
                        options.map((option, index) => (
                            <li
                                key={index}
                                className={`${
                                    option.name === selectedOption.name
                                        ? 'custom-select__option--selected'
                                        : ''
                                }`}
                                onClick={() => handleOptionClick(option)}>
                                {option.name}
                            </li>
                        ))
                    ) : (
                        <li>{strings.NO_DATA_FOUND}</li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default Select;
