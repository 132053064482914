import React, { useState, useEffect } from 'react';
import { MEDIA_COLLECTION } from 'common/configs';
import { ToastVariant } from 'common/enums';
import './Toast.scss';

interface ToastProps {
    className?: string;
    show: boolean;
    variant: 'info' | 'warning' | 'error' | 'success';
    position?: 'top' | 'bottom';
    message: string;
    duration?: number;
    onClose?: () => void;
}

const Toast: React.FC<ToastProps> = ({
    className = '',
    show,
    variant,
    position = 'bottom',
    message,
    duration = 3000,
    onClose
}) => {
    const [visible, setVisible] = useState(show);

    useEffect(() => {
        setVisible(show);

        if (show) {
            const timer = setTimeout(() => {
                setVisible(false);
                if (onClose) {
                    onClose();
                }
            }, duration);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [duration, onClose, show]);

    const getVariantClass = () => {
        switch (variant) {
            case ToastVariant.INFO:
                return 'info-toast';
            case ToastVariant.WARNING:
                return 'warning-toast';
            case ToastVariant.ERROR:
                return 'error-toast';
            case ToastVariant.SUCCESS:
                return 'success-toast';
            default:
                return '';
        }
    };

    const getImageAsPerVariant = () => {
        switch (variant) {
            case ToastVariant.ERROR:
                return MEDIA_COLLECTION.IC_ERROR;
            case ToastVariant.SUCCESS:
                return MEDIA_COLLECTION.IC_COMPLETE;
            default:
                return '';
        }
    };

    return (
        <div className={`toast ${position}-position ${className} ${getVariantClass()}`}>
            <div className="toast-content">
                <div className="toast-content__left">
                    <span className="toast-content__left--icon">
                        <img src={getImageAsPerVariant()} alt="toast-variant-icon"></img>
                    </span>
                    <p>{message}</p>
                </div>
                <span onClick={onClose} className="toast-content__right">
                    <img src={MEDIA_COLLECTION.IC_CLOSE_BLACK} alt="close-icon" />
                </span>
            </div>
        </div>
    );
};

export default Toast;
