import React from 'react';
import './RadioButton.scss';

type RadioButtonProps = {
    id: string;
    name: string | number;
    checked: boolean;
    onChange: () => void;
};

const RadioButton: React.FC<RadioButtonProps> = ({
    id,
    name,
    checked,
    onChange
}: RadioButtonProps) => {
    return (
        <label htmlFor={id} className="radio-label">
            <input
                className="radio-input"
                type="radio"
                id={id}
                name={id}
                checked={checked}
                onChange={onChange}
            />
            <span className="custom-radio" />
            {name}
        </label>
    );
};

export default RadioButton;
