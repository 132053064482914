import { HTTP_METHOD, UserRoleMapper } from 'common/enums';
import { ApiConfig } from 'common/models';
import { CONTROLLER, METHOD } from './endpoint.config';
import {
    EditUserRequest,
    ForceEndorsementRequest,
    UsersListRequest,
    ViewProgressRequest,
    WipeProgressRequest,
    SyncUserDataRequest,
    DownloadUsersCSVRequest,
    UserRolesRequest
} from 'modules/CommonModules/Users/models';
import { RequestUtil } from 'common/utils';
import {
    GenerateReportRequest,
    ReportGenerationStatusInfoRequest
} from 'modules/AdminDashboard/models';

export const usersApiConfig = {
    fetchUsersList: (request: UsersListRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.USER}`,
            HTTP_METHOD.GET,
            RequestUtil.generateQueryString(request)
        );
    },

    fetchUserRoles: (request: UserRolesRequest): ApiConfig => {
        return request.currentUserType === UserRoleMapper.NAR_ADMIN
            ? new ApiConfig(`${CONTROLLER.ADMIN}/${METHOD.ROLES}`, HTTP_METHOD.GET)
            : new ApiConfig(`${CONTROLLER.BROKERAGES}/${METHOD.ROLES}`, HTTP_METHOD.GET);
    },

    editUser: (request: EditUserRequest): ApiConfig => {
        const reqPayload = {
            email: request.email,
            firstName: request.firstName,
            lastName: request.lastName,
            role: request.role
        };
        return request.currentUserType === UserRoleMapper.NAR_ADMIN
            ? new ApiConfig(
                  `${CONTROLLER.ADMIN}/${CONTROLLER.USER}/${request.userGuid}/${METHOD.EDIT}`,
                  HTTP_METHOD.PUT,
                  '',
                  { ...reqPayload }
              )
            : new ApiConfig(
                  `${CONTROLLER.BROKERAGES}/${CONTROLLER.USER}/${request.userGuid}/${METHOD.EDIT}`,
                  HTTP_METHOD.PUT,
                  '',
                  { ...reqPayload }
              );
    },

    wipeProgress: (request: WipeProgressRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.RESETPROGRESS}`,
            HTTP_METHOD.POST,
            '',
            request
        );
    },

    forceEndorsement: (request: ForceEndorsementRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.FORCE_ENDORSEMENT}`,
            HTTP_METHOD.POST,
            '',
            request
        );
    },

    viewProgress: (request: ViewProgressRequest): ApiConfig => {
        return request.currentUserType === UserRoleMapper.NAR_ADMIN
            ? new ApiConfig(
                  `${CONTROLLER.ADMIN}/${METHOD.USER}/${request.userGuid}`,
                  HTTP_METHOD.GET
              )
            : new ApiConfig(
                  `${CONTROLLER.BROKERAGES}/${METHOD.USER}/${request.userGuid}`,
                  HTTP_METHOD.GET
              );
    },

    syncUserData: (request: SyncUserDataRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.ADMIN}/${METHOD.SYNC_USER_DATA}`,
            HTTP_METHOD.POST,
            '',
            request
        );
    },

    downloadCSV: (request: DownloadUsersCSVRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.DOWNLOAD}/${request.reportType}`,
            HTTP_METHOD.GET
        );
    },

    fetchReportGenerationStatus: (request: ReportGenerationStatusInfoRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${request.reportType}/${METHOD.STATUS_SMALL}`,
            HTTP_METHOD.GET
        );
    },

    generateReport: (request: GenerateReportRequest): ApiConfig => {
        return new ApiConfig(
            `${CONTROLLER.REPORTS}/${METHOD.GENERATE}/${request.reportType}`,
            HTTP_METHOD.POST,
            '',
            { ...request.body }
        );
    }
};
