import { KeyboardEvent, ChangeEvent, FocusEvent, useState } from 'react';
import { MEDIA_COLLECTION } from 'common/configs';
import './Input.scss';

type InputProps = {
    id: string;
    className?: string;
    value: number | string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onEnterPressed?: () => void;
    type?: 'date' | 'email' | 'number' | 'text' | 'password';
    placeHolder: string;
    startIcon?: string;
    endIcon?: string;
    showClearBtn?: boolean;
    onClear?: () => void;
    readonly?: boolean;
};

const Input = ({
    id,
    className,
    value,
    onChange,
    onBlur,
    onFocus,
    onEnterPressed,
    type = 'text',
    placeHolder,
    startIcon,
    endIcon,
    showClearBtn,
    onClear,
    readonly
}: InputProps) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleFocus = (e: FocusEvent<HTMLInputElement, Element>) => {
        setIsFocused(true);
        onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
        setIsFocused(false);
        onBlur?.(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    };

    const handleClear = () => {
        onClear?.();
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onEnterPressed?.();
        }
    };

    return (
        <div
            className={`input-field ${isFocused ? 'input-field__focused' : ''} ${
                value && !isFocused ? 'input-field__has-value' : ''
            }`}>
            {startIcon && (
                <div className="input-field__icon-container-left">
                    <img className="input-field__icon" src={startIcon} alt="start-icon" />
                </div>
            )}

            <input
                id={id}
                className={`input-field__input ${className} ${startIcon ? 'has-start-icon' : ''} ${
                    showClearBtn ? 'has-end-icon' : ''
                } ${readonly ? 'read-only' : ''}`}
                type={type}
                value={value ?? ''}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                readOnly={readonly}
                autoComplete="one-time-code"
            />

            {value && showClearBtn && (
                <div className="input-field__icon-container-right" onClick={handleClear}>
                    <img
                        className="input-field__icon"
                        src={endIcon ? endIcon : MEDIA_COLLECTION.IC_CLEAR}
                        alt="end-icon"
                    />
                </div>
            )}
            <p
                className={`input-field__label ${startIcon ? 'has-start-icon' : ''} ${
                    readonly ? 'read-only' : ''
                }`}>
                {placeHolder}
            </p>
        </div>
    );
};

export default Input;
