import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Loader } from 'common/components';
import AuthorizedLayout from 'layouts/AuthorizedLayout';

const Layout = () => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

    const checkAuthStateAndRedirect = async () => {
        if (!isAuthenticated && !isLoading) {
            await loginWithRedirect();
        }
    };

    useEffect(() => {
        checkAuthStateAndRedirect();
    }, [isAuthenticated, isLoading]);

    if (isLoading) {
        return <Loader show fullscreen />;
    }

    return isAuthenticated ? <AuthorizedLayout /> : isLoading ? <Loader show fullscreen /> : <></>;
};

export default Layout;
