import './ColoredLine.scss';

type ColoredLineProps = {
    className?: string;
    lineHeight?: number;
};

const ColoredLine = ({ className, lineHeight = 3 }: ColoredLineProps) => {
    return <div style={{ height: lineHeight }} className={`colored-line ${className}`}></div>;
};

export default ColoredLine;
