import {
    process,
    profilePic,
    contentIcNARLogo,
    contentIcC2EXLogo,
    contentIcDownArrow,
    contentIcSettings,
    contentIcLogout,
    contentIcNARFooterLogo,
    contentIcRealtorLogo,
    contentIcClear,
    contentIcSearch,
    contentIcNext,
    contentIcPrevious,
    contentIcCalendar,
    contentIcDelete,
    contentIcEdit,
    contentIcFirstPage,
    contentIcLastPage,
    contentIcPreviousPage,
    contentIcNextPage,
    contentIcAdd,
    contentIcDownload,
    contentScenery,
    contentIcSiteSettingsScenery,
    contentIcClose,
    contentIcCloseBlack,
    contentIcView,
    contentIcHistory,
    contentIcBin,
    contentIcBookmark,
    contentIcSetting,
    contentIcUpload,
    contentIcBackBtn,
    contentIcComplete,
    contentIcError,
    contentIcUpArrowBlack,
    contentIcLearningCompleted24px,
    contentIcLearningProgressing24px,
    contentIcLearningNotStarted24px,
    contentIcLearningCompleted18px,
    contentIcLearningProgressing18px,
    contentIcLearningNotStarted18px,
    contentIcJPGIcon,
    contentIcZIPIcon,
    contentIcDoc,
    contentIcBackArrow,
    contentIcUser,
    contentIcDownloadDisabled,
    contentIcGenerateCSV,
    contentIcGenerateCSVDisabled
} from 'assets/media';

export const MEDIA_COLLECTION = {
    IMG_PROCESS: process,
    IMG_PROFILE_PIC: profilePic,
    IC_NAR_LOGO: contentIcNARLogo,
    IC_C2EX_LOGO: contentIcC2EXLogo,
    IC_DOWN_ARROW: contentIcDownArrow,
    IC_SETTINGS: contentIcSettings,
    IC_LOGOUT: contentIcLogout,
    IC_FOOTER_NAR_LOGO: contentIcNARFooterLogo,
    IC_REALTOR_LOGO: contentIcRealtorLogo,
    IC_CLEAR: contentIcClear,
    IC_SEARCH: contentIcSearch,
    IC_NEXT: contentIcNext,
    IC_PREVIOUS: contentIcPrevious,
    IC_CALENDAR: contentIcCalendar,
    IC_DELETE: contentIcDelete,
    IC_EDIT: contentIcEdit,
    IC_FIRST_PAGE: contentIcFirstPage,
    IC_LAST_PAGE: contentIcLastPage,
    IC_PREVIOUS_PAGE: contentIcPreviousPage,
    IC_NEXT_PAGE: contentIcNextPage,
    IC_ADD: contentIcAdd,
    IC_DOWNLOAD: contentIcDownload,
    IC_DOWNLOAD_DISABLED: contentIcDownloadDisabled,
    IC_GENERATE_CSV: contentIcGenerateCSV,
    IC_GENERATE_CSV_DISABLED: contentIcGenerateCSVDisabled,
    IC_SCENERY: contentScenery,
    IC_SITE_SETTINGS_SCENERY: contentIcSiteSettingsScenery,
    IC_CLOSE: contentIcClose,
    IC_CLOSE_BLACK: contentIcCloseBlack,
    IC_VIEW: contentIcView,
    IC_HISTORY: contentIcHistory,
    IC_BIN: contentIcBin,
    IC_BOOKMARK: contentIcBookmark,
    IC_SETTING: contentIcSetting,
    IC_UPLOAD: contentIcUpload,
    IC_COMPLETE: contentIcComplete,
    IC_ERROR: contentIcError,
    IC_UP_ARROW_BLACK: contentIcUpArrowBlack,
    IC_LEARNING_COMPLETED_24PX: contentIcLearningCompleted24px,
    IC_LEARNING_PROGRESSING_24PX: contentIcLearningProgressing24px,
    IC_LEARNING_NOT_STARTED_24PX: contentIcLearningNotStarted24px,
    IC_LEARNING_COMPLETED_18PX: contentIcLearningCompleted18px,
    IC_LEARNING_PROGRESSING_18PX: contentIcLearningProgressing18px,
    IC_LEARNING_NOT_STARTED_18PX: contentIcLearningNotStarted18px,
    IC_JPG_ICON: contentIcJPGIcon,
    IC_ZIP_ICON: contentIcZIPIcon,
    IC_DOC: contentIcDoc,
    IC_BACK_ARROW: contentIcBackArrow,
    IC_USER: contentIcUser
};
