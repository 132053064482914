import { ApiStatus } from 'common/enums';
import { ApiResponse } from 'common/models';
import {
    AddExternalUserResponse,
    DeleteExternalUserResponse,
    ExternalUsersListResponse,
    initialAddExternalUser,
    initialDeleteExternalUser,
    initialExternalUsersList,
    initialUpdateExternalUser
} from 'modules/CommonModules/Users/models';

export type ExternalUsersListState = {
    list: ApiResponse<ExternalUsersListResponse>;
    add: ApiResponse<AddExternalUserResponse>;
    update: ApiResponse<AddExternalUserResponse>;
    delete: ApiResponse<DeleteExternalUserResponse>;
};

export const initialExternalUsersListState: ExternalUsersListState = {
    list: {
        data: initialExternalUsersList,
        status: ApiStatus.NONE
    },
    add: {
        data: initialAddExternalUser,
        status: ApiStatus.NONE
    },
    update: {
        data: initialUpdateExternalUser,
        status: ApiStatus.NONE
    },
    delete: {
        data: initialDeleteExternalUser,
        status: ApiStatus.NONE
    }
};
