const SESSION_KEY = {
    TOKEN: 'access_token'
};

const CONFIG = {
    TOKEN_REFRESH_TIME: 1800000 // 30 minutes
};

export const AUTH_CONFIG = {
    SESSION_KEY,
    CONFIG
};
