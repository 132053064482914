import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENV_CONFIG } from 'common/configs';

type Auth0ProviderWithNavigateType = {
    children: ReactNode;
};

export const Auth0ProviderWithNavigate = ({ children }: Auth0ProviderWithNavigateType) => {
    const navigate = useNavigate();

    const domain = ENV_CONFIG.AUTH0_DOMAIN;
    const clientId = ENV_CONFIG.AUTH0_CLIENT_ID;
    const redirectUri = ENV_CONFIG.AUTH0_CALLBACK_URL;
    const audience = ENV_CONFIG.AUTH0_AUDIENCE;

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname, { replace: true });
    };

    if (!(domain && clientId && redirectUri && audience)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                audience: audience,
                redirect_uri: redirectUri
            }}
            onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};
