import React, { ChangeEvent } from 'react';
import './CheckBox.scss';

type CheckboxProps = {
    className?: string;
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({ className = '', label, checked, onChange }) => {
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.checked);
        }
    };

    return (
        <div className={`checkbox-container ${className ?? ''}`}>
            <input
                type="checkbox"
                className="custom-checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
            />
            <label className="checkbox-label">{label}</label>
        </div>
    );
};

export default Checkbox;
