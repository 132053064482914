export const strings = {
    APP_NAME: 'NAR Admin Dashboard',
    APP_HEADER_NAME: 'NAR Admin Dashboard',
    ADD: 'Add',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    SUBMIT: 'Submit',
    CLOSE: 'Close',
    RESET: 'Reset',
    PROCEED: 'Proceed',
    OK: 'Ok',
    LOADING: 'Loading results...',
    DOWNLOAD: 'Download',
    REUPLOAD: 'Re-Upload',
    SAVE_CHANGES: 'Save changes',
    GENERATE_CSV: 'Generate CSV',
    DOWNLOAD_CSV: 'Download CSV',
    NO_DATA_FOUND: 'No data found',
    SOMETHING_WENT_WRONG: 'Something went wrong',
    SEARCH_RESULTS: 'Search results',
    CLEAR_SEARCH: 'Clear search',
    EMAIL_VALIDATION_ERROR: 'Please enter a valid email',
    FILE_UPLOAD: {
        DRAG_AND_DROP: 'Drag and drop a document or ',
        BROWSE: 'browse'
    },
    HEADER: {
        ADMIN_DASHBOARD: 'Admin Dashboard',
        SETTINGS: 'Settings',
        LOGOUT: 'Logout'
    },
    FOOTER: {
        REALTOR_DESC: "NAR's exclusive top-level domain.",
        COPYRIGHT: '© ',
        COPYRIGHT_TEXT: 'National Association of REALTORS®. All Rights Reserved.',
        HEADQUARTERS: 'Headquarters',
        HEADQUARTERS_ADD_LINE_1: '430 N. Michigan Ave',
        HEADQUARTERS_ADD_LINE_2: 'Chicago, IL 60611-4087',
        HEADQUARTERS_ADD_LINE_3: '800-874-6500',
        DC_OFFICE: 'DC Office',
        DC_OFFICE_ADD_LINE_1: '500 New Jersey Avenue, NW',
        DC_OFFICE_ADD_LINE_2: 'Washington, D.C. 20001-2020',
        DC_OFFICE_ADD_LINE_3: '202-383-1000',
        FOLLOW_US: 'Follow us',
        CONTACT_US: 'Contact us',
        PHONE_NUM: '1.888.299.9669',
        LIVE_CHAT: 'Live Chat',
        LIVE_CHAT_TIME: '(Mon-Fri, 8AM-5PM Central)'
    },
    USERS: {
        TITLE: 'Users',
        SEARCH: 'Search',
        ENDORSED_DATE_FROM: 'Endorsed date from: ',
        ENDORSED_DATE_TO: 'Endorsed date to: ',
        PRIMARY_ASSOCIATION: 'Primary association',
        ADD_USER: 'Add user',
        DOWNLOAD_CSV: 'Download CSV',
        ADD_EXTERNAL_USER: 'Add external user',
        NO_DATA_FOUND: 'No data found',
        ACCESS_TYPE: 'Access type (Optional)',
        BACK: 'Back to users',
        EDIT_USER_MODAL: {
            TITLE: 'Edit user',
            VIEW_PROGRESS: 'View progress',
            FORCE_ASSESSMENT: 'Force assessment reset',
            WIPE_PROGRESS: 'Wipe progress',
            FORCE_ENDORSEMENT: 'Force endorsement',
            SYNC_WITH_MMP: 'Sync with MMP/RAMCO',
            LAST_SYNC: 'Last sync date: ',
            UPDATE_SUCCESS: 'User updated successfully',
            WIPE_PROGRESS_SUCCESS: 'Progress wiped successfully',
            WIPE_PROGRESS_CONFIRM_HEADER: 'Wipe progress',
            WIPE_PROGRESS_CONFIRM_BODY: 'Do you want to wipe the progress for selected user?',
            FORCE_ENDORSED_SUCCESS: 'Force endorsed successfully',
            FORCE_ENDORSED_CONFIRM_HEADER: 'Force endorsement',
            FORCE_ENDORSED_CONFIRM_BODY: 'Do you want to force endorse the selected user?',
            SYNC_WITH_MMP_STARTED: 'Sync has been started.'
        },
        VIEW_PROGRESS_MODAL: {
            TITLE: 'View progress',
            ENDORSEMENT_DATE: 'Endorsement date:',
            RENEWAL_DATE: 'Renewal date:',
            NO_LESSONS_FOUND: 'No lessons found',
            PROGRESSING: 'In Progress',
            COMPLETED: 'Completed',
            NOT_STARTED: 'Not Started'
        },
        DELETE_USER_MODAL: {
            TITLE: 'Delete user',
            CONFIRM_LINE_1: 'Are you sure you want to delete the user?',
            CONFIRM_LINE_2: 'Please enter the user email to confirm.'
        }
    },
    EXTERNAL_USERS: {
        TITLE: 'Add / Edit External users',
        INVITE_USER: 'Invite user',
        ROLE: 'Role',
        DELETE_MODAL_TITLE: 'Delete user',
        DELETE_MODAL_BODY: 'Do you want to delete the selected user?',
        ADD_USER_SUCCESS: 'User added successfully',
        UPDATE_USER_SUCCESS: 'User updated successfully',
        DELETE_USER_SUCCESS: 'User deleted successfully',
        ROLE_VALIDATION_ERROR: 'Please choose a role',
        STATUS: 'Status'
    },
    USERS_TABLE: {
        LAST_NAME: 'Last name',
        FIRST_NAME: 'First name',
        EMAIL: 'Email',
        M1: 'M1#',
        BROKERAGE: 'Brokerage',
        TYPE: 'Type',
        LAST_LOGIN: 'Last Login',
        COUNT_LOGIN: 'Count Login',
        PERCENT_OF_ENDORSEMENT: '% of Endorsement'
    },
    EXTERNAL_USERS_TABLE: {
        EMAIL: 'Email',
        ROLE: 'Role',
        STATUS: 'Status',
        ISACCEPTED: 'Is Accepted'
    },
    BROKERAGES: {
        TITLE: 'Brokerages',
        NAME: 'Name',
        CITY: 'City',
        STATE: 'State',
        M1: 'M1#',
        ENDORSEMENT: 'Endorsement',
        USERS_COUNT: 'User count',
        EDIT_MODAL: {
            HEADER: 'Edit Brokerage',
            NAME: 'Name:',
            EMAIL: 'Email',
            MARK_AS_ENDORSED: 'Mark as endorsed?',
            NOTE: 'Note:',
            NOTE_CONTENT:
                'Please provide a contact email in case a broker manager from this brokerage has no logged into the C2EX platform.',
            EMAIL_VALIDATION_ERROR: 'Please enter a valid email',
            CHANGES_SAVED: 'Changes saved successfully'
        }
    },
    REPORTS: {
        TITLE: 'Reports',
        SELECT_REPORT: 'Select a report',
        CREATING_CSV: 'Creating CSV file...',
        GENERATION_FAILED: 'The generation of the report failed.',
        LAST_GENERATED: 'Last generated: ',
        STAFF_REPORT: 'Staff Report',
    },
    ENDORSEMENT_MATERIAL: {
        TITLE: 'Endorsement Materials',
        EDIT_ENDORSEMENT: 'Edit endorsement materials',
        UPLOAD_ENDORSEMENT_ZIP: 'Upload endorsement zip',
        CONTENT_LIMIT_20_MB: 'Content upload is limited to 20MB',
        UPLOAD_ENDORSEMENT_CERT: 'Upload Endorsement Certificate background',
        UPLOAD_ASSESSMENT_CERT: 'Upload Assessment Certificate background',
        CONTENT_LIMIT_2_MB: 'Content upload is limited to 2MB',
        IMAGE_TEMPLATE: 'Image must resemble current template',
        FILE_IN_JPG: 'File must be in .jpg format',
        DOWNLOAD_CURRENT_VERSION: 'Download current version',
        ZIP_UPLOAD_ERROR_HEADER: 'Zip upload error',
        ZIP_UPLOAD_ERROR_BODY:
            'Content upload is <b> limited to 20MB </b> and the uploaded zip file is more than 20MB.',
        ZIP_UPLOAD_ERROR_FOOTER: 'Please upload a new Zip file.',
        CERT_BG_UPLOAD_ERROR_HEADER: 'Certificate background upload error',
        CERT_BG_UPLOAD_ERROR_BODY:
            'Certificate background content is limited to <b> 2MB </b> in a <b> .jpg </b> format and the uploaded certificate is the wrong format or more than 2MB.',
        CERT_BG_UPLOAD_ERROR_FOOTER: 'Please re upload a new file.',
        RETURN_TO_FILE_LISTING: 'Return to File Listing'
    },
    RESET_PROGRESS: {
        TITLE: 'Reset Progress',
        CONFIRM_LINE_1: 'Are you sure you want to reset the progress?'
    }
};
