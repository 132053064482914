import { Component, ReactNode } from 'react';
import FallBack from './Fallback';

declare const process: NodeJS.Process;

type ErrorBoundaryProps = {
    children: ReactNode;
    showFallback?: boolean;
    onError?: () => void;
};

type State = {
    hasError: boolean;
};

type ErrorInfo = {
    componentStack: string;
};

export default class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
    state: State = {
        hasError: false
    };

    static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
        this.props.onError?.();
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('Error Boundary', error);
            console.log('Error Info', info);
            console.log(this.props);
        }

        //ToDo: Add telemetry service call
    }
    render() {
        if (this.props.showFallback) {
            return <FallBack />;
        }
        return this.props.children;
    }
}
