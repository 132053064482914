import { DEFAULT_PAGE_SIZE } from 'common/configs';
import { IErrors } from 'common/models';

export type LEGEND_BASED_ON_TYPE = 'percentage' | 'yesOrNo' | 'activeInactive';

export type UsersListRequest = {
    pageNum?: number;
    pageSize?: number;
    filters?: string;
    sortOrder?: string;
    sortColumn?: string;
    searchQuery?: string;
    endorsedDateFrom?: string;
    endorsedDateTo?: string;
    primaryAssociationId?: string;
};

export type UsersDetails = {
    lastName: string;
    firstName: string;
    fullName: string;
    email: string;
    m1Number: string;
    brokerage: string;
    type: string;
    lastLogin: string;
    countLogin: string;
    id: string;
    endorsement: string;
    endorsedStartDate: string;
    roleCode: string;
    roleNames: string;
    syncDate: string;
};

export type UsersListResponse = {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    items: UsersDetails[];
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
};

export type UserRolesRequest = {
    currentUserType: string;
};

export type UserRolesResponse = {
    name: string;
    code: string;
}[];

export type GenericSuccessResponse = {
    isSuccess: boolean;
    hasNudge: boolean;
    title: string;
    responseDetails: {
        details: string;
        code: string;
        additionalParameters: null;
    };
};

export type EditUserRequest = {
    userGuid: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    currentUserType: string;
};

export type EditUserResponse = GenericSuccessResponse & {
    userGuid: string;
};

export type WipeProgressRequest = {
    userGuid: string;
};

export type WipeProgressResponse = GenericSuccessResponse;

export type ForceEndorsementRequest = {
    userGuid: string;
    currentDate: string;
};

export type ForceEndorsementResponse = EditUserResponse;

export type ViewProgressRequest = {
    userGuid: string;
    currentUserType: string;
};

export type ViewProgressLessons = {
    competencyId: number;
    name: string;
    description: string;
    competencyCode: string;
    isCompleted: boolean | null;
    sortIndex: number;
    isBrokerOnly: boolean | null;
    lessonObjects: viewProgressLessonObjects[];
};

export type viewProgressLessonObjects = {
    lessonObjectId: number;
    lessonObjectCode: string;
    name: string;
    description: string;
    estimateTime: number;
    sortIndex: number;
    isCompleted: boolean | null;
    isStarted: boolean;
    thumbnailUrl: string;
    completedThumbnailUrl: string;
    contents: null | unknown;
};

export type ViewProgressResponse = {
    id: string;
    lastName: string;
    firstName: string;
    email: string;
    type: string;
    m1Number: string;
    endorsedDate: string;
    renewalDate: string;
    lessons: ViewProgressLessons[];
};

export type SyncUserDataRequest = {
    userId: string;
};

export type SyncUserDataResponse = GenericSuccessResponse;

export type SyncUserDataErrorResponse = {
    errors: IErrors[] | string;
};

// Download users csv
export type DownloadUsersCSVRequest = {
    reportType: string;
};

export type DownloadUsersCSVResponse = {
    sasUri: string;
    fileName: string;
};

export type DownloadUsersCSVError = {
    errors: IErrors[] | string;
};

export const initialUsersListResponse: UsersListResponse = {
    hasPreviousPage: false,
    hasNextPage: false,
    items: [],
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: DEFAULT_PAGE_SIZE
};

export const initialGenericSuccessResponse: GenericSuccessResponse = {
    isSuccess: false,
    hasNudge: false,
    title: '',
    responseDetails: {
        details: '',
        code: '',
        additionalParameters: null
    }
};

export const initialEditUserResponse: EditUserResponse = {
    userGuid: '',
    ...initialGenericSuccessResponse
};

export const initialWipeProgressResponse: WipeProgressResponse = {
    ...initialGenericSuccessResponse
};

export const initialForceEndorsementResponse: ForceEndorsementResponse = {
    userGuid: '',
    ...initialGenericSuccessResponse
};

export const initialViewProgressResponse: ViewProgressResponse = {
    id: '',
    lastName: '',
    firstName: '',
    email: '',
    type: '',
    m1Number: '',
    endorsedDate: '',
    renewalDate: '',
    lessons: []
};

export const initialSyncUserDataResponse: SyncUserDataResponse = {
    ...initialGenericSuccessResponse
};

export const initialDownloadUsersReportResponse: DownloadUsersCSVResponse = {
    sasUri: '',
    fileName: ''
};
