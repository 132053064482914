import React from 'react';
import { ContactUsInfo, FollowUsInfo, MEDIA_COLLECTION } from 'common/configs';
import ColoredLine from '../ColoredLine';
import { strings } from 'common/constants/app-constants';
import { Link } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
    return (
        <div className="app-footer">
            <ColoredLine className="footer-colored-line" lineHeight={7} />
            <footer className="app-footer-container">
                <div className="app-footer-container__left">
                    <img
                        className="app-footer-container__left--nar-logo"
                        src={MEDIA_COLLECTION.IC_FOOTER_NAR_LOGO}
                        aria-hidden="true"
                    />

                    <div className="app-footer-container__left--sub-container">
                        <p>
                            {strings.FOOTER.COPYRIGHT} {new Date().getFullYear()}{' '}
                            {strings.FOOTER.COPYRIGHT_TEXT}
                        </p>

                        <img
                            className="app-footer-container__left--realtor-logo"
                            src={MEDIA_COLLECTION.IC_REALTOR_LOGO}
                            aria-hidden="true"
                        />
                        <span>{strings.FOOTER.REALTOR_DESC}</span>
                    </div>
                </div>

                <div className="app-footer-container__vertical-line"></div>

                <div className="app-footer-container__right">
                    <div className="app-footer-container__right--adress-container">
                        <div className="app-footer-container__right--headquarters">
                            <p className="title">{strings.FOOTER.HEADQUARTERS}</p>
                            <p className="desc">{strings.FOOTER.HEADQUARTERS_ADD_LINE_1}</p>
                            <p className="desc">{strings.FOOTER.HEADQUARTERS_ADD_LINE_2}</p>
                            <p className="desc">{strings.FOOTER.HEADQUARTERS_ADD_LINE_3}</p>
                        </div>

                        <div className="app-footer-container__right--office">
                            <p className="title">{strings.FOOTER.DC_OFFICE}</p>
                            <p className="desc">{strings.FOOTER.DC_OFFICE_ADD_LINE_1}</p>
                            <p className="desc">{strings.FOOTER.DC_OFFICE_ADD_LINE_2}</p>
                            <p className="desc">{strings.FOOTER.DC_OFFICE_ADD_LINE_3}</p>
                        </div>
                    </div>

                    <div className="app-footer-container__right--communication-container">
                        <div className="app-footer-container__right--follow">
                            <p className="title">{strings.FOOTER.FOLLOW_US}</p>
                            <div className="app-footer-container__right--icons">
                                {Object.entries(FollowUsInfo).map(([key, data]) => (
                                    <Link
                                        key={key}
                                        to={data.url}
                                        target="_blank noop"
                                        rel="noopener noreferrer">
                                        {React.createElement(data.iconComponent)}
                                    </Link>
                                ))}
                            </div>
                        </div>

                        <div className="app-footer-container__right--contact">
                            {Object.entries(ContactUsInfo).map(([key, data]) =>
                                data.url ? (
                                    <a
                                        key={key}
                                        href={data.url}
                                        target="_blank noop"
                                        rel="noopener noreferrer">
                                        <div className="app-footer-container__right--contact-row">
                                            {React.createElement(data.iconComponent)}
                                            <span className="underline">{data.desc}</span>
                                        </div>
                                    </a>
                                ) : (
                                    <div
                                        key={key}
                                        className="app-footer-container__right--contact-row non-clickable">
                                        {React.createElement(data.iconComponent)}
                                        <span>{data.desc}</span>
                                    </div>
                                )
                            )}

                            <p className="font-small">{strings.FOOTER.LIVE_CHAT_TIME}</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
