import React, { useEffect, useState } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Select
} from 'common/components';
import { strings } from 'common/constants/app-constants';
import { MEDIA_COLLECTION } from 'common/configs';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { usersActions } from 'modules/CommonModules/Users/redux';
import { EDIT_USER_ACTIONS } from 'modules/CommonModules/Users/enums';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';
import ViewProgressModal from '../ViewProgressModal/ViewProgressModal';
import { UsersDetails } from 'modules/CommonModules/Users/models';
import { ApiStatus, UserRoleMapper } from 'common/enums';
import { dateUtil } from 'common/utils';
import { UserRoles } from 'common/models';
import './EditUserModal.scss';

type EditUserModalProps = {
    currentUserRole: UserRoles;
    selectedRow: UsersDetails;
    onCancel: () => void;
};

const EditUserModal: React.FC<EditUserModalProps> = ({
    currentUserRole,
    selectedRow,
    onCancel
}) => {
    const dispatch = useAppDispatch();
    const { data: userRoles } = useAppSelector((state) => state.users.roles);
    const [currentEditAction, setCurrentEditAction] = useState<EDIT_USER_ACTIONS | null>(null);

    const { data: syncUserData, status: syncUserDataStatus } = useAppSelector(
        (state) => state.users.syncUserData
    );

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        accessType: {
            id: '',
            name: ''
        }
    });

    useEffect(() => {
        setFormData({
            firstName: selectedRow.firstName,
            lastName: selectedRow.lastName,
            email: selectedRow.email,
            accessType: {
                id: selectedRow.roleCode,
                name: ''
            }
        });

        return () => {
            dispatch(usersActions.resetSyncUserData());
        };
    }, []);

    const handleChange = (fieldName: string, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value
        }));
    };

    const handleEditActions = (action: EDIT_USER_ACTIONS) => {
        switch (action) {
            case EDIT_USER_ACTIONS.ViewProgress: {
                setCurrentEditAction(EDIT_USER_ACTIONS.ViewProgress);
                break;
            }
            case EDIT_USER_ACTIONS.WipeProgress: {
                setCurrentEditAction(EDIT_USER_ACTIONS.WipeProgress);
                break;
            }
            case EDIT_USER_ACTIONS.ForceEndorsement: {
                setCurrentEditAction(EDIT_USER_ACTIONS.ForceEndorsement);
                break;
            }
            case EDIT_USER_ACTIONS.SyncWithRAMCO: {
                dispatch(usersActions.syncUserDataBegin({ userId: selectedRow.id }));
                break;
            }
        }
    };

    const handleSaveUser = () => {
        const request = {
            userGuid: selectedRow.id,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            role: formData.accessType.id,
            currentUserType: currentUserRole
        };

        dispatch(usersActions.editUserBegin(request));
        onCancel();
    };

    return (
        <>
            <div>
                <Modal className="edit-user-modal" show size="md" onClose={onCancel}>
                    <ModalHeader>
                        <div>{strings.USERS.EDIT_USER_MODAL.TITLE}</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="edit-user-modal__body-content">
                            <Input
                                id="firstName"
                                readonly
                                placeHolder={strings.USERS_TABLE.FIRST_NAME}
                                value={formData.firstName}
                                onChange={(e) => handleChange('firstName', e.target.value)}
                            />
                            <Input
                                id="lastName"
                                readonly
                                placeHolder={strings.USERS_TABLE.LAST_NAME}
                                value={formData.lastName}
                                onChange={(e) => handleChange('lastName', e.target.value)}
                            />
                            <Input
                                id="email"
                                type="email"
                                readonly
                                placeHolder={strings.USERS_TABLE.EMAIL}
                                value={formData.email}
                                onChange={(e) => handleChange('email', e.target.value)}
                            />
                            <Select
                                defaultOption={formData.accessType}
                                options={userRoles?.map((role) => {
                                    return {
                                        id: role.code,
                                        name: role.name
                                    };
                                })}
                                placeHolder={strings.USERS.ACCESS_TYPE}
                                onChange={(selectedOption) =>
                                    handleChange('accessType', selectedOption)
                                }
                            />

                            <div className="edit-user-modal__body-content--controls">
                                {currentUserRole === UserRoleMapper.NAR_ADMIN ? (
                                    <>
                                        <div className="edit-user-modal__body-content--controls--top">
                                            <div
                                                className="control__wrapper"
                                                onClick={() =>
                                                    handleEditActions(
                                                        EDIT_USER_ACTIONS.ViewProgress
                                                    )
                                                }>
                                                <img src={MEDIA_COLLECTION.IC_VIEW}></img>
                                                <span>
                                                    {strings.USERS.EDIT_USER_MODAL.VIEW_PROGRESS}
                                                </span>{' '}
                                            </div>

                                            <div
                                                className="control__wrapper"
                                                onClick={() =>
                                                    handleEditActions(
                                                        EDIT_USER_ACTIONS.ForceEndorsement
                                                    )
                                                }>
                                                <img src={MEDIA_COLLECTION.IC_BOOKMARK}></img>
                                                <span>
                                                    {
                                                        strings.USERS.EDIT_USER_MODAL
                                                            .FORCE_ENDORSEMENT
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="edit-user-modal__body-content--controls--bottom">
                                            <div
                                                className="control__wrapper"
                                                onClick={() =>
                                                    handleEditActions(
                                                        EDIT_USER_ACTIONS.WipeProgress
                                                    )
                                                }>
                                                <img src={MEDIA_COLLECTION.IC_BIN}></img>
                                                <span>
                                                    {strings.USERS.EDIT_USER_MODAL.WIPE_PROGRESS}
                                                </span>
                                            </div>

                                            <div className="control">
                                                <div
                                                    className="control__wrapper"
                                                    onClick={() =>
                                                        handleEditActions(
                                                            EDIT_USER_ACTIONS.SyncWithRAMCO
                                                        )
                                                    }>
                                                    <img src={MEDIA_COLLECTION.IC_SETTING}></img>
                                                    <span>
                                                        {
                                                            strings.USERS.EDIT_USER_MODAL
                                                                .SYNC_WITH_MMP
                                                        }
                                                    </span>
                                                </div>
                                                <p className="sync-date">
                                                    {syncUserDataStatus === ApiStatus.SUCCESS &&
                                                    syncUserData.isSuccess
                                                        ? strings.USERS.EDIT_USER_MODAL
                                                              .SYNC_WITH_MMP_STARTED
                                                        : strings.USERS.EDIT_USER_MODAL.LAST_SYNC +
                                                          '' +
                                                          (dateUtil.convertUTCtoLocalDate(
                                                              selectedRow.syncDate
                                                          ) || 'NA')}
                                                </p>
                                            </div>
                                        </div>{' '}
                                    </>
                                ) : currentUserRole === UserRoleMapper.BROKER_MANAGER ? (
                                    <div className="edit-user-modal__body-content--broker-controls">
                                        <div
                                            className="control__wrapper"
                                            onClick={() =>
                                                handleEditActions(EDIT_USER_ACTIONS.ViewProgress)
                                            }>
                                            <img src={MEDIA_COLLECTION.IC_VIEW}></img>
                                            <span>
                                                {strings.USERS.EDIT_USER_MODAL.VIEW_PROGRESS}
                                            </span>{' '}
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-save" variant="primary" onClick={handleSaveUser}>
                            {strings.SAVE}
                        </Button>
                        <Button className="btn-cancel" variant="secondary" onClick={onCancel}>
                            {strings.CANCEL}
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
            {(currentEditAction === EDIT_USER_ACTIONS.WipeProgress ||
                currentEditAction === EDIT_USER_ACTIONS.ForceEndorsement) && (
                <ConfirmationModal
                    actionName={currentEditAction}
                    selectedRow={selectedRow}
                    onCancel={() => setCurrentEditAction(null)}
                />
            )}
            {currentEditAction === EDIT_USER_ACTIONS.ViewProgress && (
                <ViewProgressModal
                    currentUserRole={currentUserRole}
                    selectedRow={selectedRow}
                    onCancel={() => setCurrentEditAction(null)}
                />
            )}
        </>
    );
};

export default EditUserModal;
