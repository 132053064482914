import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { strings } from 'common/constants/app-constants';
import { DEFAULT_PAGE_SIZE, MEDIA_COLLECTION } from 'common/configs';
import { AppUtil } from 'common/utils';
import { ApiStatus, ToastVariant } from 'common/enums';
import {
    CustomTable,
    LegendDot,
    Pagination,
    TableColumn,
    Button,
    Input,
    Scenery
} from 'common/components';
import { LEGEND_BASED_ON } from 'modules/CommonModules/Users/enums';
import { externalUsersActions } from 'modules/CommonModules/Users/redux';
import { ToastMessageUtil } from 'common/services';
import { ExternalUsersDetails } from '../../models';
import ExternalUserAddOrEditModal from './Components/ExternalUserAddOrEditModal/ExternalUserAddOrEditModal';
import ConfirmationModal from './Components/ExternalUserAddOrEditModal/ConfirmationModal/ConfirmationModal';
import './AddExternalUsers.scss';

type AddExternalUsersProps = {
    backToUsers: () => void;
};

const AddExternalUsers = ({ backToUsers }: AddExternalUsersProps) => {
    const dispatch = useAppDispatch();
    const { data: usersListData, status: usersListStatus } = useAppSelector(
        (state) => state.externalUsers.list
    );
    const {
        data: addUserData,
        status: addUserDataStatus,
        errors: addUserErrors
    } = useAppSelector((state) => state.externalUsers.add);
    const {
        data: updateUserData,
        status: updateUserDataStatus,
        errors: updateUserErrors
    } = useAppSelector((state) => state.externalUsers.update);

    const { data: deleteUserData, status: deleteUserDataStatus } = useAppSelector(
        (state) => state.externalUsers.delete
    );

    const userRoleOptions = [
        {
            id: 'NARAdministrator',
            name: 'NAR Administrator'
        },
        {
            id: 'StandardUser',
            name: 'Standard User'
        }
    ];

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState({
        pageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        sortValue: '',
        sortOrder: '',
        sortColumn: ''
    });

    const [isSearchActive, setSearchActive] = useState<boolean>(false);
    const [showAddOrEditModal, setShowAddOrEditModal] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState<ExternalUsersDetails | undefined>(
        undefined
    );
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    useEffect(() => {
        if (
            addUserDataStatus === ApiStatus.SUCCESS ||
            updateUserDataStatus === ApiStatus.SUCCESS ||
            deleteUserDataStatus === ApiStatus.SUCCESS
        ) {
            if (addUserData.isSuccess) {
                ToastMessageUtil.show(strings.EXTERNAL_USERS.ADD_USER_SUCCESS, () =>
                    dispatch(externalUsersActions.resetAddExternalUser())
                );
            } else if (updateUserData.isSuccess) {
                ToastMessageUtil.show(strings.EXTERNAL_USERS.UPDATE_USER_SUCCESS, () =>
                    dispatch(externalUsersActions.resetUpdateExternalUser())
                );
            } else if (deleteUserData.isSuccess) {
                ToastMessageUtil.show(strings.EXTERNAL_USERS.UPDATE_USER_SUCCESS, () =>
                    dispatch(externalUsersActions.resetDeleteExternalUser())
                );
            } else {
                ToastMessageUtil.show(
                    strings.SOMETHING_WENT_WRONG,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
            // refetch users list
            fetchExternalUsersList();
        }
    }, [addUserDataStatus, updateUserDataStatus, deleteUserDataStatus]);

    useEffect(() => {
        if (updateUserDataStatus === ApiStatus.ERROR || addUserDataStatus === ApiStatus.ERROR) {
            if (updateUserErrors && updateUserErrors?.length) {
                const errMsg = updateUserErrors || '';
                ToastMessageUtil.show(
                    errMsg as string,
                    () => dispatch(externalUsersActions.resetUpdateExternalUser()),
                    '',
                    ToastVariant.ERROR
                );
            } else if (addUserErrors && addUserErrors?.length) {
                const errMsg = addUserErrors || '';
                ToastMessageUtil.show(
                    errMsg as string,
                    () => dispatch(externalUsersActions.resetAddExternalUser()),
                    '',
                    ToastVariant.ERROR
                );
            } else {
                ToastMessageUtil.show(
                    strings.SOMETHING_WENT_WRONG,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
        }
    }, [addUserDataStatus, updateUserDataStatus]);

    useEffect(() => {
        fetchExternalUsersList();
    }, [currentPage]);

    const fetchExternalUsersList = () => {
        dispatch(
            externalUsersActions.fetchExternalUsersListBegin({
                ...currentPage,
                searchQuery: searchQuery
            })
        );
    };

    const handleChange = (value) => {
        setSearchQuery(value);
    };

    const handleSearch = () => {
        setSearchActive(true);
        handlePageChange(1); //redirecting user to first page when search
    };

    const handleClearSearch = () => {
        setSearchActive(false);
        setSearchQuery('');
        handlePageChange(1);
    };

    const handleSort = (value: string) => {
        const res = AppUtil.getSortOrderAndColumn(value);

        if (res) {
            setCurrentPage((prevState) => ({
                ...prevState,
                sortValue: value,
                sortOrder: res.sortOrder,
                sortColumn: res.columnName
            }));
        }
    };

    const handleActions = (action: string, data: ExternalUsersDetails) => {
        setSelectedRowData(data);
        switch (action) {
            case 'edit': {
                setShowAddOrEditModal(true);
                break;
            }
            case 'delete': {
                setShowDeleteModal(true);
                break;
            }
            default:
                break;
        }
    };

    const handleEnterPressed = () => {
        handleSearch();
    };

    const handleBtnClick = (btnType) => {
        switch (btnType) {
            case 'inviteUser': {
                setShowAddOrEditModal(true);
                break;
            }
            default:
                break;
        }
    };

    const handleAddEditModalClose = () => {
        setShowAddOrEditModal(false);
        setSelectedRowData(undefined);
    };

    const handleDeleteUserCancel = () => {
        setShowDeleteModal(false);
        setSelectedRowData(undefined);
    };

    const handlePageChange = (pageNum) => {
        setCurrentPage((prevState) => ({
            ...prevState,
            pageNum: pageNum
        }));
    };

    const onPageSizeChange = (pageSize) => {
        setCurrentPage((prevState) => ({
            ...prevState,
            pageNum: 1,
            pageSize: pageSize
        }));
    };

    return (
        <div className="external-users-container">
            <div className="external-users-container__header">
                <div className="external-users-container__header--left">
                    <p className="external-users-container__header--back-btn" onClick={backToUsers}>
                        <img src={MEDIA_COLLECTION.IC_BACK_ARROW} />
                        <span>{strings.USERS.BACK}</span>
                    </p>
                    <h2>{strings.EXTERNAL_USERS.TITLE}</h2>
                </div>

                <div className="external-users-container__header--action-btn">
                    <Button
                        variant="secondary"
                        startIcon={MEDIA_COLLECTION.IC_ADD}
                        onClick={() => handleBtnClick('inviteUser')}>
                        {strings.EXTERNAL_USERS.INVITE_USER}
                    </Button>
                </div>
            </div>

            <div className="external-users-container__body">
                <div className="search-form-container">
                    <Input
                        id="searchQuery"
                        placeHolder={strings.USERS.SEARCH}
                        value={searchQuery}
                        startIcon={MEDIA_COLLECTION.IC_SEARCH}
                        onChange={(e) => handleChange(e.target.value)}
                        onClear={() => handleChange('')}
                        onEnterPressed={handleEnterPressed}
                        showClearBtn
                    />

                    <Button className="search-btn" variant="primary" onClick={handleSearch}>
                        {strings.USERS.SEARCH}
                    </Button>
                </div>

                {isSearchActive && (
                    <div className="search-text__container">
                        <p>{`${strings.SEARCH_RESULTS} (${usersListData.totalResults})`}</p>
                        <Button
                            variant="primary"
                            className="search-text__container--clear"
                            startIcon={MEDIA_COLLECTION.IC_CLOSE}
                            onClick={handleClearSearch}>
                            {strings.CLEAR_SEARCH}
                        </Button>
                    </div>
                )}

                {usersListData.items.length == 0 && usersListStatus === ApiStatus.LOADING ? (
                    <div className="search-loader">
                        <p className="search-content">{strings.LOADING}</p>
                        <Scenery />
                    </div>
                ) : usersListStatus === ApiStatus.SUCCESS && usersListData.items.length == 0 ? (
                    <p className="external-users-container__body--no-data">
                        {strings.USERS.NO_DATA_FOUND}
                    </p>
                ) : (
                    <CustomTable
                        className="external-users-table"
                        tableHeaderClass="table-head"
                        rowData={usersListData.items}
                        enableSort={true}
                        handleSort={handleSort}
                        sortValue={currentPage.sortValue}
                        showLoader={usersListStatus === ApiStatus.LOADING}>
                        <TableColumn
                            headerName={strings.EXTERNAL_USERS_TABLE.EMAIL}
                            field="email"
                        />
                        <TableColumn
                            headerName={strings.EXTERNAL_USERS_TABLE.ROLE}
                            field="roleCode"
                            sortable={false}
                            renderer={(value: ExternalUsersDetails) => {
                                return (
                                    userRoleOptions.find((item) => item.id === value.roleCode)
                                        ?.name || ''
                                );
                            }}
                        />
                        <TableColumn
                            headerName={strings.EXTERNAL_USERS_TABLE.ISACCEPTED}
                            field="isAccepted"
                            sortable={false}
                            renderer={(value: ExternalUsersDetails) => {
                                const transformedCellData = value?.isAccepted ? 'Yes' : 'No';
                                return (
                                    <LegendDot
                                        cellData={transformedCellData}
                                        legendBasedOn={LEGEND_BASED_ON.YesOrNo}
                                    />
                                );
                            }}
                        />
                        <TableColumn
                            headerName={strings.EXTERNAL_USERS_TABLE.STATUS}
                            field="isActive"
                            sortable={false}
                            renderer={(value: ExternalUsersDetails) => {
                                const transformedCellData = value?.isActive ? 'Active' : 'Inactive';
                                return (
                                    <LegendDot
                                        cellData={transformedCellData}
                                        legendBasedOn={LEGEND_BASED_ON.ActiveInactive}
                                    />
                                );
                            }}
                        />
                        <TableColumn
                            headerClassName="edit-action"
                            className="edit-action"
                            headerName={''}
                            field=""
                            sortable={false}
                            cssStyles={{ flex: 0.5 }}
                            renderer={(value: ExternalUsersDetails) => {
                                return (
                                    <>
                                        <div className="action-container">
                                            <img
                                                className={`action-container__icon-delete ${
                                                    !value.isActive ? 'disabled' : ''
                                                }`}
                                                src={MEDIA_COLLECTION.IC_DELETE}
                                                aria-hidden="true"
                                                onClick={
                                                    value.isActive
                                                        ? () => handleActions?.('delete', value)
                                                        : () => false
                                                }
                                            />

                                            <img
                                                className="action-container__icon-edit"
                                                src={MEDIA_COLLECTION.IC_EDIT}
                                                aria-hidden="true"
                                                onClick={() => handleActions?.('edit', value)}
                                            />
                                        </div>
                                    </>
                                );
                            }}
                        />
                    </CustomTable>
                )}
            </div>

            {usersListData.totalResults ? (
                <Pagination
                    totalRows={usersListData.totalResults}
                    currentPageNum={currentPage.pageNum}
                    onPageChange={handlePageChange}
                    pageSize={currentPage.pageSize}
                    onPageSizeChange={onPageSizeChange}
                />
            ) : null}

            {showAddOrEditModal ? (
                <ExternalUserAddOrEditModal
                    selectedRow={selectedRowData}
                    userRoleOptions={userRoleOptions}
                    onCancel={handleAddEditModalClose}
                />
            ) : (
                ''
            )}

            {showDeleteModal ? (
                <ConfirmationModal
                    selectedRow={selectedRowData}
                    onCancel={handleDeleteUserCancel}
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default AddExternalUsers;
