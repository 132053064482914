import React, { useEffect } from 'react';
import {
    Accordion,
    Button,
    Loader,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from 'common/components';
import { strings } from 'common/constants/app-constants';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { usersActions } from 'modules/CommonModules/Users/redux';
import { ApiStatus } from 'common/enums';
import { UsersDetails, viewProgressLessonObjects } from 'modules/CommonModules/Users/models';
import { MEDIA_COLLECTION } from 'common/configs';
import { dateUtil } from 'common/utils';
import './ViewProgressModal.scss';

type ViewProgressModalProps = {
    currentUserRole: string;
    selectedRow: UsersDetails;
    onCancel: () => void;
};

const ViewProgressModal: React.FC<ViewProgressModalProps> = ({
    currentUserRole,
    selectedRow,
    onCancel
}) => {
    const dispatch = useAppDispatch();

    const { data: viewProgressData, status: viewProgressStatus } = useAppSelector(
        (state) => state.users.viewProgress
    );

    useEffect(() => {
        dispatch(
            usersActions.viewProgressBegin({
                userGuid: selectedRow.id,
                currentUserType: currentUserRole
            })
        );
    }, []);

    const isAnyLessonInProgressOrCompleted = (lesson: viewProgressLessonObjects[]) => {
        return lesson?.some((lesson) => lesson.isStarted || lesson.isCompleted);
    };

    const getAccordionTitle = (
        title: string,
        isCompleted: boolean | null,
        lesson: viewProgressLessonObjects[]
    ) => {
        return (
            <div className="accordion-title">
                <span className="accordion-title__image-wrapper">
                    <img
                        src={
                            isCompleted
                                ? MEDIA_COLLECTION.IC_LEARNING_COMPLETED_24PX
                                : isAnyLessonInProgressOrCompleted(lesson)
                                ? MEDIA_COLLECTION.IC_LEARNING_PROGRESSING_24PX
                                : MEDIA_COLLECTION.IC_LEARNING_NOT_STARTED_24PX
                        }
                    />
                </span>
                <span>{title}</span>
            </div>
        );
    };

    const getAccordionContent = (lessonObjects: viewProgressLessonObjects[]) => {
        return lessonObjects?.map((lessonObjects, index) => (
            <div key={index} className="accordion-content-wrapper--row">
                <span className="accordion-content-wrapper--row__image-wrapper">
                    <img
                        src={
                            lessonObjects.isCompleted
                                ? MEDIA_COLLECTION.IC_LEARNING_COMPLETED_24PX
                                : lessonObjects.isStarted
                                ? MEDIA_COLLECTION.IC_LEARNING_PROGRESSING_24PX
                                : MEDIA_COLLECTION.IC_LEARNING_NOT_STARTED_24PX
                        }
                    />
                </span>
                <span>{lessonObjects.name}</span>
            </div>
        ));
    };

    return (
        <div>
            <Modal className="view-progress-modal" show size="lg" onClose={onCancel}>
                <ModalHeader>
                    <div className="view-progress-modal__title">
                        {strings.USERS.VIEW_PROGRESS_MODAL.TITLE}
                    </div>
                </ModalHeader>

                <ModalBody>
                    <div className="view-progress-modal__body">
                        {viewProgressStatus === ApiStatus.SUCCESS ? (
                            <>
                                <div className="view-progress-modal__body--user-info">
                                    <p className="personal-info">
                                        <span className="personal-info__name">
                                            {viewProgressData?.firstName}{' '}
                                            {viewProgressData?.lastName}
                                        </span>

                                        {viewProgressData?.m1Number ? (
                                            <span className="personal-info__m1-number">
                                                ({viewProgressData?.m1Number})
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                    <p className="date-info">
                                        <span className="date-info__label">
                                            {strings.USERS.VIEW_PROGRESS_MODAL.ENDORSEMENT_DATE}
                                        </span>
                                        <span className="date-info__date">
                                            {viewProgressData?.endorsedDate
                                                ? dateUtil.convertTimeToDDMMMYYYY(
                                                      viewProgressData?.endorsedDate
                                                  )
                                                : 'N/A'}
                                        </span>
                                    </p>

                                    <p className="date-info">
                                        <span className="date-info__label">
                                            {strings.USERS.VIEW_PROGRESS_MODAL.RENEWAL_DATE}
                                        </span>
                                        <span className="date-info__date">
                                            {viewProgressData?.renewalDate
                                                ? dateUtil.convertTimeToDDMMMYYYY(
                                                      viewProgressData?.renewalDate
                                                  )
                                                : 'N/A'}
                                        </span>
                                    </p>
                                </div>

                                {viewProgressData.lessons?.length ? (
                                    <>
                                        <div className="view-progress-modal__body--progress-wrapper">
                                            {viewProgressData.lessons?.map((lesson, index) => {
                                                return (
                                                    <Accordion
                                                        key={index}
                                                        title={getAccordionTitle(
                                                            lesson.name,
                                                            lesson.isCompleted,
                                                            lesson.lessonObjects
                                                        )}
                                                        content={getAccordionContent(
                                                            lesson.lessonObjects
                                                        )}
                                                        contentClassName="accordion-content-wrapper"
                                                    />
                                                );
                                            })}
                                        </div>

                                        <div className="view-progress-modal__body--progress-legend">
                                            <div className="legend-wrapper">
                                                <span>
                                                    <img
                                                        src={
                                                            MEDIA_COLLECTION.IC_LEARNING_NOT_STARTED_18PX
                                                        }
                                                    />
                                                </span>
                                                <span>
                                                    {strings.USERS.VIEW_PROGRESS_MODAL.NOT_STARTED}
                                                </span>
                                            </div>
                                            <div className="legend-wrapper">
                                                <span>
                                                    <img
                                                        src={
                                                            MEDIA_COLLECTION.IC_LEARNING_PROGRESSING_18PX
                                                        }
                                                    />
                                                </span>
                                                <span>
                                                    {strings.USERS.VIEW_PROGRESS_MODAL.PROGRESSING}
                                                </span>
                                            </div>

                                            <div className="legend-wrapper">
                                                <span>
                                                    <img
                                                        src={
                                                            MEDIA_COLLECTION.IC_LEARNING_COMPLETED_18PX
                                                        }
                                                    />
                                                </span>
                                                <span>
                                                    {strings.USERS.VIEW_PROGRESS_MODAL.COMPLETED}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <p className="view-progress-modal__body--no-data">
                                        {strings.USERS.VIEW_PROGRESS_MODAL.NO_LESSONS_FOUND}
                                    </p>
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button className="btn-cancel" variant="secondary" onClick={onCancel}>
                        {strings.CLOSE}
                    </Button>
                </ModalFooter>

                <Loader
                    className="view-progress-loader"
                    show={viewProgressStatus === ApiStatus.LOADING}
                />
            </Modal>
        </div>
    );
};

export default ViewProgressModal;
