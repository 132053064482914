import { UPLOAD_SECTION } from 'common/enums';

export const UPLOAD_FILE_CONFIG = {
    [UPLOAD_SECTION.EndorsementZip]: {
        validFileTypes: ['application/zip', 'application/x-zip-compressed'],
        accept: {
            'application/zip': ['.zip']
        },
        maxFileSize: 20971520 // 20MB in bytes
    },
    [UPLOAD_SECTION.EndorsementCertificate]: {
        validFileTypes: ['image/jpeg'],
        accept: {
            'image/jpeg': ['.jpg']
        },
        maxFileSize: 2097152 // 2MB in bytes
    },
    [UPLOAD_SECTION.AssessmentCertificate]: {
        validFileTypes: ['image/jpeg'],
        accept: {
            'image/jpeg': ['.jpg']
        },
        maxFileSize: 2097152 // 2MB in bytes
    }
};
