import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'common/configs';
import { Reports } from 'modules/CommonModules';

const AssociationAdminLayout = () => {
    return (
        <Routes>
            <Route path={ROUTES.ASSOCIATION_ADMIN.REPORTS} element={<Reports />} />

            <Route
                path={`${ROUTES.DEFAULT}${ROUTES.WILD}`}
                element={<Navigate replace to={ROUTES.ASSOCIATION_ADMIN.REPORTS} />}
            />
        </Routes>
    );
};

export default AssociationAdminLayout;
