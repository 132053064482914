import React, { FC } from 'react';
import { MEDIA_COLLECTION } from 'common/configs';
import './Modal.scss';

type ModalProps = {
    className?: string;
    show: boolean;
    size: 'sm' | 'md' | 'lg';
    onClose: () => void;
    children: React.ReactNode;
};

const Modal: FC<ModalProps> = ({ className, show, size, onClose, children }) => {
    if (!show) return null;

    return (
        <div className={`modal ${className}`}>
            <div className={`modal-content modal-${size}`}>
                {children}
                <button className="modal-close" onClick={onClose}>
                    <img src={MEDIA_COLLECTION.IC_CLOSE_BLACK} alt="close-icon" />
                </button>
            </div>
            <div className="modal-overlay" onClick={onClose} />
        </div>
    );
};

export default Modal;
