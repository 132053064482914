import { SORT_OPTIONS } from 'common/enums';

const getSortOrderAndColumn = (value: string) => {
    if (value.startsWith('-')) {
        return {
            sortOrder: SORT_OPTIONS.desc,
            columnName: value.substring(1)
        };
    } else if (value.startsWith('=')) {
        return {
            sortOrder: '',
            columnName: '' //value.substring(1)
        };
    } else if (value) {
        return {
            sortOrder: SORT_OPTIONS.asc,
            columnName: value
        };
    }
};

const getBrowserName = () => {
    const test = (regexp) => {
        return regexp.test(navigator.userAgent);
    };

    if (test(/opr\//i)) {
        return 'Opera';
    } else if (test(/edg/i)) {
        return 'Microsoft Edge';
    } else if (test(/chrome|chromium|crios/i)) {
        return 'Google Chrome';
    } else if (test(/firefox|fxios/i)) {
        return 'Mozilla Firefox';
    } else if (test(/safari/i)) {
        return 'Apple Safari';
    } else if (test(/trident/i)) {
        return 'Microsoft Internet Explorer';
    } else if (test(/ucbrowser/i)) {
        return 'UC Browser';
    } else if (test(/samsungbrowser/i)) {
        return 'Samsung Browser';
    } else {
        return 'Unknown browser';
    }
};

export default {
    getSortOrderAndColumn,
    getBrowserName
};
