import './DrawerToggle.scss';

type DrawerToggleProps = {
    show: boolean;
    isOpen: boolean;
    openMenuDrawer: () => void;
    closeMenuDrawer: () => void;
};

const DrawerToggle = ({ show, isOpen, openMenuDrawer, closeMenuDrawer }: DrawerToggleProps) => {
    return show ? (
        <>
            {isOpen ? (
                <div className="drawer-toggle--close" onClick={closeMenuDrawer}></div>
            ) : (
                <div className="drawer-toggle--open" onClick={openMenuDrawer}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            )}
        </>
    ) : (
        <></>
    );
};

export default DrawerToggle;
