import { useState, useEffect, useRef } from 'react';
import { SelectOption } from 'common/models';
import { useDebounce, useOnClickOutside } from 'common/hooks';
import { strings } from 'common/constants/app-constants';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { reportActions } from 'modules/CommonModules/redux';
import { MEDIA_COLLECTION, PRIMARY_ASSOCIATION_PAGE_SIZE } from 'common/configs';
import { ApiStatus } from 'common/enums';
import Loader from '../Loader/Loader';
import './PrimaryAssociationSelect.scss';

type PrimaryAssociationSelectProps = {
    id?: string;
    className?: string;
    placeHolder: string;
    value?: SelectOption;
    onChange: (selectedOption: SelectOption) => void;
};

const PrimaryAssociationSelect = ({
    id,
    className,
    placeHolder = 'Select',
    value,
    onChange
}: PrimaryAssociationSelectProps) => {
    const dispatch = useAppDispatch();
    const optionsRef: any = useRef(null);

    const { data: primaryAssociationData, status: primaryAssociationStatus } = useAppSelector(
        (state) => state.reports.primaryAssociation
    );

    // to prepend All option to the list
    const selectAllOption = {
        id: 'All',
        name: 'All'
    };
    const intialSelectedOption = { id: '', name: '' };
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<SelectOption>(intialSelectedOption);
    const [options, setOptions] = useState<SelectOption[]>([]);
    const [searchedOptions, setSearchedOptions] = useState<SelectOption[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 400);
    const [nextOptions, setNextOptions] = useState<SelectOption[]>([]);

    const [currentPage, setCurrentPage] = useState({
        pageNum: 1,
        pageSize: PRIMARY_ASSOCIATION_PAGE_SIZE,
        sortValue: '',
        sortOrder: '',
        sortColumn: '',
        searchQuery: ''
    });

    useEffect(() => {
        return () => {
            dispatch(reportActions.resetPrimaryAssociationOptions());
            setOptions([]);
            setSearchedOptions([]);
        };
    }, []);

    useEffect(() => {
        dispatch(
            reportActions.fetchPrimaryAssociationOptionsBegin({
                ...currentPage
            })
        );
    }, [currentPage]);

    useEffect(() => {
        setCurrentPage((prevState) => ({
            ...prevState,
            searchQuery: debouncedSearchTerm as string,
            pageNum: 1
        }));

        // clearing exising options list
        setSearchedOptions([]);
        setOptions([]);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const nextOptions = primaryAssociationData.items?.map((item) => ({
            id: item.guidCode,
            name: item.name
        }));
        setNextOptions(nextOptions);
    }, [primaryAssociationData.items]);

    useEffect(() => {
        if (currentPage.searchQuery) {
            // when no data found for searched keyword
            if (!nextOptions.length) setSearchedOptions([]);
            setSearchedOptions((prevState) => [...prevState, ...nextOptions]);
        } else {
            const uniqueArray = [selectAllOption, ...options, ...nextOptions].filter(
                (obj, index, self) => index === self.findIndex((o) => o.id === obj.id)
            );
            setOptions(uniqueArray);
        }
    }, [nextOptions]);

    useEffect(() => {
        if (value?.id && value.name) {
            setSelectedOption({ id: value?.id, name: value.name });
        } else {
            setSelectedOption(intialSelectedOption);
        }
    }, [value]);

    const optionsList = currentPage.searchQuery ? searchedOptions : options;

    useOnClickOutside(optionsRef, (e) => {
        setIsOpen(false);
        setSearchTerm('');
    });

    const handleOptionClick = (option: SelectOption) => {
        setSelectedOption(option);
        onChange(option);
        setIsOpen(false);
        setSearchTerm('');
    };

    const onScroll = (event: any) => {
        const target = event?.target;
        if (target) {
            if (
                target?.scrollHeight &&
                target?.scrollTop &&
                target.clientHeight &&
                target?.scrollHeight - target?.scrollTop - 20 < target.clientHeight &&
                primaryAssociationStatus !== ApiStatus.LOADING &&
                optionsList.length < primaryAssociationData.totalResults
            ) {
                setCurrentPage((prevState) => ({
                    ...prevState,
                    pageNum: prevState.pageNum + 1
                }));
            }
        }
    };

    const handleSearchChange = (evt) => {
        setSearchTerm(evt.target.value);
    };

    return (
        <div id={id} className={`infinite-scroll-select ${className}`} ref={optionsRef}>
            <div
                className={`infinite-scroll-select__header ${
                    isOpen ? 'infinite-scroll-select__header--open' : ''
                }`}
                onClick={() => setIsOpen(!isOpen)}>
                <div
                    className={`${
                        selectedOption.name ? 'infinite-scroll-select__header--has-value' : ''
                    }`}>
                    <p
                        className={`custom-select__placeholder-text ${
                            isOpen ? 'custom-select__placeholder-text--open' : ''
                        }`}>
                        {placeHolder ?? ''}
                    </p>
                    <p className="custom-select__selected-option">{selectedOption.name ?? ''}</p>
                </div>
                <div
                    className={`infinite-scroll-select__arrow ${
                        isOpen ? 'infinite-scroll-select__arrow--open' : ''
                    }`}></div>
            </div>
            {isOpen && (
                <div className="infinite-scroll-select__wrapper">
                    <div className="infinite-scroll-select__search-wrapper">
                        <input
                            id=""
                            className="infinite-scroll-select__search"
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        {searchTerm && (
                            <div
                                className="infinite-scroll-select__search--clear"
                                onClick={() => setSearchTerm('')}>
                                <img
                                    className="input-field__icon"
                                    src={MEDIA_COLLECTION.IC_CLEAR}
                                    alt="end-icon"
                                />
                            </div>
                        )}
                    </div>
                    <ul className="infinite-scroll-select__options" onScroll={onScroll}>
                        {optionsList?.length
                            ? optionsList.map((option, index) => (
                                  <li
                                      key={index}
                                      className={`${
                                          option.name === selectedOption.name
                                              ? 'infinite-scroll-select__option--selected'
                                              : ''
                                      }`}
                                      onClick={() => handleOptionClick(option)}>
                                      {option.name}
                                  </li>
                              ))
                            : primaryAssociationStatus !== ApiStatus.LOADING && (
                                  <li>{strings.NO_DATA_FOUND}</li>
                              )}

                        <Loader
                            show={primaryAssociationStatus === ApiStatus.LOADING}
                            className="infinite-scroll-select__loader"
                        />
                    </ul>
                </div>
            )}
        </div>
    );
};

export default PrimaryAssociationSelect;
