import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiStatus } from 'common/enums';
import { initialExternalUsersListState } from '../states/external-users.state';
import {
    AddExternalUserErrorResponse,
    AddExternalUserRequest,
    AddExternalUserResponse,
    DeleteExternalUserRequest,
    DeleteExternalUserResponse,
    ExternalUsersListRequest,
    ExternalUsersListResponse,
    initialAddExternalUser,
    initialDeleteExternalUser,
    initialExternalUsersList,
    initialUpdateExternalUser,
    UpdateExternalUserErrorResponse,
    UpdateExternalUserRequest,
    UpdateExternalUserResponse
} from 'modules/CommonModules/Users/models';

const externalUsersSlice = createSlice({
    name: 'externalUsers',
    initialState: initialExternalUsersListState,
    reducers: {
        fetchExternalUsersListBegin: (
            state,
            { payload }: PayloadAction<ExternalUsersListRequest>
        ) => {
            state.list.status = ApiStatus.LOADING;
        },
        fetchExternalUsersListSuccess: (
            state,
            { payload }: PayloadAction<ExternalUsersListResponse>
        ) => {
            state.list.status = ApiStatus.SUCCESS;
            state.list.data = payload;
        },
        fetchExternalUsersListError: (state) => {
            state.list.status = ApiStatus.ERROR;
        },
        resetExternalUsers: (state) => {
            state.list.status = ApiStatus.NONE;
            state.list.data = initialExternalUsersList;
        },

        addExternalUserBegin: (state, { payload }: PayloadAction<AddExternalUserRequest>) => {
            state.add.status = ApiStatus.LOADING;
        },
        addExternalUserSuccess: (state, { payload }: PayloadAction<AddExternalUserResponse>) => {
            state.add.status = ApiStatus.SUCCESS;
            state.add.data = payload;
        },
        addExternalUserError: (state, { payload }: PayloadAction<AddExternalUserErrorResponse>) => {
            state.add.status = ApiStatus.ERROR;
            state.add.errors = payload.errors;
        },
        resetAddExternalUser: (state) => {
            state.add.status = ApiStatus.NONE;
            state.add.data = initialAddExternalUser;
        },

        updateExternalUserBegin: (state, { payload }: PayloadAction<UpdateExternalUserRequest>) => {
            state.update.status = ApiStatus.LOADING;
        },
        updateExternalUserSuccess: (
            state,
            { payload }: PayloadAction<UpdateExternalUserResponse>
        ) => {
            state.update.status = ApiStatus.SUCCESS;
            state.update.data = payload;
        },
        updateExternalUserError: (
            state,
            { payload }: PayloadAction<UpdateExternalUserErrorResponse>
        ) => {
            state.update.status = ApiStatus.ERROR;
            state.update.errors = payload.errors;
        },
        resetUpdateExternalUser: (state) => {
            state.update.status = ApiStatus.NONE;
            state.update.data = initialUpdateExternalUser;
        },

        deleteExternalUserBegin: (state, { payload }: PayloadAction<DeleteExternalUserRequest>) => {
            state.delete.status = ApiStatus.LOADING;
        },
        deleteExternalUserSuccess: (
            state,
            { payload }: PayloadAction<DeleteExternalUserResponse>
        ) => {
            state.delete.status = ApiStatus.SUCCESS;
            state.delete.data = payload;
        },
        deleteExternalUserError: (state) => {
            state.delete.status = ApiStatus.ERROR;
        },
        resetDeleteExternalUser: (state) => {
            state.delete.status = ApiStatus.NONE;
            state.delete.data = initialDeleteExternalUser;
        }
    }
});

export default externalUsersSlice.reducer;
export const externalUsersActions = externalUsersSlice.actions;
