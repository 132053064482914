import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'common/redux/core';
import { strings } from 'common/constants/app-constants';
import {
    DEFAULT_PAGE_SIZE,
    PAGINATION_DEBOUNCE_TIME,
    MEDIA_COLLECTION,
    POLLING_CONFIG
} from 'common/configs';
import { AppUtil, dateUtil } from 'common/utils';
import { ApiStatus, REPORT_GENERATION_STATUS, ToastVariant, UserRoleMapper } from 'common/enums';
import {
    CustomTable,
    LegendDot,
    Pagination,
    TableColumn,
    Button,
    AppDatePicker,
    Input,
    Scenery,
    PrimaryAssociationSelect,
    DownloadLoader
} from 'common/components';
import { LEGEND_BASED_ON } from 'modules/CommonModules/Users/enums';
import { usersActions } from 'modules/CommonModules/Users/redux';
import EditUserModal from './Components/EditUserModal/EditUserModal';
import { ToastMessageUtil } from 'common/services';
import { SelectOption } from 'common/models';
import { UsersDetails } from '../../models';
import { GenerateReportRequestBody } from 'modules/AdminDashboard/models';
import AddExternalUsers from '../AddExternalUsers/AddExternalUsers';
import { useDebounce } from 'common/hooks';
import './Users.scss';

let reportStatusTimerId: any = undefined;

const Users = () => {
    const dispatch = useAppDispatch();
    const { data: usersListData, status: usersListStatus } = useAppSelector(
        (state) => state.users.list
    );
    const { data: editUserData, status: editUserDataStatus } = useAppSelector(
        (state) => state.users.edit
    );
    const { data: wipeProgressData, status: wipeProgressStatus } = useAppSelector(
        (state) => state.users.wipeProgress
    );
    const { data: forceEndorsementData, status: forceEndorsementStatus } = useAppSelector(
        (state) => state.users.forceEndorsement
    );
    const {
        data: syncUserData,
        status: syncUserDataStatus,
        errors: syncUserErrors
    } = useAppSelector((state) => state.users.syncUserData);
    const { data: userProfile } = useAppSelector((state) => state.userProfile.profileDetails);

    const { data: generateReportData, status: generateReportStatus } = useAppSelector(
        (state) => state.users.generateReport
    );
    const { data: fetchedReportGenInfo, status: fetchedReportGenStatus } = useAppSelector(
        (state) => state.users.generationStatusInfo
    );
    const { status: downloadReportStatus, errors: downloadReportErrors } = useAppSelector(
        (state) => state.users.download
    );

    const initialFormData = {
        searchQuery: '',
        endorsedDateFrom: '',
        endorsedDateTo: '',
        selectedAssociation: {
            id: 'All',
            name: 'All'
        }
    };

    const [formData, setFormData] = useState(initialFormData);
    const [currentPageNum, setCurrentPageNum] = useState<number>(1);
    const debouncedPageNum = useDebounce(currentPageNum, PAGINATION_DEBOUNCE_TIME) as number;
    const [isGeneratingReport, setGeneratingReport] = useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState({
        debouncedPageNum: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        sortValue: '',
        sortOrder: '',
        sortColumn: ''
    });
    const [isSearchActive, setSearchActive] = useState<boolean>(false);
    const [showAddExternalUser, setShowAddExternalUser] = useState<boolean>(false);
    const [showEditUser, setShowEditUser] = useState<boolean>(false);
    const [selectedRowData, setSelectedRowData] = useState({});

    const shouldDisableDownloadBtn =
        fetchedReportGenInfo.status !== REPORT_GENERATION_STATUS.Completed || isGeneratingReport;
    const shouldDisableGenerateBtn =
        fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.InProgress || isGeneratingReport;

    useEffect(() => {
        dispatch(
            usersActions.fetchReportGenerationStatusBegin({
                reportType: 'User'
            })
        );

        if (userProfile.role === 'NAR Administrator' || userProfile.role === 'Broker Manager') {
            dispatch(
                usersActions.fetchUserRoles({
                    currentUserType: userProfile.role
                })
            );
        }

        return () => {
            setGeneratingReport(false);
            stopPolling();
            dispatch(usersActions.resetGenerateReport());
        };
    }, []);

    useEffect(() => {
        if (
            editUserDataStatus === ApiStatus.SUCCESS ||
            wipeProgressStatus === ApiStatus.SUCCESS ||
            forceEndorsementStatus === ApiStatus.SUCCESS ||
            syncUserDataStatus === ApiStatus.SUCCESS
        ) {
            if (editUserData.isSuccess) {
                ToastMessageUtil.show(strings.USERS.EDIT_USER_MODAL.UPDATE_SUCCESS, () =>
                    dispatch(usersActions.resetEditUser())
                );
            } else if (wipeProgressData.isSuccess) {
                ToastMessageUtil.show(strings.USERS.EDIT_USER_MODAL.WIPE_PROGRESS_SUCCESS, () =>
                    dispatch(usersActions.resetWipeProgress())
                );
            } else if (forceEndorsementData.isSuccess) {
                ToastMessageUtil.show(strings.USERS.EDIT_USER_MODAL.FORCE_ENDORSED_SUCCESS, () =>
                    dispatch(usersActions.resetForceEndorsement())
                );
            } else if (syncUserData.isSuccess) {
                ToastMessageUtil.show(strings.USERS.EDIT_USER_MODAL.SYNC_WITH_MMP_STARTED, () =>
                    dispatch(usersActions.resetForceEndorsement())
                );
            } else {
                ToastMessageUtil.show(
                    strings.SOMETHING_WENT_WRONG,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
            // refetch users list
            fetchUsers();
        }
    }, [editUserDataStatus, wipeProgressStatus, forceEndorsementStatus, syncUserDataStatus]);

    useEffect(() => {
        if (syncUserDataStatus === ApiStatus.ERROR) {
            if (syncUserErrors && syncUserErrors?.length) {
                const errMsg = syncUserErrors || '';
                ToastMessageUtil.show(
                    errMsg as string,
                    () => dispatch(usersActions.resetSyncUserData()),
                    '',
                    ToastVariant.ERROR
                );
            } else {
                ToastMessageUtil.show(
                    strings.SOMETHING_WENT_WRONG,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
        }
    }, [syncUserDataStatus]);

    useEffect(() => {
        if (debouncedPageNum != currentPage.debouncedPageNum) {
            setCurrentPage((prevState) => ({
                ...prevState,
                debouncedPageNum: debouncedPageNum
            }));
        }
    }, [debouncedPageNum]);

    useEffect(() => {
        fetchUsers();
    }, [currentPage]);

    useEffect(() => {
        if (fetchedReportGenStatus === ApiStatus.SUCCESS) {
            if (fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.InProgress) {
                setGeneratingReport(true);
                startPolling();
            } else if (fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.Completed) {
                setGeneratingReport(false);
                stopPolling();
            } else if (
                fetchedReportGenInfo.status === REPORT_GENERATION_STATUS.Failed &&
                isGeneratingReport
            ) {
                setGeneratingReport(false);
                stopPolling();
                ToastMessageUtil.show(
                    strings.REPORTS.GENERATION_FAILED,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
        }
    }, [fetchedReportGenStatus]);

    // once generate report api is success, then start polling to fetch the report status
    useEffect(() => {
        if (generateReportStatus === ApiStatus.SUCCESS && generateReportData.isSuccess) {
            setGeneratingReport(true);
            startPolling();
        } else if (generateReportStatus === ApiStatus.ERROR) {
            stopPolling();
            setGeneratingReport(false);
            ToastMessageUtil.show(
                strings.SOMETHING_WENT_WRONG,
                () => dispatch(usersActions.resetGenerateReport()),
                '',
                ToastVariant.ERROR
            );
        }
    }, [generateReportStatus]);

    useEffect(() => {
        if (downloadReportStatus === ApiStatus.ERROR) {
            if (downloadReportErrors && downloadReportErrors?.length) {
                const errMsg = downloadReportErrors || '';
                ToastMessageUtil.show(
                    errMsg as string,
                    () => dispatch(usersActions.resetdownloadCSV()),
                    '',
                    ToastVariant.ERROR
                );
            } else {
                ToastMessageUtil.show(
                    strings.SOMETHING_WENT_WRONG,
                    undefined,
                    '',
                    ToastVariant.ERROR
                );
            }
        }
    }, [downloadReportStatus]);

    const startPolling = () => {
        stopPolling();

        reportStatusTimerId = setInterval(
            () =>
                dispatch(
                    usersActions.fetchReportGenerationStatusBegin({
                        reportType: 'User'
                    })
                ),
            POLLING_CONFIG.FETCH_REPORT_GEN_STATUS
        );
    };

    const stopPolling = () => {
        if (reportStatusTimerId) {
            clearInterval(reportStatusTimerId);
            reportStatusTimerId = undefined;
        }
    };

    const fetchUsers = () => {
        dispatch(
            usersActions.fetchUsersListBegin({
                ...currentPage,
                searchQuery: formData.searchQuery,
                pageNum: currentPage.debouncedPageNum,
                pageSize: currentPage.pageSize,
                sortOrder: currentPage.sortOrder,
                sortColumn: currentPage.sortColumn,
                endorsedDateFrom: formData.endorsedDateFrom
                    ? dateUtil.formatDateToYYYYMMDD(formData.endorsedDateFrom)
                    : '',
                endorsedDateTo: formData.endorsedDateTo
                    ? dateUtil.formatDateToYYYYMMDD(formData.endorsedDateTo)
                    : '',
                primaryAssociationId:
                    formData.selectedAssociation.id === 'All' ? '' : formData.selectedAssociation.id // if All selected then send it as blank, so api will return data for all
            })
        );
    };

    const handleChange = (fieldName: string, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value
        }));
    };

    const handleSearch = () => {
        setSearchActive(true);
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1
        }));
    };

    const handleClearSearch = () => {
        setSearchActive(false);
        setFormData(initialFormData);
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1
        }));
    };

    const handleSort = (value: string) => {
        const res = AppUtil.getSortOrderAndColumn(value);

        if (res) {
            setCurrentPage((prevState) => ({
                ...prevState,
                sortValue: value,
                sortOrder: res.sortOrder,
                sortColumn: res.columnName
            }));
        }
    };

    const handleActions = (action: string, data: UsersDetails) => {
        setSelectedRowData(data);
        switch (action) {
            case 'edit': {
                setShowEditUser(true);
                break;
            }
            default:
                break;
        }
    };

    const handleEnterPressed = () => {
        handleSearch();
    };

    const handleBtnClick = (btnType) => {
        switch (btnType) {
            case 'addExternalUser': {
                setShowAddExternalUser(true);
                break;
            }
            case 'downloadCSV': {
                dispatch(
                    usersActions.downloadCSVBegin({
                        reportType: 'User'
                    })
                );
                break;
            }
            case 'generateCSV': {
                setGeneratingReport(true);

                let requestBody: GenerateReportRequestBody = {
                    searchFilters: null,
                    fromDate: null,
                    toDate: null,
                    primaryAssociationId: null
                };

                if (isSearchActive) {
                    requestBody = {
                        searchFilters: formData.searchQuery || null,
                        fromDate: formData.endorsedDateFrom
                            ? dateUtil.formatDateToYYYYMMDD(formData.endorsedDateFrom)
                            : null,
                        toDate: formData.endorsedDateTo
                            ? dateUtil.formatDateToYYYYMMDD(formData.endorsedDateTo)
                            : null,
                        primaryAssociationId:
                            formData.selectedAssociation.id === 'All'
                                ? null
                                : formData.selectedAssociation.id
                    };
                }

                dispatch(
                    usersActions.generateReportBegin({
                        reportType: 'User',
                        body: { ...requestBody }
                    })
                );
                break;
            }
            default:
                break;
        }
    };

    const handlePageChange = (pageNum) => {
        setCurrentPageNum(pageNum);
    };

    const onPageSizeChange = (pageSize) => {
        setCurrentPageNum(1);
        setCurrentPage((prevState) => ({
            ...prevState,
            debouncedPageNum: 1,
            pageSize: pageSize
        }));
    };

    return (
        <>
            {showAddExternalUser ? (
                <AddExternalUsers backToUsers={() => setShowAddExternalUser(false)} />
            ) : (
                <div className="users-container">
                    <div className="users-container__header">
                        <h2>{strings.USERS.TITLE}</h2>

                        <div className="users-container__header--action-btns">
                            {isGeneratingReport && (
                                <div className="download-csv-status">
                                    <DownloadLoader />
                                    <span className="label">{strings.REPORTS.CREATING_CSV}</span>
                                </div>
                            )}

                            <div className="generate-csv-wrapper">
                                <Button
                                    variant="secondary"
                                    disabled={shouldDisableGenerateBtn}
                                    startIcon={
                                        shouldDisableGenerateBtn
                                            ? MEDIA_COLLECTION.IC_GENERATE_CSV_DISABLED
                                            : MEDIA_COLLECTION.IC_GENERATE_CSV
                                    }
                                    onClick={() => handleBtnClick('generateCSV')}>
                                    {strings.GENERATE_CSV}
                                </Button>

                                {fetchedReportGenInfo.generatedDate ? (
                                    <p className="generated-info">
                                        <span className="generated-info__label">
                                            {strings.REPORTS.LAST_GENERATED}
                                        </span>{' '}
                                        <span className="generated-info__date">
                                            {dateUtil.convertUTCtoLocalTime(
                                                fetchedReportGenInfo.generatedDate
                                            )}
                                        </span>
                                    </p>
                                ) : (
                                    ''
                                )}
                            </div>

                            <Button
                                variant="secondary"
                                disabled={shouldDisableDownloadBtn}
                                startIcon={
                                    shouldDisableDownloadBtn
                                        ? MEDIA_COLLECTION.IC_DOWNLOAD_DISABLED
                                        : MEDIA_COLLECTION.IC_DOWNLOAD
                                }
                                onClick={() => handleBtnClick('downloadCSV')}>
                                {strings.DOWNLOAD_CSV}
                            </Button>

                            {userProfile.role === 'NAR Administrator' ? (
                                <Button
                                    variant="secondary"
                                    startIcon={MEDIA_COLLECTION.IC_USER}
                                    onClick={() => handleBtnClick('addExternalUser')}>
                                    {strings.USERS.ADD_EXTERNAL_USER}
                                </Button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <div className="users-container__body">
                        <div className="search-form-container">
                            <Input
                                id="searchQuery"
                                placeHolder={strings.USERS.SEARCH}
                                value={formData.searchQuery}
                                startIcon={MEDIA_COLLECTION.IC_SEARCH}
                                onChange={(e) => handleChange('searchQuery', e.target.value)}
                                onClear={() => handleChange('searchQuery', '')}
                                onEnterPressed={handleEnterPressed}
                                showClearBtn
                            />
                            <AppDatePicker
                                id="endorsedDateFrom"
                                className="date-input"
                                placeHolder={strings.USERS.ENDORSED_DATE_FROM}
                                value={formData.endorsedDateFrom}
                                onChange={(date) => handleChange('endorsedDateFrom', date)}
                            />
                            <AppDatePicker
                                id="endorsedDateTo"
                                className="date-input"
                                placeHolder={strings.USERS.ENDORSED_DATE_TO}
                                value={formData.endorsedDateTo}
                                onChange={(date) => handleChange('endorsedDateTo', date)}
                            />
                            <PrimaryAssociationSelect
                                key={'users-primary-association'}
                                placeHolder={strings.USERS.PRIMARY_ASSOCIATION}
                                onChange={(selectedOption: SelectOption) =>
                                    handleChange('selectedAssociation', selectedOption)
                                }
                                value={formData.selectedAssociation}
                            />
                            <Button className="search-btn" variant="primary" onClick={handleSearch}>
                                {strings.USERS.SEARCH}
                            </Button>
                        </div>

                        {isSearchActive && (
                            <div className="search-text__container">
                                <p>{`${strings.SEARCH_RESULTS} (${
                                    usersListStatus === ApiStatus.LOADING
                                        ? '-'
                                        : usersListData.totalResults
                                })`}</p>
                                <Button
                                    variant="primary"
                                    className="search-text__container--clear"
                                    startIcon={MEDIA_COLLECTION.IC_CLOSE}
                                    onClick={handleClearSearch}>
                                    {strings.CLEAR_SEARCH}
                                </Button>
                            </div>
                        )}

                        {usersListData.items.length == 0 &&
                        usersListStatus === ApiStatus.LOADING ? (
                            <div className="search-loader">
                                <p className="search-content">{strings.LOADING}</p>
                                <Scenery />
                            </div>
                        ) : usersListStatus === ApiStatus.SUCCESS &&
                          usersListData.items.length == 0 ? (
                            <p className="users-container__body--no-data">
                                {strings.USERS.NO_DATA_FOUND}
                            </p>
                        ) : (
                            <CustomTable
                                className="users-table"
                                tableHeaderClass="table-head"
                                rowData={usersListData.items}
                                enableSort={true}
                                handleSort={handleSort}
                                sortValue={currentPage.sortValue}
                                showLoader={usersListStatus === ApiStatus.LOADING}>
                                <TableColumn
                                    headerName={strings.USERS_TABLE.LAST_NAME}
                                    field="lastName"
                                />
                                <TableColumn
                                    headerName={strings.USERS_TABLE.FIRST_NAME}
                                    field="firstName"
                                />
                                <TableColumn
                                    headerName={strings.USERS_TABLE.EMAIL}
                                    field="email"
                                    cssStyles={{ flex: 1.5 }}
                                />
                                <TableColumn headerName={strings.USERS_TABLE.M1} field="m1Number" />
                                <TableColumn
                                    headerName={strings.USERS_TABLE.BROKERAGE}
                                    field="brokerage"
                                    cssStyles={{ flex: 2 }}
                                />
                                <TableColumn
                                    headerName={strings.USERS_TABLE.TYPE}
                                    field="roleName"
                                    cssStyles={{ flex: 1.5 }}
                                />
                                <TableColumn
                                    headerName={strings.USERS_TABLE.LAST_LOGIN}
                                    field="lastLogin"
                                    cssStyles={{ flex: 1.3 }}
                                    renderer={(value: UsersDetails) => {
                                        const formattedDate = dateUtil.convertUTCtoLocalTime(
                                            value.lastLogin
                                        );
                                        return formattedDate ? (
                                            <p title={formattedDate}>{formattedDate}</p>
                                        ) : (
                                            ''
                                        );
                                    }}
                                />
                                <TableColumn
                                    headerName={strings.USERS_TABLE.COUNT_LOGIN}
                                    field="countLogin"
                                />
                                <TableColumn
                                    headerName={strings.USERS_TABLE.PERCENT_OF_ENDORSEMENT}
                                    field="endorsement"
                                    renderer={(value: UsersDetails) => {
                                        return (
                                            <LegendDot
                                                cellData={value.endorsement}
                                                legendBasedOn={LEGEND_BASED_ON.Percentage}
                                            />
                                        );
                                    }}
                                />
                                {userProfile.role === UserRoleMapper.NAR_ADMIN ||
                                userProfile.role === UserRoleMapper.BROKER_MANAGER ? (
                                    <TableColumn
                                        headerClassName="edit-action"
                                        className="edit-action"
                                        headerName={''}
                                        field=""
                                        sortable={false}
                                        cssStyles={{ flex: 0.5 }}
                                        renderer={(value: UsersDetails) => {
                                            return (
                                                <div className="action-container">
                                                    <img
                                                        className="action-container__icon-edit"
                                                        src={MEDIA_COLLECTION.IC_EDIT}
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                            handleActions?.('edit', value)
                                                        }
                                                    />
                                                </div>
                                            );
                                        }}
                                    />
                                ) : (
                                    ''
                                )}
                            </CustomTable>
                        )}
                    </div>

                    {usersListData.totalResults ? (
                        <Pagination
                            totalRows={usersListData.totalResults}
                            currentPageNum={currentPageNum}
                            onPageChange={handlePageChange}
                            pageSize={currentPage.pageSize}
                            onPageSizeChange={onPageSizeChange}
                        />
                    ) : null}

                    {showEditUser ? (
                        <EditUserModal
                            currentUserRole={userProfile.role}
                            selectedRow={selectedRowData as UsersDetails}
                            onCancel={() => setShowEditUser(false)}
                        />
                    ) : (
                        ''
                    )}
                </div>
            )}
        </>
    );
};

export default Users;
