import { PERCENT_DOT_COLORS, YES_OR_NO_DOT_COLORS, ACTIVE_INACTIVE_COLORS } from 'common/configs';
import { LEGEND_BASED_ON_TYPE } from 'modules/CommonModules/Users/models';
import { LEGEND_BASED_ON } from 'modules/CommonModules/Users/enums';
import './LegendDot.scss';

type LegendDotProps = {
    className?: string;
    cellData: string;
    legendBasedOn?: LEGEND_BASED_ON_TYPE;
};

const LegendDot = ({ className, cellData, legendBasedOn }: LegendDotProps) => {
    let dotColor = '#FF736A'; //red
    const shouldShowContent = cellData !== null && cellData !== undefined;

    switch (legendBasedOn) {
        case LEGEND_BASED_ON.Percentage:
            {
                const cellDataNumber = parseInt(cellData);
                Object.entries(PERCENT_DOT_COLORS).forEach(([color, range]) => {
                    if (cellDataNumber >= range.min && cellDataNumber <= range.max) {
                        dotColor = color;
                    }
                });
            }
            break;

        case LEGEND_BASED_ON.YesOrNo:
            {
                dotColor = YES_OR_NO_DOT_COLORS[cellData?.toLowerCase()];
            }
            break;

        case LEGEND_BASED_ON.ActiveInactive:
            {
                dotColor = ACTIVE_INACTIVE_COLORS[cellData?.toLowerCase()];
            }
            break;
    }

    return (
        <div className="custom-LegendDot__wrapper">
            {shouldShowContent ? (
                <>
                    <p
                        className={`custom-LegendDot ${className}`}
                        style={{ backgroundColor: dotColor }}></p>
                    <p title={cellData}>{cellData}</p>
                </>
            ) : (
                ''
            )}
        </div>
    );
};

export default LegendDot;
