import React, { useEffect, useState } from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    RadioButtonGroup,
    Select
} from 'common/components';
import { strings } from 'common/constants/app-constants';
import { useAppDispatch } from 'common/redux/core';
import { AddExternalUserRequest, ExternalUsersDetails } from 'modules/CommonModules/Users/models';
import { externalUsersActions } from 'modules/CommonModules/Users/redux';
import { SelectOption } from 'common/models';
import { StringUtil } from 'common/utils';
import {
    ExternalUserStatusActiveOption,
    ExternalUserStatusInActiveOption,
    ExternalUserStatusOptions
} from 'common/configs';
import './ExternalUserAddOrEditModal.scss';

type ExternalUserAddOrEditModalProps = {
    selectedRow?: ExternalUsersDetails | undefined;
    userRoleOptions: SelectOption[];
    onCancel: () => void;
};

const ExternalUserAddOrEditModal: React.FC<ExternalUserAddOrEditModalProps> = ({
    selectedRow,
    userRoleOptions,
    onCancel
}) => {
    const dispatch = useAppDispatch();
    const isEditMode = selectedRow && Object.keys(selectedRow)?.length; // check whether its add or edit user
    const [validationError, setValidationError] = useState({
        email: '',
        role: ''
    });
    const [emailTouched, setEmailTouched] = useState<boolean>(false);

    const [formData, setFormData] = useState({
        email: '',
        role: {
            id: '',
            name: ''
        },
        status: {
            id: '',
            name: ''
        }
    });

    useEffect(() => {
        if (isEditMode) {
            setFormData({
                email: selectedRow?.email || '',
                role: {
                    id: selectedRow?.roleCode || '',
                    name: ''
                },
                status: selectedRow?.isActive
                    ? ExternalUserStatusActiveOption
                    : ExternalUserStatusInActiveOption
            });
        }
    }, []);

    const handleEmailBlur = (e) => {
        setEmailTouched(true);
        triggerEmailValidation(formData.email);
    };

    const handleChange = (fieldName: string, value) => {
        if (fieldName === 'email' && (emailTouched || validationError.email)) {
            triggerEmailValidation(value);
        } else if (fieldName === 'role') {
            setValidationError((prevState) => ({ ...prevState, role: '' }));
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [fieldName]: value
        }));
    };

    const triggerEmailValidation = (email: string) => {
        if (!StringUtil.isValidMail(email)) {
            setValidationError((prevState) => ({
                ...prevState,
                email: strings.EMAIL_VALIDATION_ERROR
            }));
        } else {
            setValidationError((prevState) => ({ ...prevState, email: '' }));
        }
    };

    const handleSaveUser = () => {
        if (!formData.role.id || !formData.email) {
            if (!formData.role.id) {
                setValidationError((prevState) => ({
                    ...prevState,
                    role: strings.EXTERNAL_USERS.ROLE_VALIDATION_ERROR
                }));
            }

            if (!formData.email) {
                setValidationError((prevState) => ({
                    ...prevState,
                    email: strings.EMAIL_VALIDATION_ERROR
                }));
            }
            return;
        }
        if (validationError.email) return;

        const request: AddExternalUserRequest = {
            email: formData.email,
            role: formData.role.id
        };

        if (isEditMode) {
            if (selectedRow?.userInvitationGuid) {
                dispatch(
                    externalUsersActions.updateExternalUserBegin({
                        userInvitationGuid: selectedRow?.userInvitationGuid,
                        isActive: formData.status.id === 'active' ? true : false,
                        ...request
                    })
                );
            }
        } else {
            dispatch(externalUsersActions.addExternalUserBegin(request));
        }
        onCancel();
    };

    return (
        <div>
            <Modal className="external-user-modal" show size="md" onClose={onCancel}>
                <ModalHeader>
                    <div>{strings.EXTERNAL_USERS.TITLE}</div>
                </ModalHeader>
                <ModalBody>
                    <div className="external-user-modal__body-content">
                        <div className="input-wrapper">
                            <Input
                                id="email"
                                type="email"
                                placeHolder={strings.USERS_TABLE.EMAIL}
                                value={formData.email}
                                onChange={(e) => handleChange('email', e.target.value)}
                                onBlur={(e) => handleEmailBlur(e)}
                            />
                            {validationError.email ? (
                                <p className="input-wrapper--error">{validationError.email}</p>
                            ) : (
                                ''
                            )}
                        </div>

                        <div className="select-wrapper">
                            <Select
                                defaultOption={formData.role}
                                options={userRoleOptions}
                                placeHolder={strings.EXTERNAL_USERS.ROLE}
                                onChange={(selectedOption) => handleChange('role', selectedOption)}
                            />
                            {validationError.role ? (
                                <p className="input-wrapper--error">{validationError.role}</p>
                            ) : (
                                ''
                            )}
                        </div>

                        {isEditMode ? (
                            <div className="radio-wrapper">
                                <p className="label">{strings.EXTERNAL_USERS.STATUS}</p>
                                <RadioButtonGroup
                                    className="radio-wrapper__btn-group"
                                    options={ExternalUserStatusOptions}
                                    value={formData.status}
                                    onChange={(selectedOption) =>
                                        handleChange('status', selectedOption)
                                    }
                                />
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-save" variant="primary" onClick={handleSaveUser}>
                        {strings.SAVE}
                    </Button>
                    <Button className="btn-cancel" variant="secondary" onClick={onCancel}>
                        {strings.CANCEL}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ExternalUserAddOrEditModal;
