import { ApiStatus } from 'common/enums';
import { ApiResponse } from 'common/models';
import {
    BrokeragesListResponse,
    EditBrokerageResponse,
    EndorsementMaterials,
    initialBrokerageListResponse,
    initialEditBrokerageResponse,
    initialEndorsementMaterialsState
} from 'modules/AdminDashboard/models';

export type AdminActionsState = {
    brokeragesList: ApiResponse<BrokeragesListResponse>;
    editBrokerage: ApiResponse<EditBrokerageResponse>;
    endorsementMaterials: EndorsementMaterials;
};

export const initialAdminActionsState: AdminActionsState = {
    brokeragesList: {
        data: initialBrokerageListResponse,
        status: ApiStatus.NONE
    },
    editBrokerage: {
        data: initialEditBrokerageResponse,
        status: ApiStatus.NONE
    },
    endorsementMaterials: initialEndorsementMaterialsState
};
