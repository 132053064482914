import { ApiStatus } from 'common/enums';
import {
    LogoutResponse,
    SubmitDeviceInfoResponse,
    UserProfileDetails,
    initialLogoutResponse,
    initialProfileDetails,
    initialSubmitDeviceInfo
} from '../../models/user-profile.model';
import { ApiResponse } from 'common/models';

export type UserState = {
    profileDetails: ApiResponse<UserProfileDetails>;
    deviceInfo: ApiResponse<SubmitDeviceInfoResponse>;
    logout: ApiResponse<LogoutResponse>;
};

const initialUserProfileState: UserState = {
    profileDetails: {
        data: initialProfileDetails,
        status: ApiStatus.NONE
    },
    deviceInfo: {
        data: initialSubmitDeviceInfo,
        status: ApiStatus.NONE
    },
    logout: {
        data: initialLogoutResponse,
        status: ApiStatus.NONE
    }
};

export default initialUserProfileState;
