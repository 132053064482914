const PERCENT_DOT_COLORS = {
    '#61D19E': { min: 100, max: 999 }, // green
    '#FFD94D': { min: 60, max: 99 }, // yellow
    '#FF8700': { min: 30, max: 59 }, // orange
    '#FF736A': { min: 0, max: 29 } // red
};

const YES_OR_NO_DOT_COLORS = {
    yes: '#61D19E', // green
    no: '#FF736A' //red
};

const ACTIVE_INACTIVE_COLORS = {
    active: '#189D3E', // green
    inactive: '#FF736A' //red
};

export { PERCENT_DOT_COLORS, YES_OR_NO_DOT_COLORS, ACTIVE_INACTIVE_COLORS };
