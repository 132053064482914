import { DEFAULT_PAGE_SIZE } from 'common/configs';
import { IErrors } from 'common/models';

// fetch users list
export type ExternalUsersListRequest = {
    pageNum?: number;
    pageSize?: number;
    filters?: string;
    sortOrder?: string;
    sortColumn?: string;
    searchQuery?: string;
};

export type ExternalUsersDetails = {
    userInvitationGuid: string;
    email: string;
    isAccepted: boolean;
    isActive: boolean;
    roleCode: string;
};

export type ExternalUsersListResponse = {
    hasPreviousPage: boolean;
    hasNextPage: boolean;
    items: ExternalUsersDetails[];
    totalResults: number;
    totalPages: number;
    currentPage: number;
    pageSize: number;
};

export type ExternalUserActionsGenericResponse = {
    userInvitationGuid: string;
    isSuccess: boolean;
    hasNudge: boolean;
    title: string;
    responseDetails: {
        details: string;
        code: string;
        additionalParameters: null;
    };
};

// add user
export type AddExternalUserRequest = {
    email: string;
    role: string;
};

export type AddExternalUserResponse = ExternalUserActionsGenericResponse;

export type AddExternalUserErrorResponse = {
    errors: IErrors[] | string;
};

//update user
export type UpdateExternalUserRequest = {
    userInvitationGuid: string;
    email: string;
    role: string;
    isActive: boolean;
};

export type UpdateExternalUserResponse = ExternalUserActionsGenericResponse;

export type UpdateExternalUserErrorResponse = {
    errors: IErrors[] | string;
};

//delete user
export type DeleteExternalUserRequest = {
    userInvitationGuid: string;
};

export type DeleteExternalUserResponse = ExternalUserActionsGenericResponse;

export const initialExternalUsersList: ExternalUsersListResponse = {
    hasPreviousPage: false,
    hasNextPage: false,
    items: [],
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: DEFAULT_PAGE_SIZE
};

export const externalUserActionsGenericResponse: ExternalUserActionsGenericResponse = {
    userInvitationGuid: '',
    isSuccess: false,
    hasNudge: false,
    title: '',
    responseDetails: {
        details: '',
        code: '',
        additionalParameters: null
    }
};

export const initialAddExternalUser: AddExternalUserResponse = externalUserActionsGenericResponse;

export const initialUpdateExternalUser: UpdateExternalUserResponse =
    externalUserActionsGenericResponse;

export const initialDeleteExternalUser: DeleteExternalUserResponse =
    externalUserActionsGenericResponse;
