import { FC } from 'react';
import './TableColumnHeader.scss';

type TableColumnHeaderProps = {
    className?: string;
    headerName: string;
    sortable?: boolean;
    field: string;
    sortValue?: string;
    onSort?: (value: string) => void;
};

const TableColumnHeader: FC<TableColumnHeaderProps> = ({
    className,
    headerName,
    sortable,
    field,
    sortValue,
    onSort
}: TableColumnHeaderProps) => {
    const handleSortDataAscending = (field: string) => {
        onSort?.(field);
    };

    const handleSortDataDescending = (field: string) => {
        onSort?.(`-${field}`);
    };

    const handleSortDataReset = () => {
        onSort?.(`=${field}`);
    };

    return (
        <>
            <span title={headerName} className={className}>
                {headerName}
            </span>

            {onSort && sortable && (
                <span className="header-icon">
                    {sortValue !== field && sortValue !== `-${field}` && (
                        <>
                            <i
                                onClick={() => handleSortDataAscending(field || '')}
                                className="custom-table-header-cell__up-arrow"
                            ></i>
                            <i
                                onClick={() => handleSortDataAscending(field || '')}
                                className={`custom-table-header-cell__down-arrow`}
                            ></i>
                        </>
                    )}
                    {sortValue === field && (
                        <i
                            onClick={() => handleSortDataDescending(field || '')}
                            className={`custom-table-header-cell__down-arrow ${
                                sortValue === field &&
                                'custom-table-header-cell__down-arrow--active'
                            }`}
                        ></i>
                    )}

                    {sortValue === `-${field}` && (
                        <i
                            onClick={() => handleSortDataReset()}
                            className="custom-table-header-cell__up-arrow"
                        ></i>
                    )}
                </span>
            )}
        </>
    );
};
export default TableColumnHeader;
