import { useEffect, useRef, useState } from 'react';
import { MEDIA_COLLECTION } from 'common/configs';
import { useNavigate } from 'react-router-dom';
import { NavOptions, UserAvatarDropdownDetails, UserRoles } from 'common/models';
import { useOnClickOutside } from 'common/hooks';
import ColoredLine from '../ColoredLine';
import AvatarDropdown from '../AvatarDropdown';
import { strings } from 'common/constants/app-constants';
import { NAR_HOME_PAGE } from 'common/configs/app.config';
import DrawerToggle from '../MenuDrawer/DrawerToggle/DrawerToggle';
import NavOptionsBuilder from '../NavOptionsBuilder/NavOptionsBuilder';
import { MenuDrawer } from 'common/components';
import './Header.scss';

type HeaderProps = {
    label: string;
    shouldShowBaseLabel: boolean;
    navOptions: NavOptions | undefined;
    userDetails: UserAvatarDropdownDetails;
    showHeader: boolean;
};

const Header = ({
    label,
    shouldShowBaseLabel,
    navOptions,
    userDetails,
    showHeader
}: HeaderProps) => {
    const navigate = useNavigate();

    const dropdownRef = useRef(null);
    const headerRef = useRef(null);
    const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
    const [isMenuDrawerOpen, setMenuDrawerOpen] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const shouldShowHamburgerMenu = windowWidth <= 992; //992px is the breakpoint for mobile/ipad view

    useOnClickOutside(dropdownRef, () => {
        if (showAvatarDropdown) {
            handleUserIconClick();
        }
    });

    useOnClickOutside(headerRef, () => {
        if (isMenuDrawerOpen) {
            setMenuDrawerOpen(false);
        }
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!shouldShowHamburgerMenu) {
            closeMenuDrawer();
        }
    }, [shouldShowHamburgerMenu]);

    const openMenuDrawer = () => {
        setMenuDrawerOpen(true);
    };

    const closeMenuDrawer = () => {
        setMenuDrawerOpen(false);
    };

    const handleUserIconClick = () => {
        closeMenuDrawer();
        setShowAvatarDropdown((prevState) => !prevState);
    };

    const handleAppLogoClick = () => {
        window.open(NAR_HOME_PAGE, '_self');
    };

    return (
        <>
            <header className="app-header" ref={headerRef}>
                <div className="app-header-left">
                    <DrawerToggle
                        show={shouldShowHamburgerMenu}
                        isOpen={isMenuDrawerOpen}
                        openMenuDrawer={openMenuDrawer}
                        closeMenuDrawer={closeMenuDrawer}
                    />
                    <div className="app-header__logo-container" onClick={handleAppLogoClick}>
                        <img
                            className="app-header__logo-container--logo vertical-line"
                            src={MEDIA_COLLECTION.IC_NAR_LOGO}
                            aria-hidden="true"
                        />
                        <img
                            className={`app-header__logo-container--logo ${
                                label ? 'line vertical-line' : ''
                            }`}
                            src={MEDIA_COLLECTION.IC_C2EX_LOGO}
                            aria-hidden="true"
                        />
                        {label && (
                            <div className="app-header__title-container">
                                <span className="app-header__title-container--text">{label}</span>
                                {shouldShowBaseLabel && (
                                    <span className="app-header__title-container--base-text">
                                        {strings.HEADER.ADMIN_DASHBOARD}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="app-header__options">
                    <NavOptionsBuilder navOptions={navOptions} />
                    {showHeader && (
                        <div
                            className="app-header__user-profile-container"
                            ref={dropdownRef}
                            onClick={handleUserIconClick}>
                            <div className="app-header__user-profile-container--initials">
                                {userDetails.firstName.charAt(0)?.toUpperCase()}
                                {userDetails.lastName.charAt(0)?.toUpperCase()}
                            </div>
                            <img
                                className="app-header__user-profile-container--arrow-btn"
                                src={MEDIA_COLLECTION.IC_DOWN_ARROW}
                                aria-hidden="true"
                            />

                            {showAvatarDropdown ? (
                                <AvatarDropdown userDetails={userDetails} />
                            ) : null}
                        </div>
                    )}
                </div>

                <MenuDrawer
                    isOpen={isMenuDrawerOpen}
                    closeMenuDrawer={closeMenuDrawer}
                    navOptions={navOptions}
                />
            </header>
            <ColoredLine className="header-colored-line" />
        </>
    );
};

export default Header;
