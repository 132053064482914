import { MEDIA_COLLECTION } from 'common/configs';
import './Scenery.scss';

type SceneryProps = {
    className?: string;
};

const Scenery: React.FC<SceneryProps> = ({ className }: SceneryProps) => {
    return (
        <div className={`scenery-container ${className}`}>
            <img className="scenery" src={MEDIA_COLLECTION.IC_SCENERY} />
        </div>
    );
};

export default Scenery;
