const PAGE_SIZE_OPTIONS = [5, 10, 20, 50];
const DEFAULT_PAGE_SIZE = 10;
const PRIMARY_ASSOCIATION_PAGE_SIZE = 100;
const PAGINATION_DEBOUNCE_TIME = 200;

export {
    PAGE_SIZE_OPTIONS,
    DEFAULT_PAGE_SIZE,
    PRIMARY_ASSOCIATION_PAGE_SIZE,
    PAGINATION_DEBOUNCE_TIME
};
