import { PayloadAction } from '@reduxjs/toolkit';
import { ApiResult, IErrors } from 'common/models';
import { put, takeLatest } from 'redux-saga/effects';
import { ExternalUsersService } from '../services/external-users.service';
import { externalUsersActions } from '../slices/external-users.slice';
import {
    AddExternalUserRequest,
    AddExternalUserResponse,
    DeleteExternalUserRequest,
    DeleteExternalUserResponse,
    ExternalUsersListRequest,
    ExternalUsersListResponse,
    UpdateExternalUserRequest,
    UpdateExternalUserResponse
} from '../../models';

const externalUsersWatch = function* () {
    yield takeLatest(externalUsersActions.fetchExternalUsersListBegin.type, FetchExternalUsersList);
    yield takeLatest(externalUsersActions.addExternalUserBegin.type, AddExternalUser);
    yield takeLatest(externalUsersActions.updateExternalUserBegin.type, UpdateExternalUser);
    yield takeLatest(externalUsersActions.deleteExternalUserBegin.type, DeleteExternalUser);
};

const FetchExternalUsersList = function* ({ payload }: PayloadAction<ExternalUsersListRequest>) {
    const result: ApiResult<ExternalUsersListResponse> =
        yield ExternalUsersService.fetchExternalUsersList(payload);
    if (result.hasErrors) {
        yield put(externalUsersActions.fetchExternalUsersListError());
    } else {
        yield put(externalUsersActions.fetchExternalUsersListSuccess(result.value));
    }
};

const AddExternalUser = function* ({ payload }: PayloadAction<AddExternalUserRequest>) {
    const result: ApiResult<AddExternalUserResponse> = yield ExternalUsersService.addExternalUser(
        payload
    );
    if (result.hasErrors) {
        const { errors } = result;
        const status = errors[0]?.response?.status;
        let errorResponse: IErrors[] | string = [];

        if (status && status == 400)
            errorResponse = errors[0].response?.data?.causes[0].details ?? [];

        yield put(
            externalUsersActions.addExternalUserError({
                errors: errorResponse
            })
        );
    } else {
        yield put(externalUsersActions.addExternalUserSuccess(result.value));
    }
};

const UpdateExternalUser = function* ({ payload }: PayloadAction<UpdateExternalUserRequest>) {
    const result: ApiResult<UpdateExternalUserResponse> =
        yield ExternalUsersService.updateExternalUser(payload);
    if (result.hasErrors) {
        const { errors } = result;
        const status = errors[0]?.response?.status;
        let errorResponse: IErrors[] | string = [];

        if (status && status == 400)
            errorResponse = errors[0].response?.data?.causes[0].details ?? [];

        yield put(
            externalUsersActions.updateExternalUserError({
                errors: errorResponse
            })
        );
    } else {
        yield put(externalUsersActions.updateExternalUserSuccess(result.value));
    }
};

const DeleteExternalUser = function* ({ payload }: PayloadAction<DeleteExternalUserRequest>) {
    const result: ApiResult<DeleteExternalUserResponse> =
        yield ExternalUsersService.deleteExternalUser(payload);
    if (result.hasErrors) {
        yield put(externalUsersActions.deleteExternalUserError());
    } else {
        yield put(externalUsersActions.deleteExternalUserSuccess(result.value));
    }
};

export { externalUsersWatch };
