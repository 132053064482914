import React, { FC } from 'react';
import { strings } from 'common/constants/app-constants';
import './Fallback.scss';

const Fallback: FC = () => {
    return (
        <div className="fallback-container motif-row">
            <div className="fallback__content">
                <span>{strings.SOMETHING_WENT_WRONG}</span>
            </div>
        </div>
    );
};

export default Fallback;
