import { NavLink, useLocation } from 'react-router-dom';
import { NavOptions } from 'common/models';
import './NavOptionsBuilder.scss';

type NavOptionsBuilderProps = {
    className?: string;
    navOptions: NavOptions | undefined;
    onClick?: () => void;
};

const NavOptionsBuilder: React.FC<NavOptionsBuilderProps> = ({
    className,
    navOptions,
    onClick
}: NavOptionsBuilderProps) => {
    const location = useLocation();

    return (
        <nav>
            <ul className={`app-header__options-container ${className ?? ''}`}>
                {navOptions?.map((option, i) => {
                    const isActiveOption = option.path === location.pathname;
                    return (
                        <li
                            key={i}
                            className={`${isActiveOption ? 'active' : ''}`}
                            onClick={() => onClick?.()}>
                            <NavLink
                                to={option.path}
                                className={`app-header__options-container--option`}>
                                {option.name}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default NavOptionsBuilder;
